<template>
  <div class="cell">
    <div class="big">
      <div class="title">
        <div class="left">检查项目</div>
        <div class="right">检查指标意义</div>
      </div>
      <div class="item" v-for="item in CellList" :key="item">
        <div class="small_box">
          {{ item.inspectionItemType }}({{ item.itemVoList.length }}项)
        </div>
        <ul>
          <li v-for="item2 in item.itemVoList" :key="item2">
            <div class="left">{{ item2.inspectionItem }}</div>
            <div class="right">{{ item2.detailedExamination }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //表格数据
    CellList: [],
  },
  data() {
    return {};
  },
  methods: {
    getNum() {
      this.CellList.forEach((element) => {
        this.Num += element.num;
      });
    },
  },
};
</script>

<style>
.cell {
  margin: 0.32rem 0.32rem 0 0.32rem;
  padding-bottom: 1rem;
}
.cell .big {
  border: solid 0.01rem #acacac;
  border-radius: 0.2rem;
}
.cell .big .title {
  width: 6.86rem;
  height: 1rem;
  background: #444444;
  border-radius: 0.2rem 0.2rem 0rem 0rem;
  /* border: 0.01rem solid #bdbdbd; */
  display: flex;
  flex-direction: row;
  line-height: 1rem;
  color: #ffffff;
  font-size: 0.32rem;
}
.cell .big .title .left {
  margin-right: 1rem;
  margin-left: 0.3rem;
}
.cell .item .small_box {
  width: 6.46rem;
  height: 0.5rem;
  background: var(--hotBox);
  border-radius: 0.06rem;
  font-weight: 600;
  color: var(--otherColor);
  line-height: 0.5rem;
  font-size: 0.2rem;
  text-align: center;
  margin: 0.32rem auto 0 auto;
}
.cell .item ul li {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0.24rem;
  border-bottom: 0.01rem solid #acacac;
  padding: 0.24rem 0;
}
.cell .item ul li .right {
  width: 3.98rem;
  font-size: 0.24rem;
  font-weight: 600;
  color: #666666;
  line-height: 0.36rem;
  margin-left: 0.6rem;
}
.cell .item ul li .left {
  width: 1.68rem;
  font-size: 0.28rem;
  font-weight: 600;
  color: #000000;
  line-height: 0.4rem;
}
.cell .item:last-child li:last-child {
  border: none;
}
</style>
