<template>
  <div class="tjrxxList bg_white pt48">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
      fixed
    >
      <template #left>
        <i
          class="van-badge__wrapper van-icon van-icon-cross"
          style="font-size: 0.4583rem; color: #2e2e2e"
        ></i>
      </template>
      <template #right>
        <router-link to="/User">
          <!-- <i
            class="van-badge__wrapper van-icon van-icon-ellipsis"
            style="font-size: 0.5208rem; color: #2e2e2e"
          ></i> -->
           <img style="width:0.4rem" src="@/assets/img/user11.png" alt="">
        </router-link>
      </template>
    </van-nav-bar>
    <div class="form_box mt10">
      <van-form ref="form">
        <van-cell-group inset>
          <!-- 输入名字 -->
          <van-field
            v-model="examinatorForm.medicalName"
            name="姓名"
            label="姓名"
            required
            label-width="80"
            placeholder="请输入姓名"
            colon="true"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <!-- 选择证件类型 -->
          <van-field
            v-model="typeShow"
            required
            readonly
            colon="true"
            name="picker"
            label="证件类型"
            label-width="80"
            @click="showType = true"
          />
          <!-- <van-popup v-model:show="showType" position="bottom">
            <van-picker
              :columns="typeList"
              @confirm="onConfirmType"
              @cancel="showType = false"
            />
          </van-popup> -->
          <!-- 输入证件号码 -->
          <van-field
            v-model="examinatorForm.cardNumber"
            name="证件号码"
            label="证件号码"
            required
            label-width="80"
            colon="true"
            placeholder="请输入证件号码"
            :rules="[
              { required: true, message: '请输入您的证件号码' },
              { pattern: idNumPattern, message: '请输入正确的证件号码' },
            ]"
            @blur="blurIdNum"
          />
          <!-- 选择性别 -->
          <van-field
            name="radio"
            label="性别"
            required
            label-width="80"
            colon="true"
          >
            <template #input>
              <div
                :class="
                  item.dictValue == examinatorForm.gender
                    ? 'sex_box'
                    : 'sex_box2'
                "
                v-for="item in gender"
                :key="item.dictValue"
                @click="examinatorForm.gender = item.dictValue"
              >
                {{ item.dictLabel }}
              </div>
            </template>
          </van-field>
          <!-- 婚姻状态 -->
          <van-field
            name="radio"
            label="婚姻状态"
            required
            label-width="80"
            colon="true"
          >
            <template #input>
              <div
                :class="
                  item.dictValue == examinatorForm.isMarriage
                    ? 'wed_box'
                    : 'wed_box2'
                "
                v-for="item in marriage"
                :key="item.dictValue"
                @click="examinatorForm.isMarriage = item.dictValue"
              >
                {{ item.dictLabel }}
              </div>
            </template>
          </van-field>
          <!-- 选择出生日期 -->
          <div class="time" @click="showSelectDate">
            <van-field
              v-model="examinatorForm.birthday"
              readonly
              name="date"
              colon="true"
              label="出生日期"
              placeholder="出生日期"
            />
            <img src="@/assets/img/riqi.png" @click="showSelectDate" />
          </div>
          <van-popup v-model:show="showDate" position="bottom">
            <van-datetime-picker
              v-model="selectDate.currentDate"
              type="date"
              title="选择年月日"
              :min-date="selectDate.minDate"
              :max-date="selectDate.maxDate"
              @confirm="onConfirmDate"
              @cancel="showDate = false"
            />
          </van-popup>
          <!-- 输入证件号码 -->
          <van-field
            v-model="examinatorForm.age"
            name="年龄"
            label="年龄"
            required
            label-width="80"
            colon="true"
            placeholder="请输入年龄"
            :rules="[
              { required: true, message: '请输入您的年龄' },
              { pattern: agePattern, message: '请输入正确的年龄' },
            ]"
          />
          <!-- 输入手机号码 -->
          <van-field
            v-model="examinatorForm.telephone"
            name="手机号码"
            label="手机号码"
            colon="true"
            label-width="80"
            required
            placeholder="请输入手机号码"
            :rules="[
              { pattern: mobilePattern, message: '请输入正确的手机号码' },
              { required: true, message: '请输入您的手机号码' },
            ]"
          />
          <!-- 关系 -->
          <div class="connect_box">
            <van-field
              name="radio"
              label="TA是您"
              required
              label-width="80"
              colon="true"
            >
              <template #input>
                <div
                  class="connect"
                  :class="
                    examinatorForm.relationship == item ? 'wed_box' : 'wed_box2'
                  "
                  style="margin-bottom: 0.2rem"
                  v-for="(item, index) in relationList"
                  :key="index"
                  @click="examinatorForm.relationship = item"
                >
                  {{ item }}
                </div>
              </template>
            </van-field>
          </div>
        </van-cell-group>
        <van-action-bar>
          <van-action-bar-button
            type="danger"
            text="保存"
            @click="saveExaminator"
          />
        </van-action-bar>
      </van-form>
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import {
  NavBar,
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  RadioGroup,
  Radio,
  DatetimePicker,
  Button,
  Toast,
  ActionBar,
  ActionBarButton,
} from "vant";
import api from "@/api/api/Index";

export default {
  components: {
    vanNavBar: NavBar,
    vanForm: Form,
    vanField: Field,
    vanCellGroup: CellGroup,
    vanPopup: Popup,
    vanPicker: Picker,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanDatetimePicker: DatetimePicker,
    vanButton: Button,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  data() {
    return {
      title:"新增个人信息",
      examinatorForm: {
        medicalName: null, //体检人姓名
        cardType: 0, //证件类型
        cardNumber: null, //证件号码
        gender: null, //性别
        isMarriage: null, //婚姻状态
        birthday: null, //生日
        telephone: null, //手机号码
        relationship: null, //关系
        age: null, //年龄
      },
      gender: [], //性别
      marriage: [], //婚姻状态
      certificate: [], //证件
      typeList: [], //证件类型
      typeShow: "居民身份证", //所选择的证件
      relationList: ["本人", "父母", "子女", "配偶", "其他"],
      showType: false, //证件类型选择器
      showDate: false, //出生日期选择器
      mobilePattern: /^1[3|4|5|7|8]\d{9}$/, //手机号码正则
      idNumPattern:
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, //身份证正则
      agePattern: /^([1-9]\d?|1[01]\d|150)$/, //年龄正则
      selectDate: {
        minDate: new Date(1900, 0, 1),
        maxDate: new Date(),
        currentDate: new Date(2000, 0, 1),
      },
    };
  },
  methods: {
    // 添加体检人信息的一些表单数据
    getexaminatorForm() {
      // 婚姻
      api.Examinator.dictType("is_marriage").then((res) => {
        this.marriage = res;
      });
      // 性别
      api.Examinator.dictType("sys_user_sex").then((res) => {
        this.gender = res;
      });
      // 证件
    //   api.Examinator.dictType("card_type").then((res1) => {
    //     this.certificate = res1;
    //     this.typeList = res1.map((item) => item.dictLabel);
    //     // 默认值
    //     this.typeShow = res1[0].dictLabel;
    //     this.examinatorForm.cardType = res1[0].dictValue;
    //     if (this.$route.query.id) {
    //       api.Examinator.getExaminatorDetail(this.$route.query.id).then(
    //         (res) => {
    //           this.examinatorForm = res[0];
    //           this.examinatorForm.cardType = res[0].cardType;
    //           this.examinatorForm.gender = this.examinatorForm.gender + "";
    //           this.examinatorForm.isMarriage =
    //             this.examinatorForm.isMarriage + "";
    //           this.certificate.forEach((item) => {
    //             if (item.dictValue == this.examinatorForm.cardType) {
    //               this.typeShow = item.dictLabel;
    //               // if (this.typeShow == "居民身份证") {
    //               //   this.idNumPattern =
    //               //     /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; //身份证正则
    //               // } else {
    //               //   this.idNumPattern =
    //               //     /^([A-Z]\d{6,10}(\(\w{1}\))?)$/ ||
    //               //     /^([a-zA-z]|[0-9]){5,17}$/; //港澳通行证正则
    //               // }
    //               // this.onConfirmType(item.dictLabel)
    //               return;
    //             }
    //           });
    //         }
    //       );
    //     }
    //   });
    },

    // 选择证件类型的方法
    // onConfirmType(value) {
    //   this.typeShow = value;
    //   this.certificate.forEach((item) => {
    //     if (item.dictLabel == value) {
    //       this.examinatorForm.cardType = item.dictValue;
    //       return;
    //     }
    //   });
    //   if (this.typeShow == "居民身份证") {
    //     this.idNumPattern =
    //       /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; //身份证正则
    //   } else {
    //     this.idNumPattern =
    //       /^([A-Z]\d{6,10}(\(\w{1}\))?)$/ || /^([a-zA-z]|[0-9]){5,17}$/; //港澳通行证正则
    //   }
    //   this.showType = false;
    // },

    // 输入身份证后自动计算出生日
    blurIdNum(event) {
      // 130503670401001
      const idNum =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (
        this.typeShow == "居民身份证" &&
        idNum.test(this.examinatorForm.cardNumber)
      ) {
        if (event.target) {
          var value = event.target._value;
        } else {
          var value = event;
        }
        const year = value.substr(6, 4);
        const mon = value.substr(10, 2);
        const day = value.substr(12, 2);
        this.examinatorForm.birthday = `${year}-${mon}-${day}`;
      } else {
        return;
      }
    },

    // 选择出生日期的方法
    onConfirmDate(date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      if (m < 10) {
        m = "0" + m;
      }
      if (d < 10) {
        d = "0" + d;
      }
      let birthday = `${y}-${m}-${d}`;
      this.examinatorForm.birthday = birthday;
      this.showDate = false;
    },

    // 打开选择日期的方法
    showSelectDate() {
      this.showDate = true;
      if (this.examinatorForm.birthday) {
        this.selectDate.currentDate = new Date(this.examinatorForm.birthday);
      } else {
        this.selectDate.currentDate = new Date(2000, 0, 1);
      }
    },

    // 保存体检人信息
    saveExaminator() {
      if (!this.examinatorForm.medicalName) {
        Toast("请输入您的姓名");
        return;
      } else if (this.examinatorForm.cardType!=0&&this.examinatorForm.cardType!=1) {
        Toast("请选择您的证件类型");
        return;
      } else if (!this.examinatorForm.cardNumber) {
        Toast("请输入您的证件号码");
        return;
      } else if (!this.examinatorForm.gender) {
        Toast("请选择您的性别");
        return;
      } else if (!this.examinatorForm.isMarriage) {
        Toast("请选择您的婚姻状态");
        return;
      } else if (!this.examinatorForm.birthday) {
        Toast("请选择您的出生日期");
        return;
      } else if (!this.examinatorForm.age) {
        Toast("请输入您的年龄");
        return;
      } else if (!this.examinatorForm.telephone) {
        Toast("请输入您的手机号码");
        return;
      } else if (!this.examinatorForm.relationship) {
        Toast("关系不能为空");
        return;
      }
      this.$refs.form
        .validate()
        .then(() => {
          // this.examinatorForm.birthday = new Date(this.examinatorForm.birthday);
          if (this.$route.query.id) {
            // 修改体检人信息
            api.Examinator.editExaminator(this.examinatorForm).then((res) => {
              this.$router.push({
                name: "Examinator",
                query: { redirect: this.$route.query.redirect },
              });
              Toast.success("修改成功");
            });
          } else {
            // 新增体检人信息
            api.Examinator.addExaminator(this.examinatorForm).then((res) => {
              this.$router.push({
                name: "Examinator",
                query: { redirect: this.$route.query.redirect },
              });
              Toast.success("添加成功");
            });
          }
        })
        .catch((err) => {
          Toast(err[0].message);
        });
    },

    // 跳转到user页面
    goUser() {
      this.$router.push({ name: "User" });
    },
  },
  created() {
    this.getexaminatorForm();
    if (this.$route.query.id) {
      this.title = "编辑个人信息"
      api.Examinator.getExaminatorDetail(this.$route.query.id).then((res) => {
        this.examinatorForm = res[0];
        this.examinatorForm.cardType = this.examinatorForm.cardType + "";
        this.examinatorForm.gender = this.examinatorForm.gender + "";
        this.examinatorForm.isMarriage = this.examinatorForm.isMarriage + "";
      });
    }
  },
};
</script>

<style scoped>
.tjrxxList {
  background: #f0f0f0;
  height: 100vh;
  padding-top: 1rem;
}
.form_box {
  width: 7.5rem;
  margin-top: 0.2rem;
}
.van-action-bar-button {
  width: 6.86rem;
  height: 0.8rem;
  background: #ff8230;
  font-size: 0.32rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
}
.van-action-bar {
  padding: 0 0.2rem;
}
.wed_box,
.wed_box2 {
  width: 1.2rem;
  height: 0.56rem;
  border-radius: 0.28rem;
  font-size: 0.28rem;
  line-height: 0.56rem;
  letter-spacing: 0.01rem;
  text-align: center;
  margin-right: 0.28rem;
}
.wed_box {
  background: #ff8230;
  color: #ffffff;
}
.wed_box2 {
  background: #f2f2f2;
  color: #444444;
}
.sex_box,
.sex_box2 {
  width: 1rem;
  height: 0.56rem;
  border-radius: 0.28rem;
  font-size: 0.28rem;
  line-height: 0.56rem;
  letter-spacing: 0.01rem;
  text-align: center;
  margin-right: 0.28rem;
}
.sex_box {
  background: #ff8230;
  color: #ffffff;
}
.sex_box2 {
  background: #f2f2f2;
  color: #444444;
}
.time {
  position: relative;
}
.time img {
  width: 0.36rem;
  height: 0.36rem;
  position: absolute;
  top: 25%;
  right: 0.5rem;
}
.time >>> .van-cell__title:before {
  margin-right: 0.0417rem;
  color: rgb(238, 10, 36);
  content: "*";
}
.van-cell__value .van-field__value {
  text-align: left;
}
#van-field-2-input {
  margin-right: 1rem;
}
.connect_box >>> .van-field__control--custom {
  flex-wrap: wrap !important;
}
.connect .van-field__value {
  width: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.connect_box .connect .van-field__control {
  display: flex;
  flex-wrap: wrap;
}

.connect_box {
  width: 8rem;
}
</style>
