<template>
  <van-nav-bar :title="List.title" left-arrow @click-left="onClickLeft" fixed>
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
          class="van-badge__wrapper van-icon van-icon-ellipsis"
          style="font-size: 0.5208rem; color: #2e2e2e"
        ></i> -->
        <img style="width:0.4rem" src="@/assets/img/user11.png" alt="">
      </router-link>
    </template>
  </van-nav-bar>
  <div class="all bg_white pt86 pb48 flex_c f_ai_c">
    <div class="img mb23"><img :src="List.img" alt="" /></div>
    <div class="name fs20 fw600 cr_000 lh27 f_ls_1 mb12">{{ List.title }}</div>
    <div class="desc fs14 cr_000 lh24 f_ls_1 tc mb38" v-if="List.status != 0">
      {{ List.desc }}
    </div>
    <div class="desc fs14 cr_000 lh24 f_ls_1 tc mb38" v-if="List.status == 0">
      请在<span class="time cr_f86 pl5">{{ orderDetail.remainTime }}</span>
      内完成付款,否则订单将会被系统取消
    </div>
    <div class="OrderBtn mr46 ml46">
      <van-button
        class="one brs_20 br_1_c0 fs14 fw600 lh22 f_ls_1 mb17"
        :style="{ color: item.color, background: item.bc }"
        v-for="item in List.btn"
        :key="item"
        @click="toPage(item.path)"
        >{{ item.name }}</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import api from "@/api/api/Index";
import { Button, NavBar, Dialog } from "vant";
export default {
  components: {
    vanNavBar: NavBar,
    vanButton: Button,
  },
  setup() {
    const onClickLeft = () => history.back();
    const active = ref(0);
    const { proxy } = getCurrentInstance();
    return {
      onClickLeft,
      active,
      proxy,
    };
  },
  created() {
    var _this = this;
    if(_this.$route.query.orderStatus){
       _this.status = this.$route.query.orderStatus;
        _this.OrderList.forEach((element) => {
        if (element.status == _this.status) {
          _this.List = element;
        }
      });

    }else if(this.$route.query.orderId){
    api.OrderDetail.OrderDetail(this.$route.query.orderId).then((res) => {
      _this.status = res.orderState;
      _this.OrderList.forEach((element) => {
        if (element.status == _this.status) {
          this.List = element;
        }
      });
      //设置订单失效时间
      var time = res.createTime * 1000 + 1000 * 60 * 60 * 2;
      var currentTime = new Date().getTime();
      var cm = time - currentTime;
      if (cm <= 1000 * 60 * 60 * 2 && cm > 0) {
        _this.orderDetail.remainTime = _this.proxy.$common.toHHmmss(cm);
        _this.proxy.$common.setTimer(cm, _this.orderDetail);
      }
    });
        }
  },
  mounted() {},
  methods: {
    toPage(page) {
      if (page) {
        this.$router.push({
          name: page,
        });
      } else {
        Dialog.confirm({
          title: "在线客服",
          message: "400-1000-676（08:00-22:00）",
          confirmButtonText: "拨打",
        }).then(() => {
          // 拨打电话
          window.location.href = "tel://400-1000-676";
        });
      }
    },
  },
  data() {
    return {
      orderDetail: {},
      status: 0,
      // 订单状态
      OrderList: [
        {
          title: "付款成功",
          img: require("@/assets/img/order/cheackbox.png"),
          desc: "付款成功！请您在体检前完成预约",
          btn: [
            {
              name: "查看订单",
              path: "OrderList",
              color: "#FFFFFF",
              bc: "#FF8230",
            },
            {
              name: "确定",
              path: "Home",
              color: "#FF8230",
              bc: "#FFF5EF",
            },
          ],
          status: 1,
        },
        {
          title: "付款失败",
          img: require("@/assets/img/order/shibai.png"),
          desc: "请在01:59:28内完成付款 否则订单将会被系统取消",
          btn: [
            {
              name: "去支付",
              path: "OrderList",
              color: "#FFFFFF",
              bc: "linear-gradient(270deg, #FF7463 0%, #FF8D5F 100%)",
            },
            {
              name: "查看订单",
              path: "OrderList",
              color: "#FF8230",
              bc: "#FFF5EF",
            },
          ],
          status: 0,
        },
        {
          title: "预约成功",
          img: require("@/assets/img/order/cheackbox.png"),
          desc: "付款成功！请您在预约日期到医院体检",
          btn: [
            {
              name: "查看订单",
              path: "OrderList",
              color: "#FFFFFF",
              bc: "#FF8230",
            },
            {
              name: "联系客服",
              path: "",
              color: "#FF8230",
              bc: "#FFF5EF",
            },
          ],
          status: 2,
        },
      ],
      //当前订单状态
      List: {},
    };
  },
};
</script>

<style scoped>
.all {
  width: 100%;
  min-height: 95vh;
}
.all .img {
  width: 1.1rem;
  height: 1.1rem;
}
.all .desc {
  width: 3.5rem;
}
.all .desc .time {
  display: inline-block;
  width: 1.5rem;
}
.van-button {
  width: 5.58rem;
  height: 0.8rem;
}
</style>
