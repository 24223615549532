import request from '@/api/axios'; // 导入http中创建的axios实例


const Order = {
    //体检预约下单
    orderAdd(data){
        return request({
            url: '/api/h5/exam/order/add',
            method: 'post',
            data:data
        })
    }
}


export default Order;