<template>
  <div class="all pt20 bg_fff posr">
    <div class="mr16 ml16">
      <div class="fs16 cr_333 fw600 t_ls1 pb8">团检健康报告</div>
      <img src="@/assets/img/team/banner.png" alt="" />
      <div class="des pt8 cr_666 fs11 lh16 t_ls1">
        参检情况，生活方式统计，重要指标及异常指标等汇总分析，异常人员详细清单，全面了解企业整体健康状况，帮助企业控制健康风险。
      </div>

      <div class="pt26 tc fs16 cr_333 fw600 t_ls1">团体业务咨询</div>
      <div class="tc cr_666 fs11 lh16 t_ls1 pt8 pb8">
        工作人员将会在24小时内与您联系
      </div>
      <div class="formItem pb60">
        <van-form>
          <van-field
            v-model="FormData.contactName"
            label="联系人"
            colon
            placeholder=""
            required
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="FormData.contactPhone"
            label="手机号码"
            type="number"
            colon
            placeholder=""
            required
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="FormData.companyName"
            label="单位名称"
            colon
            placeholder=""
            required
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="FormData.estimatedPeople"
            label="预计人数"
            colon
            type="number"
            placeholder=""
            required
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="FormData.perCapitaBudget"
            label="人均预算"
            colon
            type="number"
            placeholder=""
            required
            :rules="[{ required: true }]"
          />
        </van-form>
      </div>
    </div>
    <div class="apply">
      <van-submit-bar
        button-text="立即申请"
        button-color="#FF8230"
        @submit="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  RadioGroup,
  Radio,
  DatetimePicker,
  Button,
  Toast,
  ActionBar,
  ActionBarButton,
  Icon,
  DropdownMenu,
  DropdownItem,
  Overlay,
  SubmitBar,
} from "vant";
import api from "@/api/api/Index";
export default {
  methods: {
    onSubmit() {
      // const data = {
      //   contactName :'1111',
      //   contactPhone:'12312312321',
      //   companyName : '1111111',
      //   estimatedPeople : '111',
      //   perCapitaBudget : '1231'
      // }
      if (
        this.FormData.companyName == "" ||
        this.FormData.contactPhone == "" ||
        this.FormData.perCapitaBudget == "" ||
        this.FormData.contactName == "" ||
        this.FormData.estimatedPeople == ""
      ) {
        Toast("请完整填写信息！");
        return false;
      } else if (!this.iphone.test(this.FormData.contactPhone)) {
        Toast("请填写正确的手机号码！");
        return false;
      } else if (
        !/^\+?[1-9][0-9]*$/.test(this.FormData.perCapitaBudget)|| !/^\+?[1-9][0-9]*$/.test(this.FormData.estimatedPeople)
      ) {
        Toast("预约人数/人均预算请输入正整数！");
        return false;
      } else {
        api.itemCheck.insertSysBallCheck(this.FormData).then((res) => {
          // if (res.code == 200){
          Toast.success("预约成功");

          // }
        });
        // this.$router.push({path:"ItemStatus",query: { orderStatus: 0 }})
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    document.title = "单位团检预约";
    next();
  },
  data() {
    return {
      show: false,
      FormData: {
        perCapitaBudget: "",
        estimatedPeople: "",
        companyName: "",
        contactPhone: "",
        contactName: "",
      },
      iphone: /^[1][3,4,5,7,8,9][0-9]{9}$/,
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  components: {
    vanSubmitBar: SubmitBar,
    vanNavBar: NavBar,
    vanForm: Form,
    vanField: Field,
    vanCellGroup: CellGroup,
    vanPopup: Popup,
    vanPicker: Picker,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanDatetimePicker: DatetimePicker,
    vanButton: Button,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
    vanDropdownMenu: DropdownMenu,
    vanDropdownItem: DropdownItem,
    vanOverlay: Overlay,
  },
};
</script>

<style scoped>
.all {
  height: 100vh;
}
.downBtn {
  width: 6.86rem;
  bottom: 0;
  height: 1.1rem;
}
.downBtn .van-button {
  width: 6.86rem;
  height: 0.8rem;
  border-radius: 0.4rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.block {
  width: 6.22rem;
  height: 5rem;
  background: #ffffff;
  border-radius: 0.2rem;
}
.van-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.block .close {
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
}
</style>