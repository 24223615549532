<template>
  <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" fixed>
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
            class="van-badge__wrapper van-icon van-icon-ellipsis"
            style="font-size: 0.5208rem; color: #2e2e2e"
          ></i> -->
        <img style="width: 0.4rem" src="@/assets/img/user11.png" alt="" />
      </router-link>
    </template>
  </van-nav-bar>
  <div class="all bg_f8">
    <div class="topInfo bg_fff flex_r f_ai_c f_jc_b posa w100">
      <div class="left">
        <div>李迪迪</div>
        <div>手机号码:12351548421</div>
      </div>
      <div class="right">
        <input
          class="selectOne posr"
          v-model="select.text"
          readonly
          @click="ActiveTime = true"
        />
        <van-icon
          name="arrow-down"
          class="posa nameIcon"
          @click="ActiveTime = true"
        />
        <ul class="timeSelect" v-show="ActiveTime" style="z-index: 999999">
          <li
            v-for="(item, index) in option1"
            :key="index"
            :value="item.value"
            @click="toTime(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
    </div>
    <div class="Nocontent" v-show="contentShow">
      <img src="@/assets/img/report/noReport.png" alt="" srcset="" />
      <div class="mt10">查不到报告</div>
    </div>
    <div class="content" v-show="!contentShow">
      <ul class="contentList">
        <li
          v-for="(item, index) in reportList"
          :key="index"
          @click="toReportDetail(item)"
        >
          <div class="left">
            <div class="title">{{ item.title }}</div>
            <div class="class">
              科室：<span>{{ item.class }}</span>
            </div>
            <div class="time">
              到检时间：<span>{{ item.arrival }}</span>
            </div>
            <div class="time" v-show="item.reportTime == ''">
              报告时间：<span style="color: #ff8230">未出结果</span>
            </div>
            <div class="time" v-show="item.reportTime != ''">
              报告时间：<span>{{ item.reportTime }}</span>
            </div>
          </div>
          <div v-show="item.reportTime != ''" class="right">
            <van-icon name="arrow" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  RadioGroup,
  Radio,
  DatetimePicker,
  Button,
  Toast,
  ActionBar,
  ActionBarButton,
  Icon,
  DropdownMenu,
  DropdownItem,
} from "vant";
export default {
  components: {
    vanNavBar: NavBar,
    vanForm: Form,
    vanField: Field,
    vanCellGroup: CellGroup,
    vanPopup: Popup,
    vanPicker: Picker,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanDatetimePicker: DatetimePicker,
    vanButton: Button,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
    vanDropdownMenu: DropdownMenu,
    vanDropdownItem: DropdownItem,
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  methods: {
    toTime(value) {
      this.select = value;
      this.ActiveTime = false;
    },
    toReportDetail(item) {
      if (item.reportTime != "") {
        this.$router.push({ path: "report" });
      }
    },
  },
  data() {
    return {
      contentShow: false,
      ActiveTime: false,
      title: "体检报告",
      select: {
        text: "近三个月",
        value: "0",
      },
      option1: [
        { text: "近三个月", value: 0 },
        { text: "近半年", value: 1 },
      ],
      reportList: [
        {
          title: "入职体检套餐",
          class: "体检科",
          arrival: "2020-04-20 15:40",
          reportTime: "",
        },
        {
          title: "骨科体检套餐",
          class: "体检科",
          arrival: "2020-04-20 15:40",
          reportTime: "2022-04-21  10:40",
        },
        {
          title: "中老年癌症筛查",
          class: "体检科",
          arrival: "2020-04-20 15:40",
          reportTime: "2022-04-21  10:40",
        },
      ],
    };
  },
};
</script>

<style scoped>
.all {
  height: 100vh;
}
.topInfo {
  height: 1.2rem;
  padding: 0 0.32rem;
  top: 1rem;
}
.topInfo .left div:nth-child(1) {
  margin-bottom: 0.1rem;
}
.topInfo .left > div {
  color: #666666;
  font-size: 0.24rem;
}
.topInfo .right {
  width: 1.66rem;
  height: 0.6rem;
}
.topInfo .right .van-dropdown-menu {
  width: 1.66rem;
  height: 0.6rem;
  border-radius: 0.2rem;
}
.timeBtn {
  right: 0.3rem;
  top: 1.5rem;
}
.selectOne {
  width: 1.66rem;
  height: 0.6rem;
  border-radius: 0.2rem;
  border: 0.01rem solid #cccccc;
  color: #666666;
  font-size: 0.24rem;
}
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  outline: none;
}
option:active {
  background-color: none;
}
/* option{
    color: #666666;
    border-radius: 0.1rem;
}
select::-ms-expand{
    display: none;
} */
.selectOne {
  position: relative;
  padding-left: 0.2rem;
}
.nameIcon {
  right: 0.45rem;
  top: 0.5rem;
}
.timeSelect {
  width: 1.8rem;
  background: #ffffff;
  box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.1rem;
  margin-right: 0.5rem;
  margin-top: 0.04rem;
  position: absolute;
  right: -0.2rem;
}
.timeSelect li {
  font-size: 0.24rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.33rem;
  text-align: center;
  padding: 0.14rem 0;
}
.timeSelect li:not(:last-child) {
  width: 1.81rem;
  border-bottom: 0.01rem solid #eeeeee;
}
.Nocontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.8rem;
}
.Nocontent img {
  width: 2.82rem;
  height: 1.53rem;
  text-align: center;
}
.Nocontent div {
  font-weight: 500;
  color: #999999;
  line-height: 0.45rem;
  font-size: 0.32rem;
}
.contentList {
  margin: 2.2rem auto;
  width: 6.86rem;
  height: 2.3rem;
  padding-top: 0.2rem;
}
.contentList > li {
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  box-shadow: 0rem 0.06rem 0.32rem 0rem rgba(218, 218, 218, 0.5);
  border-radius: 0.2rem;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}
.contentList li .title {
  font-size: 0.28rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.4rem;
  padding-bottom: 0.2rem;
}
.contentList li div:not(:first-child) {
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.33rem;
  padding-bottom: 0.1rem;
}
.contentList li > div span {
  font-size: 0.24rem;
  color: #333333;
  line-height: 0.33rem;
}
.org {
  color: #ff8230;
}
</style>