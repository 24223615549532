<template>
  <van-nav-bar title="体检预约" left-arrow @click-left="toPage('/')" fixed>
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
          class="van-badge__wrapper van-icon van-icon-ellipsis"
          style="font-size: 0.5208rem; color: #2e2e2e"
        ></i> -->
        <img style="width: 0.4rem" src="@/assets/img/user11.png" alt="" />
      </router-link>
    </template>
  </van-nav-bar>
  <div class="bg_white all pt38 pb48">
    <div class="type">
      <TcType :typeList="typeList" @typeSelect="typeSelect"></TcType>
    </div>
    <!-- <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      success-text="刷新成功"
    > -->
    <!-- <div class="black"></div> -->
    <!-- <van-list
        v-model:loading="loading"
        :immediate-check="false"
        :finished="finished"
        @load="onLoad"
      > -->
    <!-- <van-list
        v-model:loading="loading"
        :immediate-check="false"
        :finished="finished"
      > -->
    <div style="margin-top: 1.36rem">
      <TcList
        :isHot="true"
        :packageList="packageList"
        @showNoMore="showNoMore"
        :isShow="false"
      />
    </div>
    <!-- </van-list> -->
    <div class="mt24 mb24 mr16 ml16 tc br_t_1_grey cr_b6b">我是有底线的</div>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import { ref } from "vue";
import api from "@/api/api/Index";
import { NavBar, PullRefresh, List } from "vant";
import TcList from "@/components/content/MealList.vue";
import TcType from "@/components/content/MealType.vue";

export default {
  components: {
    vanNavBar: NavBar,
    TcType,
    TcList,
    vanPullRefresh: PullRefresh,
    vanList: List,
  },
  setup() {
    const onClickLeft = () => history.back();
    return { onClickLeft };
  },
  created() {
    //得到examtype类型
    api.Examinator.dictType("exam_type").then((res) => {
      this.examType = res;
    }),
      //热门套餐数据
      api.Home.hotDate().then((res) => {
        this.hotList = res[0];
        this.hotList.hotStatus = 1;
      });
    var _this = this;
    api.Home.meal_type()
      .then((res) => {
        _this.typeList = res;
        _this.typeList.unshift({
          dictLabel: "全部",
          dictValue: 0,
          checked: false,
        });
        _this.typeList.forEach((element) => {
          const obj = {};
          obj.total = 0;
          obj.num = 1;
          obj.finished = null;
          obj.children = [];
          obj.dictValue = element.dictValue;
          _this.AllList.push(obj);
        });
        _this.typeList.forEach((item, index) => {
          if (item.dictValue == _this.type) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      })
      .then(() => {
        this.getOneNum(this.type);
        // this.getHotList();
      });
  },

  methods: {
     toPage(page) {
      this.$router.push({
        path: page,
     
      });
    },
    // getOneNum(select, type, num) {
    //   this.type = type;
    //   if (type == 0) {
    //     type = "";
    //   }
    //   api.Home.listbytype({
    //     mealtype: type,
    //     pageNum: num,
    //     pageSize: this.size,

    //   }).then((res) => {
    //     // if(res.rows == null){
    //     //   return
    //     // }
    //     this.examType.forEach((element) => {
    //       res.rows.forEach((element1) => {
    //         if (element.dictValue == element1.examType) {
    //           element1.examType = element.dictLabel;
    //         }
    //       });
    //     });
    //     if (res.total == 0) {
    //       this.AllList[this.type].finished = true;
    //       this.packageList = [];
    //       return false;
    //     }
    //     this.AllList[this.type].total = res.total;
    //     for (let i = 0; i < res.rows.length; i++) {
    //       if (res.rows[i].mealId == this.hotList.mealId) {
    //         res.rows.splice(i, 1);
    //       }
    //     }
    //     this.AllList[this.type].children = this.AllList[
    //       this.type
    //     ].children.concat(res.rows);
    //     if (this.AllList[this.type].children.length >= res.total) {
    //       this.AllList[this.type].finished = true;
    //     } else {
    //       this.AllList[this.type].finished = false;
    //     }
    //     if (select == "loading") {
    //       this.loading = false;
    //       this.AllList[this.type].num = num;
    //     } else if (select == "pullRefresh") {
    //       this.refreshing = false;
    //       this.AllList[this.type].finished = false;
    //       for (let i = 0; i < res.rows.length; i++) {
    //         if (res.rows[i].mealId == this.hotList.mealId) {
    //           res.rows.splice(i, 1);
    //         }
    //       }
    //       this.AllList[this.type].children = res.rows;
    //       this.getHotList();
    //     }
    //     this.packageList = this.AllList[this.type].children;
    //   });
    // },
    getOneNum(type) {
      this.type = type;
      if (type == 0) {
        type = "";
      }
      api.Home.listbytype({
        mealtype: type,
      }).then((res) => {
        // if(res.rows == null){
        //   return
        this.examType.forEach((element) => {
          res.rows.forEach((element1) => {
            if (element.dictValue == element1.examType) {
              element1.examType = element.dictLabel;
            }
          });
        });
        // }
        for (let i = 0; i < res.rows.length; i++) {
          if (res.rows[i].mealId == this.hotList.mealId) {
            res.rows.splice(i, 1);
            res.rows.unshift(this.hotList);
          }
        }
        this.packageList = res.rows;
      });
    },
    // getHotList() {
    //   var type = this.type;
    //   if (type == 0) {
    //     type = "";
    //   }
    //   api.Home.listbytype({
    //     mealtype: type,
    //   }).then((res) => {
    //     res.rows.forEach((element) => {
    //       if (element.mealId == this.hotList.mealId) {
    //         this.packageList.unshift(this.hotList);
    //         return;
    //       }
    //     });
    //   });
    // },
    // onLoad() {
    //   if (this.AllList[this.type]) {
    //     if (!this.AllList[this.type].finished) {
    //       this.loading = true;
    //       this.AllList[this.type].num = this.AllList[this.type].num + 1;
    //       this.getOneNum("loading", this.type, this.AllList[this.type].num);
    //       return false;
    //     }
    //   }
    //   this.loading = false;
    // },
    // onRefresh() {
    //   // this.AllList[this.type].num = 1;
    //   this.packageList = []
    //   // this.AllList[this.type].children = [];
    //   this.getOneNum(this.type);
    // },
    // 点击分类
    typeSelect(dictValue) {
      this.type = dictValue;
      // this.AllList.forEach((element) => {
      // if (element.dictValue == dictValue) {
      // if (element.finished == null) {
      // this.getOneNum(null, dictValue, element.num);
      this.getOneNum(dictValue);
      // this.getHotList();
      // } else {
      // this.packageList = element.children;
      // }
      // }
      // });
      this.$router.replace({
        query: { type: dictValue },
      });
    },
    showNoMore(value) {
      this.show = value;
    },
  },
  data() {
    return {
      // examType
      examType: [],
      // 热门
      hotList: {},
      //套餐类型
      typeList: [],
      type: this.$route.query.type,
      //套餐列表
      packageList: [],
      show: false,
      num: 1, //当前页数
      size: 5, //条数
      loading: false,
      finished: false,
      refreshing: false,
      AllList: [],
      change: false,
    };
  },
};
</script>

<style scoped>
.all {
  width: 100%;
  min-height: 90vh;
  overflow-x: hidden;
}
.type {
  position: fixed;
  top: 0.7rem;
  bottom: 0;
  width: 100%;
  height: 1.8rem;
  overflow-x: scroll;
  overflow-y: hidden;
  z-index: 99;
}
.black {
  position: fixed;
  top: 3rem;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 99;
}
</style>
