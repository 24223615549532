<template>
  <div class="allCan" :style="{ height: Bigheight + 'rem' }">
    <div class="up">
      <div class="left">查看预约时间</div>
      <div class="right">{{ currentYear }}年</div>
    </div>
    <div
      id="calendar"
      :class="{ change: isChange }"
      :style="{ height: height + 'rem' }"
    >
      <!-- 年份 月份 -->
      <div class="year-month">
        <div class="year-month_left">
          <span
            :class="item.toOrg == true ? 'org' : 'top-time'"
            v-for="item in month"
            :key="item"
            @click="toMonth(item)"
            >{{ item.num }}月</span
          >
          <van-icon
            v-show="this.team==''"
            @click="toBig"
            class="btn"
            name="arrow-down"
          />
          <!-- <ul>
            <li>周{{ weekdays[newWeek] }}</li>
            <li>{{ currentYear }}年</li>
          </ul> -->
        </div>
      </div>
      <!-- 星期 -->
      <div class="week_day">
        <ul class="weekdays">
          <li v-for="(vo, index) in weekdays" v-text="vo" :key="index"></li>
        </ul>
        <!-- 日期 -->
        <ul
          class="days"
          :class="{
            fadeOut: fadeOut,
            fadeIn: fadeIn,
            fadeOutR: fadeOutR,
            fadeInR: fadeInR,
          }"
          @touchstart="allTouchStart"
          @touchend="allTouchEnd"
          @touchstart.stop="touchStart"
          @touchend.stop="touchEnd"
        >
          <!-- 核心 v-for循环 每一次循环用<li>标签创建一天 -->
          <li
            v-for="(dayobject, index) in days"
            :class="{ weekend: index % 7 === 0 || (index + 1) % 7 === 0 }"
            :key="index"
          >
            <!--本月-->
            <!--如果不是本月 改变类名加灰色-->
            <div
              v-if="dayobject.day.getMonth() + 1 !== currentMonth"
              @click="otherMonth(dayobject.day.getDate())"
              class="other-month"
            >
              <!-- {{ dayobject.day.getDate() }} -->
            </div>
            <!--如果是本月 还需要判断是不是这一天-->
            <div v-else class="everyDay">
              <div
                :class="{
                  otherday: dayobject.day.getDate() === otherDay,
                  one: dayobject.status == 2,
                  two: dayobject.status == 1,
                  three: dayobject.status == 0,
                }"
                @click="
                  getDayMessage(
                    currentYear,
                    currentMonth,
                    dayobject.day.getDate(),
                    dayobject
                  )
                "
              >
                <div
                  :class="
                    dayobject.SelectDay == true ? 'selectDay' : 'noSelectDay'
                  "
                >
                  {{ dayobject.day.getDate() }}
                </div>
                <!-- {{ dayobject.day.getDate() }} -->
                <div
                  class="type"
                  :class="
                    dayobject.status == 0 || dayobject.status == 2
                      ? 'oneType'
                      : 'twoType'
                  "
                >
                  {{ dayobject.type }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api/Index";
export default {
  name: "Calendar",
  props: ["day", "selectOne"],

  data() {
    return {
      time: [
        { num: "08:00-09:00", status: "false" },
        { num: "09:00-10:00", status: "false" },
        { num: "10:00-11:00", status: "false" },
      ],
      month: [],
      currentDay: 1,
      currentMonth: 1,
      currentYear: 2022,
      currentWeek: 1,
      newWeek: 1,
      days: [],
      weekdays: ["一", "二", "三", "四", "五", "六", "日"],
      // 上下滑动的鼠标位置
      positionSX: "",
      positionEX: "",
      positionSY: "",
      positionEY: "",
      isChange: false,
      // 左右滑动动画的初始状态
      show: true,
      fadeOut: false,
      fadeIn: false,
      fadeOutR: false,
      fadeInR: false,
      monthList: [],
      status: "",
      otherDay: "",
      height: "2.7",
      Bigheight: "3.6",
      //开始时间
      start: "2022-03-13",
      //结束时间
      end: "2022-04-11",
      //可约时间段
      NoDay: [],
      a: [],
      min: "",
      select: "",
      team: this.$store.state.team,
    };
  },
  created() {
    if (this.team != "") {
      this.height = "7.88";
      this.Bigheight = "8.8";
    }
    api.ProjectDetail.appointmentDate().then((res) => {
      res[0].appointmentDateList.forEach((element) => {
        this.NoDay.push(element.appointmentDate);
      });
      this.start = res[0].startTime;
      this.end = res[0].endTime;
      this.NoDay = JSON.parse(JSON.stringify(this.NoDay));
      var start = new Date();
      this.getMonth();
      this.initData(
        this.formatDate(start.getFullYear(), start.getMonth() + 1, 1)
      );
    });
  },
  mounted() {
  },
  methods: {
    //时间段选择方法
    toTime(item) {
      this.time.forEach((element) => {
        element.status = false;
      });
      if (item.status == false) {
        item.status = true;
      } else {
        item.status = false;
      }
    },
    //得到月份
    getMonth() {
      var d = new Date(this.end);
      var end = d.getMonth() + 1;
      var date = new Date();
      this.currentYear = date.getFullYear();
      var m = date.getMonth() + 1;
      for (var i = m; i <= end; i++) {
        this.month.push({ num: i, toOrg: "false" });
        this.month[0].toOrg = true;
      }
    },
    //切换月份
    toMonth(item) {
      this.month.forEach((element) => {
        element.toOrg = false;
      });
      item.toOrg = !item.toOrg;
      this.pickNext(this.currentYear, item.num);
      if (this.select != "") {
        this.days.forEach((element) => {
          if (this.select.day.getTime() == element.day.getTime()) {
            element.SelectDay = true;
            this.$emit(
              "change",
              this.formatDate(
                this.select.day.getFullYear(),
                this.select.day.getMonth(),
                this.select.day.getDate()
              ),
              this.select.day.getMonth(),
              this.select.day.getDate()
            );
            this.$emit("time", this.select, true);
          } else {
            element.SelectDay = false;
          }
        });
      }
    },
    //得到每个月的时间
    getDayMessage(y, m, d, items) {
      var dd = new Date();
      // var start = this.start.split("-");
      // var end = this.end.split("-");
      if (items.spec == 2) {
        this.select = items;
        this.select.day = items.day;
      }

      this.days.forEach((element) => {
        if (items == element) {
          if (element.spec == 1) {
            element.SelectDay = false;
          } else {
            element.SelectDay = true;
            this.$emit("time", items, false);
          }
          if (element.day.getMonth() == dd.getMonth()) {
            if (element.day.getDate() < dd.getDate()) {
              element.SelectDay = false;
            }
          }
        } else {
          element.SelectDay = false;
        }
      });
      this.getCurrentWeek(y, m, d);
      const str = this.formatDate(y, m, d);
      this.$emit("change", str, m, d);
      this.otherDay = d;
    },
    getCurrentWeek(y, m, d) {
      const w = `${y}-${m}-${d}`;
      const weekArr = w.split("-");
      const weeks = new Date(weekArr[0], parseInt(weekArr[1] - 1), weekArr[2]);
      this.newWeek = weeks.getDay();
    },
    otherMonth(day) {
      if (day < 15) {
        this.rightSliding();
      } else if (day > 15) {
        this.leftSliding();
      }
    },
    addSchedule() {
      this.$emit("add");
    },
    toBig() {
      if (this.height === "2.7") {
        this.height = "7.88";
        this.Bigheight = "8.8";
      } else {
        this.height = "2.7";
        this.Bigheight = "3.6";
      }
    },
    initData(cur) {
      let date;
      if (cur) {
        date = new Date(cur);
      } else {
        const now = new Date();
        const t = this.formatDate(now.getFullYear(), now.getMonth(), 1);
        const d = new Date(t);
        d.setDate(35);
        date = new Date(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      }
      // this.pickNext(date.getMonth())
      this.currentDay = date.getDate();
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;

      if (date.getDay() > 0) {
        this.currentWeek = date.getDay() - 1;
      } else {
        this.currentWeek = 6;
      }

      if (!cur || !this.otherDay) {
        this.otherDay = new Date().getDate();
      }
      const str = this.formatDate(
        this.currentYear,
        this.currentMonth,
        this.currentDay
      );
      this.days.length = 0;
      var a = [];
      //初始化本周
      this.NoDay.forEach((element) => {
        a.push(element.split("-"));
      });
      for (let i = this.currentWeek; i >= 0; i--) {
        const d = new Date(str);
        d.setDate(d.getDate() - i);
        const dayobject = {}; //用一个对象包装Date对象 以便为以后预定功能添加属性
        dayobject.day = d;
        dayobject.type = "不可约";
        dayobject.status = "2";
        dayobject.spec = "1";
        this.days.push(dayobject);
      }
      //其他周
      for (let i = 1; i <= 36 - this.currentWeek; i++) {
        const d = new Date(str);
        var MinBookDate = this.NoDay[0];
        // }
        for (let index = 0; index < this.NoDay.length; index++) {
          if (
            new Date(this.NoDay[index]).getMonth() + 1 === this.currentMonth &&
            new Date(this.NoDay[index]).getDate() >= new Date().getDate()
          ) {
            if (new Date(this.NoDay[index]).getDate() == new Date().getDate()) {
              var MinBookDate = this.NoDay[index];
            }
            if (
              new Date(this.NoDay[index]).getDate() <
              new Date(MinBookDate).getDate()
            ) {
              MinBookDate = this.NoDay[index];
            }
          }
        }
        var dd = new Date();
        d.setDate(d.getDate() + i);
        const dayobject = {};
        dayobject.day = d;
        dayobject.type = "不可约";
        dayobject.status = "2";
        dayobject.spec = 1;
        this.days.push(dayobject);
        a.forEach((element) => {
          var time = new Date(element[0], element[1], element[2]); //可约的时间
          this.days.forEach((item) => {
            var time2 = new Date(
              item.day.getFullYear(),
              item.day.getMonth() + 1,
              item.day.getDate()
            ); //全部的时间
            if (item.day.getMonth() + 1 === this.currentMonth) {
              if (time.getTime() === time2.getTime()) {
                item.type = "可预约";
                item.status = "1";
                item.spec = 2;
              }
              if (time2.getTime() < new Date(MinBookDate).getTime()) {
                item.type = "不可约";
                item.status = "2";
                item.spec = 1;
              }
              if (item.day.getTime() <= Date.now() - 24 * 60 * 60 * 1000) {
                item.type = "";
                item.status = "2";
                item.spec = 1;
              }
              // if (this.day == null || this.day == "") {
              if (this.day != null) {
                var lastDate = this.day.split("-");
                if (
                  time2.getTime() ==
                  new Date(lastDate[0], lastDate[1], lastDate[2]).getTime()
                ) {
                  item.SelectDay = true;
                }
              }
              if (item.day.getTime() == new Date(MinBookDate).getTime()) {
                if (this.day == null || this.day == "") {
                  item.SelectDay = true;
                }
              }
              // }
              // } else if (this.day != null || this.day != "") {
              // var d = new Date(this.day);
              // if (item.day.getTime() == d.getTime()) {
              //   item.SelectDay = true;
              // }
              // }

              if (
                !this.NoDay.includes(
                  this.formatDate(
                    dd.getFullYear(),
                    dd.getMonth() + 1,
                    dd.getDate()
                  )
                )
              ) {
                // if (item.day.getDate() === dd.getDate()) {
                //   item.type = "不可约";
                //   item.status = "2";
                //   item.spec = 1;
                // }
              } else {
                // if (item.day.getDate() === dd.getDate()) {
                //   item.type = "可预约";
                //   item.status = "1";
                //   item.spec = 2;
                // }
              }
            }
          });
        });
      }
      this.$emit(
        "MinBookDate",
        this.formatDate(
          new Date(MinBookDate).getFullYear(),
          new Date(MinBookDate).getMonth() + 1,
          new Date(MinBookDate).getDate()
        ),
        this.select.day
      );
      this.min = MinBookDate;
      //选中日期在其他月份是否超出最大日期判断
      const arr = [];
      for (let j = 0, length = this.days.length; j < length; j++) {
        if (this.days[j].day.getMonth() + 1 === this.currentMonth) {
          arr.push(this.days[j].day.getDate());
        }
      }
      const maxDate = Math.max.apply(null, arr);
      if (this.otherDay > maxDate) {
        this.otherDay = maxDate;
      }

      // this.getDayMessage(this.currentYear, this.currentMonth, this.otherDay);
    },
    //     上个月信息
    pickPre(year, month) {
      const d = new Date(this.formatDate(year, month, 1));
      d.setDate(0);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 下个月信息
    pickNext(year, month) {
      const d = new Date(this.formatDate(year, month, 1));
      //  d.setDate(30);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 补零处理
    formatDate(year, month, day) {
      let y = year;
      let m = month;
      if (m < 10) m = "0" + m;
      let d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
  },
};
</script>

<style lang="less" scoped>
.up {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 0.32rem 0.2rem 0.32rem;
  padding-top: 0.18rem;
}
.up .left {
  font-size: 0.24rem;
  font-weight: 600;
  color: #000000;
  line-height: 0.33rem;
  letter-spacing: 0.0208rem;
}
.up .right {
  font-size: 0.24rem;
  font-weight: 600;
  color: #b9947d;
  line-height: 0.33rem;
  letter-spacing: 0.0208rem;
}
.allCan {
  margin: 0 0.32rem;
  background-color: var(--bookColor);
  border-radius: 0.2rem;
  transition: all 0.5s;
}
#calendar {
  width: 6.48rem;
  margin: 0 auto;
  transition: all 0.5s;
  overflow: hidden;
  background: #ffffff;
  border-radius: 0.2rem;
  .change {
  }
  .btn {
    position: absolute;
    right: 0.32rem;
  }
  .year-month {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    .year-month_left {
      width: 100%;
      padding-left: 0.32rem;
      display: flex;

      align-items: center;
      .top-time {
        font-size: 0.24rem;
        font-weight: 500;
        color: #444444;
        line-height: 0.33rem;
        letter-spacing: 0.01rem;
        margin: 0.18rem 0.6rem 0.27rem 0;
      }
      ul {
        display: flex;
        flex-direction: column;
      }
    }
    .year-month_right {
      height: 100%;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .i-plus-empty {
        fill: #f18d2f;
      }
    }
  }
  .weekdays {
    margin: 0;
    font-size: 0.24rem;
    display: flex;
    flex-wrap: wrap;
    color: #c2c2c2;
    // justify-content: space-around;
    li {
      display: inline-block;
      width: 13.6%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .type {
    font-size: 0.22rem;
  }
  .days {
    margin: 0.16rem 0 0 0;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    font-size: 0.28rem;
    li {
      list-style-type: none;
      display: inline-block;
      margin-bottom: 0.2rem;
      width: 13.6%;
      text-align: center;
      position: relative;
      .active {
        border-radius: 50%;
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .other-month {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #a2a2a2;
      }
      .everyDay {
        height: 0.85rem;
        margin: auto;
        display: flex;
        justify-content: center;
      }
    }
  }
  .fadeOut {
    animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }
  .fadeOutR {
    animation-name: fadeOutR;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }
  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }
  .fadeInR {
    animation-name: fadeInR;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }
  .background {
    .dbg {
      background-color: #e1e1e1;
    }
    .lbg {
      background-color: #d5d5d5;
    }
  }
}

@keyframes fadeOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeOutR {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes fadeInR {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.org {
  font-size: 0.32rem;
  font-weight: 500;
  color: #ff8230;
  line-height: 0.45rem;
  margin: 0.18rem 0.6rem 0.27rem 0;
}
.time {
  width: 6.48rem;
  padding: 0 0.53rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.time_box {
  height: 0.56rem;
  background: #f2f2f2;
  border-radius: 0.28rem;
  color: #444444;
  line-height: 0.4rem;
  letter-spacing: 1px;
  padding: 0.08rem 0.36rem;
  margin-top: 0.15rem;
}
.isStatus {
  height: 0.56rem;
  background: #fff5ef;
  border-radius: 0.28rem;
  color: #ff8230;
  line-height: 0.4rem;
  letter-spacing: 1px;
  padding: 0.06rem 0.34rem;
  margin-top: 0.15rem;
  border: 0.01rem solid #ffb98c;
}
.oneType {
  color: #bdbdbd;
}
.twoType {
  color: #ff8230;
  font-weight: 600;
}
.one {
  color: #bdbdbd;
}
.two {
  color: #000000;
  font-weight: bold;
}
.three {
  color: #000000;
  font-weight: bold;
}
.week_day {
  padding-left: 0.2rem;
}
.selectDay {
  background-color: #ff8230;
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  line-height: 0.7rem;
}
.noSelectDay {
  width: 0.7rem;
  height: 0.7rem;
  line-height: 0.7rem;
}
.type {
  margin-bottom: 0.6rem;
}
</style>
