<template>
  <div
    class="tc_list"
    v-for="(item, index) in packageList"
    :key="index"
    @click="toDetail(item.mealId)"
  >
    <div class="left">
      <div class="hoticon" v-show="item.hotStatus== 1">
        <div>
          <img
            style="height: 0.4rem; width: 1.2rem"
            src="@/assets/img/hotIcon.png"
            alt=""
          />
          <div class="hotTitle">热销套餐</div>
        </div>
      </div>
      <img style="border-radius: 0.1rem;object-fit:cover" :src="item.imgUrl" alt="" />
    </div>
    <div class="right">
      <div class="tc_name">{{ item.mealName }}</div>
      <div class="middle">
        <div class="desc van-multi-ellipsis--l2 ">
          <span class="type font_type">{{item.examType}}</span
          >{{ item.introduction }}
        </div>
      </div>
      <div class="bottom">
        <div class="price">￥{{ item.price }}</div>
        <div class="people" v-show="isShow">{{ item.orderCount }}人已预约</div>
      </div>
    </div>
  </div>
</template>

<script>
import { PullRefresh } from "vant";
import store from '@/store/index';
export default {
  props: ["isHot", "packageList", "loading", "finished", "num","isShow"],
  //  props: {isHot:'',packageList:[]},
  components: {
    vanPullRefresh: PullRefresh,
  },
  created(){
  },
  methods: {
    toDetail(id) {
      this.$router.push({ path: "/ProjectDetail", query: { id: id }});
      this.$store.state.path = this.$route.path
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.tc_list .type {
  color: #0cb7b2;
  border: 0.01rem solid #0cb7b2;
  height: 0.4rem;
  width: 0.7rem;
  padding: 0rem 0.1rem 0.01rem 0.1rem;
  border-radius: 0.1rem;
  margin-right: 0.16rem;
  font-size: 0.2rem;
}
.tc_list {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0.32rem 0 0.32rem;
  width: 100%;
  height: 2.2rem;
  letter-spacing: 0.01rem;
}
.tc_list .left {
  width: 2rem;
  margin-right: 0.28rem;
  height: 2rem;
  position: relative;
}
.tc_list .left img {
  height: 2rem;
  width: 2rem;
  object-fit:cover
}
.tc_list .right .tc_name {
  color: #000000;
  font-size: 0.28rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
  letter-spacing: 0.01rem;
}
.tc_list .right .middle {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.tc_list .right .bottom {
  display: flex;
  align-items: flex-end;
  /* background: #0cc5bd; */
  /* position: absolute;
    bottom: -0.1rem; */
  justify-content: space-between;
  height: 0.7rem;
  width: 4.48rem;
  margin: 0 0.32rem 0 0;
  position: absolute;
  bottom: 0;
}
.tc_list .right .bottom .price {
  color:var(--mealPrice);
  font-size: 0.3rem;
  font-weight: bold;
  text-align: right;
}
.tc_list .right .bottom .people {
  color: #999999;
}
.tc_list .right {
  width: 65%;
  height: 2rem;
  position: relative;
}
.tc_list .desc {
  padding-bottom: 0.1rem;
  /* text-overflow: -o-ellipsis-lastline; */
  color: #666666;
  font-size: 0.24rem;
  margin: 0 0.32rem 0 0;
  line-height: 0.5rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
  /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical; */
}
.hoticon {
  position: absolute;
  top: 0.1rem;
  left: -0.04rem;
}
.hotTitle {
  color: #ffffff;
  font-size: 0.25rem;
  position: absolute;
  left: 0.05rem;
  top: 0.05rem;
}
</style>
