<template>
  <van-nav-bar title="预订须知" left-arrow @click-left="onClickLeft" fixed>
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
          class="van-badge__wrapper van-icon van-icon-ellipsis"
          style="font-size: 0.5208rem; color: #2e2e2e"
        ></i> -->
         <img style="width:0.4rem" src="@/assets/img/user11.png" alt="">
      </router-link>
    </template>
  </van-nav-bar>
  <div class="all bg_white pt58 pb48">
    <Title>
      <span>体检流程</span>
    </Title>
    <div class="content mt17 mr15 mb38 ml15">
      <div
        class="up flex_r f_ai_s"
        v-for="(item, index) in stepList"
        :key="index"
      >
        <div class="left flex_c">
          <div class="left_box bg_5ef brs_25 flex_r f_ai_c">
            <img class="mauto" :src="item.img" alt="" />
          </div>
          <div
            v-show="index != length - 1"
            class="bottom mt7 mb7 mauto pt10 pb10"
          ></div>
        </div>
        <div class="right flex_c ml10">
          <span class="title cr_ff8 fs14 fw600 f_ls_1">{{ item.title }}</span
          ><span class="desc cr_000 fs12 f_ls_1">{{ item.desc }}</span>
        </div>
      </div>
    </div>
    <Title>
      <span>体检须知</span>
    </Title>
    <div
      class="inform mt18 mr15 mb38 ml15"
      v-for="(item, index) in informList"
      :key="index"
    >
      <div
        class="title fs12 cr_ff8 fw600 bg_ffe brs_5"
        style="text-align: center"
      >
        {{ item.title }}
      </div>
      <ul class="bg_f8 brs_10 pt12 pr12 pb4 pl12">
        <li
          class="tl fs12 cr_000 lh18 flex_r mb8"
          v-for="i in item.content"
          :key="i"
        >
          <div
            style="
              width: 0.12rem;
              height: 0.12rem;
              margin-right: 0.11rem;
              line-height: 0.3rem;
            "
          >
            <van-icon
              style="
                width: 0.12rem;
                height: 0.12rem;
                background-color: #ffc8a4;
                border-radius: 50%;
              "
            />
          </div>
          {{ i }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { NavBar } from "vant";
import Title from "@/components/content/Title.vue";

export default {
  components: {
    vanNavBar: NavBar,
    Title,
  },
  mounted() {
    this.stepList.forEach((element) => {
      this.length++;
    });
  },
  data() {
    return {
      informList: [
        {
          title: "体检前",
          content: [
            "体检前一天请您清淡饮食，勿饮酒、勿劳累。体检当天请空腹禁食。",
            "体检前一天要注意休息，晚上8点后不再进食。避免剧烈运动和情绪激动，保证充足睡眠，以免影响体检结果。",
            "例假期间不宜做妇科、尿液检查。",
          ],
        },
        {
          title: "体检中",
          content: [
            "需空腹检查的项目为抽血、腹部B超、数字胃肠，胃镜及其它标注的体检项目。",
            "做膀胱、子宫、附件B超时请勿排尿，如无尿需饮水至膀胱充盈。做妇科检查前应排空尿。",
            "未婚女性不做妇科检查；怀孕的女性请预先告知医护人员,不安排做放射及其他有影响的检查。",
            "做放射线检查前,请您除去身上佩戴的金银、玉器等饰物。",
            "核磁共振检查，应禁止佩带首饰、手表、传呼、手机等金属物品，磁卡也不应带入检查室，以防消磁。",
          ],
        },
        {
          title: "体检后",
          content: [
            "全部项目完毕后请您务必将体检单交到前台。",
            "请您认真听取医生的建议,及时复查、随诊或进一步检查治疗。",
            "请您保存好体检结果，以便和下次体检结果作对照也可作为您就医时的资料。",
          ],
        },
      ],
      length: "",
      stepList: [
        {
          img: require("@/assets/img/content/x1.png"),
          title: "选择套餐",
          desc: "选择需要的体检套餐，完成下单。",
        },
        {
          img: require("@/assets/img/content/x2.png"),
          title: "预约体检",
          desc: "付款完成后，点击“体检预约”或到“我的订单”中预约体检。",
        },
        {
          img: require("@/assets/img/content/x3.png"),
          title: "到院服务",
          desc: "体检当日现场出示身份证即可体检,注:到医院无需另行支付其他费用 (现场加项、申请快递报告除外) 。",
        },
        {
          img: require("@/assets/img/content/x4.png"),
          title: "领取报告",
          desc: "体检报告一般在3 - 15个工作日出结果，部分医院支持在线查询报告、报告邮寄，各医院领取时间、方式不同， 具体的报告领取方式和时间可现场咨询医院工作人员。",
        },
      ],
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return { onClickLeft };
  },
};
</script>

<style scoped>
.all {
  width: 100%;
}
.content .left .left_box {
  width: 0.88rem;
  height: 0.88rem;
}
.content .left img {
  width: 0.48rem;
  height: 0.48rem;
}
.content .right .desc {
  width: 5.78rem;
}
.content .bottom {
  min-height: 0.3rem;
  border-left: 0.01rem dashed #cecece;
}
.inform {
  width: 6.86rem;
  /* position: relative; */
}
.inform .title {
  width: 1.01rem;
  height: 0.55rem;
  position: absolute;
  /* bottom: 0.1rem; */
}
.inform ul {
  width: 6.86rem;
  top: 0.34rem;
  min-height: 100%;
  position: relative;
}
</style>
