<template>
  <div class="examinator bg_f0f pt48">
    <van-nav-bar title="体检人信息" left-arrow @click-left="onClickLeft" fixed>
      <template #left>
        <i
          class="van-badge__wrapper van-icon van-icon-cross"
          style="font-size: 0.4583rem; color: #2e2e2e"
        ></i>
      </template>
      <template #right>
        <router-link to="/User">
          <!-- <i
            class="van-badge__wrapper van-icon van-icon-ellipsis"
            style="font-size: 0.5208rem; color: #2e2e2e"
            @click="goUser"
          ></i> -->
           <img style="width:0.4rem" src="@/assets/img/user11.png" alt="">
        </router-link>
      </template>
    </van-nav-bar>

    <!-- 体检人信息卡片 -->
    <div class="main pb67 bg_f0f">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        success-text="刷新成功"
      >
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <div
            class="card mr15 ml15"
            v-for="item in examinatorList"
            :key="item.id"
            @click="chooseExaminator(item)"
          >
            <van-swipe-cell>
              <!-- 卡片 -->
              <div
                class="people_list bg_white brs_10 mt10 mb10 mauto pt13 pb13"
              >
                <div class="top f_ai_c flex_r pr15 pl15">
                  <div class="name fs16 fw600 cr_000 lh22 f_ls_1 mr8">
                    {{ item.medicalName }}
                  </div>
                  <div
                    class="sex fs12 fw600 cr_fff lh17 brs_2 tc mr8"
                    :class="{ girl: item.sex == '女', boy: item.sex == '男' }"
                  >
                    {{ item.sex }}
                  </div>
                  <div
                    class="
                      connect
                      bg_5ef
                      brs_2
                      br_1_8c
                      fs12
                      fw600
                      cr_ff8
                      lh14
                      tc
                    "
                    v-if="item.relationship"
                  >
                    {{ item.relationship }}
                  </div>
                </div>
                <div
                  class="bottom flex_r pr15 pl15 f_jc_b fs14 cr_999 lh20 f_ls_1"
                >
                  <div class="left mt8">
                    <div class="phone">
                      手机号码:<span>{{ item.telephone }}</span>
                    </div>
                    <div class="idNum">
                      证件号码:<span>{{ item.cardNumber }}</span>
                    </div>
                  </div>
                  <div class="right" @click.stop="editExaminator(item.id)">
                    <img src="@/assets/img/bianji.png" alt="" />
                  </div>
                </div>
              </div>
              <!-- 滑动删除 -->
              <template #right>
                <van-button
                  square
                  text="删除"
                  type="danger"
                  class="delete-button"
                  @click="delExaminator(item)"
                />
              </template>
            </van-swipe-cell>
          </div>
        </van-list>
      </van-pull-refresh>

      <!-- 底部添加按钮 -->
      <van-action-bar>
        <van-action-bar-button
          type="danger"
          text="添加体检人"
          @click="goAddExaminator"
        />
      </van-action-bar>
    </div>

    <!-- 添加体检人 -->
    <div class="add">
      <!-- <van-button type="primary" round block @click="goAddTjr">
        添加体检人
      </van-button> -->
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import { useStore } from "vuex";
import {
  NavBar,
  SwipeCell,
  Button,
  Toast,
  Dialog,
  List,
  PullRefresh,
  ActionBar,
  ActionBarButton,
} from "vant";
import api from "@/api/api/Index";

export default {
  components: {
    vanNavBar: NavBar,
    vanSwipeCell: SwipeCell,
    vanButton: Button,
    vanDialog: Dialog,
    vanList: List,
    vanPullRefresh: PullRefresh,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
  },
  setup() {
    const onClickLeft = () => history.back();
    const store = new useStore();
    return {
      onClickLeft,
      store,
    };
  },
  data() {
    return {
      // 体检人信息
      examinatorList: [],
      total: 0,
      refreshing: false, //刷新提示
      loading: false, //下拉加载状态
      finished: false, //下拉加载到底状态
      queryParams: {
        //列表分页
        pageNum: 1,
        pageSize: 10,
        hospitalName: null,
        workTime: null,
        address: null,
        onlineCustomerService: null,
        imgUrl: null,
        orgInfo: null,
        modifyBy: null,
        modifyTime: null,
      },
    };
  },
  created() {
    this.getExaminatorList();
  },
  methods: {
    // 请求 体检人信息 数据
    getExaminatorList(type) {
      api.Examinator.getExaminatorList(this.queryParams).then((res) => {
        if (type === "pullRefresh") {
          // 上拉刷新
          this.examinatorList = res.rows;
          this.total = res.total;
          this.refreshing = false;
          this.finished = false;
        } else if (type === "loading") {
          // 下拉加载更多
          this.examinatorList.push(...res.rows.map((item) => item));
          this.loading = false;
          if (this.examinatorList.length >= res.total) {
            this.finished = true;
          }
        } else {
          this.examinatorList = res.rows;
          this.total = res.total;
        }

        api.Examinator.dictType("sys_user_sex").then((res) => {
          this.examinatorList.forEach((item, index) => {
            res.forEach((resItem) => {
              if (item.gender == resItem.dictValue) {
                this.examinatorList[index].sex = resItem.dictLabel;
              }
            });
          });
        });
      });
    },

    // 刷新体检人列表
    onRefresh() {
      this.queryParams.pageNum = 1;
      this.getExaminatorList("pullRefresh");
    },

    // 下拉加载更多体检人列表
    onLoad() {
      this.queryParams.pageNum++;
      // this.getExaminatorList("loading");
      if (this.examinatorList.length >= this.total) {
        this.finished = true;
      } else {
        this.getExaminatorList("loading");
      }
    },

    // 跳转至添加体检人
    goAddExaminator() {
      this.$router.push({
        name: "AddExaminator",
      });
    },
    // 选择体检人
    chooseExaminator(data) {
      if (this.$route.query.redirect) {
        var url = this.$route.query.redirect;
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
          var str = url.substr(url.indexOf("?") + 1, url.length);
          var strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
          }
        }
        this.$router.push({
          name: url.substr(1, url.indexOf("?") - 1),
          query: theRequest,
          params: data,
        });
      }
    },
    // 编辑体检人
    editExaminator(id) {
      this.$router.push({
        name: "AddExaminator",
        query: { id },
      });
    },
    // 删除体检人信息
    delExaminator(item) {
      Dialog.confirm({
        message: "你确定要删除该体检人吗?",
        width: "5rem",
      })
        .then(() => {
          Toast.loading({
            message: "删除中...",
            forbidClick: true,
          });

          api.Examinator.deleteExaminator(item.id).then((res) => {
            Toast.success("删除成功");
            this.queryParams.pageNum = 1;
            this.getExaminatorList();
          });
        })
        .catch(() => {});
    },
    // 跳转到user页面
    goUser() {
      this.$router.push({ name: "User" });
    },
  },
};
</script>

<style scoped>
.examinator {
  height: 100vh;
}
.card {
  margin: 0 0.32rem;
}
.card .van-button {
  height: 1.98rem;
}
.people_list {
  width: 6.86rem;
  height: 1.98rem;
}
.people_list .right img {
  width: 0.37rem;
  height: 0.37rem;
}
.people_list .top .sex {
  width: 0.56rem;
  height: 0.36rem;
}
.girl {
  background: #f86565;
}
.boy {
  background: #00c7c2;
}

.people_list .top .connect {
  width: 0.72rem;
  height: 0.36rem;
}

.van-action-bar-button {
  width: 6.86rem;
  height: 0.8rem;
  background: #ff8230;
  font-size: 0.32rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
}
.van-action-bar {
  padding: 0 0.2rem;
}
.delete-button {
  right: -0.01rem;
}
</style>
