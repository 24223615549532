<template>
  <div class="top">
    <div class="right" v-if="type == 0">
      <img :src="ProjectInfo.imgUrl" alt="" />
    </div>
    <div class="right" v-if="type != 0">
      <img src="@/assets/img/team/teamimg1.png" alt="" />
    </div>
    <div class="left">
      <div class="up">
        <div class="title">{{ ProjectInfo.mealName }}</div>
        <div>x<span class="num">1</span></div>
      </div>
      <div class="bottom">
        <div class="desc">{{ ProjectInfo.hospitalName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["ProjectInfo"],
  components: {},
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
  data() {
    return {
      type: 0,
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return { onClickLeft };
  },
};
</script>

<style scoped>
.all {
  background-color: white;
  width: 100%;
  padding: 0 0 1rem 0;
  min-height: 90vh;
}
.top {
  /* margin: 0.36rem 0.46rem; */
}
.left {
  width: 100%;
}
.top,
.left .up {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.right img {
  width: 1rem;
  height: 1rem;
  border-radius: 0.1rem;
  margin-right: 0.32rem;
}
.left .title {
  font-size: 0.36rem;
  font-weight: bold;
  color: #000000;
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
}
.left .desc {
  font-size: 0.24rem;
  font-weight: 500;
  color: #888888;
  line-height: 0.33rem;
  letter-spacing: 0.01rem;
}
.left .up {
  justify-content: space-between;
}
.left .num {
  font-size: 0.2rem;
  font-weight: 600;
  color: #888888;
  line-height: 0.28rem;
}
</style>
