<template>
  <van-nav-bar title="我的报告" left-arrow @click-left="onClickLeft" fixed>
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
            class="van-badge__wrapper van-icon van-icon-ellipsis"
            style="font-size: 0.5208rem; color: #2e2e2e"
          ></i> -->
        <img style="width: 0.4rem" src="@/assets/img/user11.png" alt="" />
      </router-link>
    </template>
  </van-nav-bar>
  <div class="all pt60 bg_f8 posr">
    <div class="bg_fff fs12 pd14 cr_000" style="height: 10.5rem">PDF报告</div>
    <div class="downBtn flex_c f_jc_c f_ai_c bg_fff w100 posa">
      <van-button class="bg_org cr_fff fs16 fw600" @click="show = true"
        >下载</van-button
      >
    </div>
    <van-overlay :show="show" >
      <div class="wrapper" @click.stop>
        <div class="block posr">
            <img class="close" style="width:0.27rem" src="@/assets/img/close.png" alt="" @click="show = false">
            <div class="successBox flex_c f_ai_c mt40">
                <img style="width:3.52rem;height:2.41rem" src="@/assets/img/report/success.png" alt="" >
                <div class="cr_ff8 fs16 fw600 mt20">下载成功！</div>
            </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  NavBar,
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  RadioGroup,
  Radio,
  DatetimePicker,
  Button,
  Toast,
  ActionBar,
  ActionBarButton,
  Icon,
  DropdownMenu,
  DropdownItem,
  Overlay,
} from "vant";
export default {
  data() {
    return {
      show: false,
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  components: {
    vanNavBar: NavBar,
    vanForm: Form,
    vanField: Field,
    vanCellGroup: CellGroup,
    vanPopup: Popup,
    vanPicker: Picker,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanDatetimePicker: DatetimePicker,
    vanButton: Button,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
    vanDropdownMenu: DropdownMenu,
    vanDropdownItem: DropdownItem,
    vanOverlay: Overlay,
  },
};
</script>

<style scoped>
.all {
  height: 100vh;
}
.downBtn {
  width: 6.86rem;
  bottom: 0;
  height: 1.1rem;
}
.downBtn .van-button {
  width: 6.86rem;
  height: 0.8rem;
  border-radius: 0.4rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.block {
  width: 6.22rem;
  height: 5rem;
  background: #ffffff;
  border-radius: 0.2rem;
}
.van-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.block .close{
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;

}
</style>