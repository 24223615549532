import request from '@/api/axios'; // 导入http中创建的axios实例


const Introduction = {
    //体检预约下单
    hospitalDetail(){
        return request({
            url: '/api/open/h5/exam/hospital/detail',
            method: 'get'
        })
    }
}


export default Introduction;