import Login from '@/api/api/Login.js';
import Home from '@/api/api/Home.js';
import ProjectDetail from '@/api/api/ProjectDetail.js';
import Examinator from '@/api/api/Examinator.js';
import Order from '@/api/api/Order.js';
import OrderList from '@/api/api/OrderList.js';
import OrderDetail from '@/api/api/OrderDetail.js';
import Introduction from '@/api/api/Introduction.js';
import itemCheck from '@/api/api/itemCheck.js';



export default {
    Login,
    Home,
    ProjectDetail,
    Examinator,
    Order,
    OrderList,
    OrderDetail,
    Introduction,
    itemCheck
}
