<template>
  <div class="all bg_f8 pt56 pb48 flex_c f_ai_c">
    <div class="img mb23"><img :src="List.img" alt="" /></div>
    <div class="name fs20 fw600 cr_000 lh27 f_ls_1 mb12">{{ List.title }}</div>
    <div class="desc fs14 cr_000 lh24 f_ls_1 tc" v-if="List.status != 2">
      {{ List.desc }}
    </div>
    <div class="mealBox mt25" v-show="List.status != 3">
      <div class="top flex_r f_ai_c pb8">
        <div class="left"><img src="@/assets/img/team/teamimg1.png" alt="" /></div>
        <div class="right ml8 flex_c f_jc_b">
          <div class="fs16 cr_333 fw600 t_ls1">{{ packageList.mealName }}</div>
          <div class="cr_666 fs11 t_ls1">
            {{ packageList.hospitalName }}
          </div>
        </div>
      </div>
      <div
        class="bottom flex_r f_ai_c f_jc_b pt16 br_t_1_e1e1"
        @click="toDetail('Detail')"
      >
        <div class="b_left">共{{ packageList.sum }}项可检查项目</div>
        <div class="b_right">
          <van-icon name="arrow" color="#cccccc" />
        </div>
      </div>
    </div>
    <div class="userInfo mt10" v-show="List.status != 3">
      <div class="fs16 fw600 cr_333 t_ls1">预约详情</div>
      <div class="flex_r">
        <div>体检人：</div>
        <span>{{ personInfo.name }}</span>
      </div>
      <div class="flex_r">
        <div>手机号码：</div>
        <span>{{ personInfo.phone }}</span>
      </div>
      <div class="flex_r">
        <div>证件号码：</div>
        <span>{{ personInfo.id_number }}</span>
      </div>
      <div class="flex_r">
        <div>预约时间：</div>
        <span>{{ personInfo.reserve_date }}</span>
      </div>
    </div>
    <div class="OrderBtn mr46 ml46 mt30">
      <van-button
        class="one brs_20 br_1_c0 fs14 fw600 lh22 f_ls_1 mb17"
        v-show="List.status == 3"
        :style="{
          color: item.color,
          background: item.bc,
          width: '5.58rem',
          height: '0.8rem',
        }"
        v-for="item in List.btn"
        :key="item"
        @click="toPage(item.path)"
        >{{ item.name }}</van-button
      >
      <van-button
        class="brs_20 br_1_c0 fs14 fw600 lh22 f_ls_1 mb17"
        v-show="List.status != 3"
        :style="{
          color: item.color,
          background: item.bc,
          width: '6.76rem',
          height: '0.8rem',
        }"
        v-for="item in List.btn"
        :key="item"
        @click="toPage(item.path)"
        >{{ item.name }}</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import api from "@/api/api/Index";
import { Button, NavBar, Dialog } from "vant";
export default {
  components: {
    vanNavBar: NavBar,
    vanButton: Button,
  },
  beforeRouteEnter(to, form, next) {
    document.title = "预约信息";
    next();
  },
  setup() {
    const onClickLeft = () => history.back();
    const active = ref(0);
    const { proxy } = getCurrentInstance();
    return {
      onClickLeft,
      active,
      proxy,
    };
  },
  created() {
    api.itemCheck
      .getUserGroupPackageInformation(this.$route.query.data)
      .then((res) => {
        this.personInfo = res;
        this.status = this.personInfo.status;
        this.OrderList.forEach((element) => {
          if (element.status == this.status) {
            this.List = element;
          }
        });
        if (this.personInfo.status == 1) {
          this.$store.state.interval = setInterval(() => {
            if (this.personInfo.status == 1) {
              this.getUserGroupPackageInformation();
            } else {
              clearInterval(this.$store.state.interval);
            }
          }, 5000);
        }
      })
      .then(() => {
        //套餐信息
        api.itemCheck.selectByReserveRegisterNo(this.personInfo.meal_id).then((res) => {
          var packageList = res[0];
          var sum = 0;
          packageList.resultvo.forEach((item, index) => {
            sum += item.itemVoList.length;
          });
          packageList.sum = sum;
          this.packageList = packageList;
        });
      });
  },
  mounted() {},
  methods: {
    getUserGroupPackageInformation() {
      api.itemCheck
        .getUserGroupPackageInformation(this.$route.query.data)
        .then((res) => {
          this.personInfo = res;
          this.status = this.personInfo.status;
          this.OrderList.forEach((element) => {
            if (element.status == this.status) {
              this.List = element;
            }
          });
        });
    },
    toDetail(page) {
      clearInterval(this.$store.state.interval);
      this.$router.push({
        name: page,
        query: {
          id: this.personInfo.meal_id,
          type: 2,
        },
      });
    },
    toPage(page) {
      if (page) {
        clearInterval(this.$store.state.interval);
        this.$router.push({
          name: page,
        });
      } else {
        Dialog.confirm({
          title: "在线客服",
          message: "400-1000-676（08:00-22:00）",
          confirmButtonText: "拨打",
        }).then(() => {
          // 拨打电话
          window.location.href = "tel://400-1000-676";
        });
      }
    },
  },
  data() {
    return {
      personInfo: {},
      packageList: {},
      orderDetail: {},
      interval: null,
      status: 1,
      // 订单状态
      OrderList: [
        {
          title: "预约中",
          img: require("@/assets/img/team/icon.png"),
          desc: "预计将在30秒内完成预约，请稍后",
          btn: [
            {
              name: "返回首页",
              path: "ItemCheck",
              color: "#FFFFFF",
              bc: "#FF8230",
            },
            {
              name: "联系客服",
              path: "",
              color: "#FF8230",
              bc: "#FFF5EF",
            },
          ],
          status: 1,
        },
        {
          title: "预约成功",
          img: require("@/assets/img/team/icon1.png"),
          desc: "请您在2022-04-26到医院体检",
          btn: [
            {
              name: "返回首页",
              path: "ItemCheck",
              color: "#FFFFFF",
              bc: "#FF8230",
            },
            {
              name: "联系客服",
              path: "",
              color: "#FF8230",
              bc: "#FFF5EF",
            },
          ],
          status: 2,
        },
        {
          title: "预约失败",
          img: require("@/assets/img/team/icon2.png"),
          desc: "服务器或网络错误，请稍后重试",
          btn: [
            {
              name: "返回首页",
              path: "ItemCheck",
              color: "#FFFFFF",
              bc: "#FF8230",
            },
            {
              name: "联系客服",
              path: "",
              color: "#FF8230",
              bc: "#FFF5EF",
            },
          ],
          status: 3,
        },
      ],
      //当前订单状态
      List: {},
    };
  },
};
</script>

<style scoped>
.all {
  width: 100%;
  min-height: 100vh;
}
.all .img {
  width: 1.1rem;
  height: 1.1rem;
}

.all .desc .time {
  display: inline-block;
  /* width: 1.5rem; */
}
/* .OrderBtn .van-button{
  width: 5.58rem;
  height: 0.8rem;
} */
.mealBox {
  width: 6.76rem;
  height: 2.2rem;
  background: #fff;
  box-shadow: 0.1rem 0.1rem 0.14rem 0rem rgba(0, 0, 0, 0.04);
  border-radius: 0.2rem;
  padding: 0.2rem;
}
.mealBox .top .left img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.1rem;
}
.mealBox .top .right {
  height: 0.8rem;
}
.userInfo {
  width: 6.76rem;
  height: 3.4rem;
  background: #ffffff;
  box-shadow: 0rem 0.1rem 0.14rem 0rem rgba(0, 0, 0, 0.04);
  border-radius: 0.2rem;
  padding: 0.2rem;
}
.userInfo div {
  padding-bottom: 0.15rem;
}
.userInfo div:not(:first-child) > div {
  font-size: 0.24rem;
  color: #999999;
  letter-spacing: 1px;
  width: 1.5rem;
}
.userInfo div:not(:first-child) span {
  font-size: 0.24rem;
  color: #333333;
}
</style>
