<template>
  <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" fixed>
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
            class="van-badge__wrapper van-icon van-icon-ellipsis"
            style="font-size: 0.5208rem; color: #2e2e2e"
          ></i> -->
        <img style="width: 0.4rem" src="@/assets/img/user11.png" alt="" />
      </router-link>
    </template>
  </van-nav-bar>
  <div class="all pt60 bg_f8">
    <div class="reportBox bg_fff mauto brs_10 ">
      <van-form>
        <van-cell-group inset class="vanGroup">
          <div class="name flex_r posr f_ai_c f_jc_c">
            <van-field
              v-model="report.name"
              name="姓名"
              label="姓名"
              colon="true"
              placeholder="请选择体检人"
              readonly
              @click="showPicker = true"
            />
            <van-icon
              name="arrow-down"
              class="posa nameIcon"
              @click="showPicker = true"
            />
            <van-popup v-model:show="showPicker" position="bottom">
              <div
                class="pd10 flex_r f_jc_c f_ai_c bg_fff posr"
                style="z-index: 999999"
              >
                <div
                  class="selectPeople fs15 cr_333 fw600 over_h bg_fff"
                  style="z-index: 999999"
                >
                  请选择体检人
                </div>
                <div
                  class="posa"
                  style="right: 0.38rem"
                  @click="showPicker = false"
                >
                  <img
                    style="width: 0.27rem"
                    src="@/assets/img/close.png"
                    alt=""
                  />
                </div>
              </div>
              <van-picker
                :columns="columns"
                @change="toSelect"
                @confirm="onConfirm"
                :show-toolbar="false"
                @click="toOne()"
              />
            </van-popup>
          </div>
          <div class="phone">
            <van-field
              v-model="report.phone"
              name="手机号码"
              label="手机号码"
              colon="true"
              :disabled="selectActive"
            />
          </div>
          <div class="idCard">
            <van-field
              v-model="report.idCard"
              name="身份证号码"
              label="身份证号码"
              colon="true"
              :disabled="selectActive"
              :class="selectActive == true ? 'bg_ccc' : ''"
            />
          </div>
        </van-cell-group>
        <div class="reportBtn">
          <van-button
            :class="selectActive == true ? 'selectActive' : 'noActive'"
            @click="toList()"
            >查询报告</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  NavBar,
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  RadioGroup,
  Radio,
  DatetimePicker,
  Button,
  Toast,
  ActionBar,
  ActionBarButton,
  Icon,
} from "vant";
export default {
  components: {
    vanNavBar: NavBar,
    vanForm: Form,
    vanField: Field,
    vanCellGroup: CellGroup,
    vanPopup: Popup,
    vanPicker: Picker,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanDatetimePicker: DatetimePicker,
    vanButton: Button,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
  },
  //   setup() {
  //     const columns = ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华"];

  //     const onConfirm = (value, index) => {
  //       Toast(`当前值: ${value}, 当前索引: ${index}`);
  //     };
  //     const onChange = (value, index) => {
  //       Toast(`当前值: ${value}, 当前索引: ${index}`);
  //     };
  //     const onCancel = () => Toast("取消");

  //     return {
  //       columns,
  //       onChange,
  //       onCancel,
  //       onConfirm,
  //     };
  //   },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  methods: {
    toList() {
      if (this.report.name != "") {
        this.$router.push({
          name: "ReportList",
        });
      } else {
        alert("111");
      }
    },
    toSelect(value, index) {
      this.value = value;
      this.index = index;
    },
    toOne() {
      this.report.name = this.value;
      this.list.forEach((element) => {
        if (element.name == this.report.name) {
          this.report.phone = element.phone;
          this.report.idCard = element.idCard;
        }
      });
      //   var d = document.getElementsByClassName('phone')
      let dom = document.querySelectorAll(".van-field__control");
      dom[2].style.backgroundColor = "#F7F7F7";
      dom[1].style.backgroundColor = "#F7F7F7";
      dom[2].style.color = "#666666";
      dom[1].style.color = "#666666";
      this.selectActive = true;
      this.showPicker = false;
    },
  },
  data() {
    return {
      selectActive: false,
      title: "体检报告",
      report: {
        name: "",
        phone: "",
        idCard: "",
      },
      showPicker: false,
      columns: ["李迪迪", "杨阳洋", "陈华", "刘芳"],
      list: [
        { name: "李迪迪", phone: "154534355345", idCard: "31453453413435" },
        { name: "杨阳洋", phone: "154534355345", idCard: "31453453413435" },
        { name: "陈华", phone: "154534355345", idCard: "31453453413435" },
        { name: "刘芳", phone: "154534355345", idCard: "31453453413435" },
      ],
      index: "0",
      value: "李迪迪",
    };
  },
};
</script>

<style scoped>
.all {
  height: 100vh;
}
.reportBox {
  width: 6.86rem;
  height: 5.6rem;
  box-shadow: 0rem 0.06rem 0.32rem 0rem rgba(218, 218, 218, 0.5);
}
.reportBtn {
  padding-top: 0.4rem;
  width: 5.68rem;
  margin: 0 auto;
}
.reportBtn .noActive,
.reportBtn .selectActive {
  width: 5.68rem;
  height: 0.8rem;
  background: #eeeeee;
  border-radius: 0.45rem;
  border: 0.01rem solid #d7d7d7;
  margin: 0 auto;
  font-size: 0.32rem;
}
.reportBtn .noActive {
  color: #999999;
}
.reportBtn .selectActive {
  background-color: #ff8230;
  color: white;
}
.nameIcon {
  right: 1rem;
  top: 0.4rem;
  z-index: 99999;
}
</style>