import request from '@/api/axios'; // 导入http中创建的axios实例


const Examinator = {
  // 字典
  dictType(data) {
    return request({
      url: '/api/open/h5/exam/dict/data/type/' + data,
      method: 'get',
    })
  },

  //获取体检人信息列表
  getExaminatorList(params) {
    return request({
      url: '/api/h5/exam/medical/user/list',
      method: 'get',
      params,
    })
  },

    //获取单个体检人信息详情
    getExaminatorDetail(id) {
      return request({
        url: '/api/h5/exam/medical/user/detail/' + id,
        method: 'put',
      })
    },

  // 新增体检人
  addExaminator(data) {
    return request({
      url: '/api/h5/exam/medical/user/add',
      method: 'post',
      data,
    })
  },

  // 修改体检人信息
  editExaminator(data) {
    return request({
      url: '/api/h5/exam/medical/user/edit',
      method: 'put',
      data,
    })
  },

  // 删除体检人信息
  deleteExaminator(ids) {
    return request({
      url: '/api/h5/exam/medical/user/' + ids,
      method: 'delete',
    })
  }

}


export default Examinator;
