import request from '@/api/axios'; // 导入http中创建的axios实例


const itemCheck = {
    //获取团队体检人员详细信息
    getUserGroupPackageInformation(data){
        return request({
            url: '/api/open/h5/inspect/user/getUserGroupPackageInformation?seachValue='+data,
            method: 'get'
        })
    },
    //点击立即预约，根据身份证号进行修改当前人员的预约状态(前置步骤)
    updateTfdRegularlyScanOrderByIdCrad(data){
        return request({
            url: '/api/open/h5/inspect/user/updateTfdRegularlyScanOrderByIdCrad/',
            method: 'get',
            params:data
        })
    },
    //新增单位团检预约
    insertSysBallCheck(data){
        return request({
            url: '/api/open/h5/inspect/user/insertSysBallCheck',
            method: 'get',
            params:data
        })
    },
     //团检项目查询
     selectByReserveRegisterNo(data){
        return request({
            url: '/api/open/h5/inspect/user/selectByReserveRegisterNo?teamMealId='+data,
            method: 'get'
        })
    }
}


export default itemCheck;