var common = {
  //时间戳转化日期
  format(time) {
    var time = new Date(time);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    if (m < 10) m = "0" + m;
    var d = time.getDate();
    if (d < 10) d = "0" + d;
    var h = time.getHours();
    if (h < 10) h = "0" + h;
    var mm = time.getMinutes();
    if (mm < 10) mm = "0" + mm;
    var s = time.getSeconds();
    if (s < 10) s = "0" + s;
    return y + "-" + m + "-" + d + " " + h + ":" + mm + ":" + s;
  },
  //时间戳转化时间
  toHHmmss(d) {
    var time;
    var hours = parseInt(
      (d % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = parseInt((d % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = parseInt((d % (1000 * 60)) / 1000);
    time =
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds);
    return time;
  },
  // 定时器
  setTimer(cm, obj) {
    let _this = this;
    if (cm <= 1000 * 60 * 60 * 2 && cm > 0) {
      obj.interval = setInterval(function () {
        cm -= 1000;
        if (cm > 0) obj.remainTime = _this.toHHmmss(cm);
        if (cm < 0) {
          clearInterval(obj.interval);
          //超时订单状态改成取消
          obj.orderState = 4;
        }
      }, 1000);
    }
  }
}
export default common
