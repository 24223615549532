<template>
    <div class="all pt50 bg_fff">
        <div>
            <!-- <div class="fs20 cr_000 tc">衡阳市中心医院</div> -->
            <div class="tc cr_333 pd10 fs14 pb25 fw600 t_ls1">单位体检预约入口</div>
            <!-- <van-cell-group> -->
            <div class="posr select_box">
                <div class="selectIn flex_r f_ai_c">
                    <div class="left flex_r f_ai_c pl13" @click="ActiveTime = true">
                        <div class="pr14">{{ type }}</div>
                        <van-icon name="arrow-down" class="pr8" />
                    </div>
                    <div class="right pl8">
                        <input type="tel" style="width: 4rem" v-model="idCard" placeholder="请输入您的身份证/手机号" @update:model-value="btnColor" />
                    </div>
                    <!-- <div class="selectOn">
            <ul
              class="tc bg_fff br_1_666"
              style="z-index: 999"
              v-show="ActiveTime"
            >
              <li
                v-for="(item, index) in option1"
                :key="index"
                :value="item.value"
                @click="toTime(item)"
              >
                {{ item.text }}
              </li>
            </ul>
          </div> -->
                    <van-popup v-model:show="ActiveTime" position="bottom" :style="{ height: '2rem', borderRadius: '0.2rem 0.2rem 0rem 0rem' }">
                        <ul class="tc">
                            <li v-for="(item, index) in option1" :key="index" :value="item.value" @click="toTime(item)">
                                {{ item.text }}
                            </li>
                        </ul>
                    </van-popup>
                </div>
            </div>
            <!-- </van-cell-group> -->
            <div class="w100 flex_r f_jc_c pt20 searchBtn">
                <van-button class="cr_fff fs16 fw600" :class="orgBtn ? 'orgBtn' : 'Btn'" @click="findCheck">查询体检套餐
                </van-button>
            </div>
            <!-- <van-button class="bg_org cr_fff fs16 fw600" @click="itemCheck"
        >团检待预约
      </van-button>
      <van-button class="bg_org cr_fff fs16 fw600" @click="createdItem"
        >单位团检</van-button
      > -->
        </div>
        <div class="bottom flex_r f_jc_b">
            <div class="left flex_c f_ai_c" @click="toPath(1)">
                <img src="@/assets/img/team/team2.png" alt="" />
                <div class="bottom_name flex_c f_ai_c">
                    <div class="cr_333 fs16 fw600 t_ls1 name">团检代预约</div>
                    <div class="one"></div>
                </div>
                <div class="cr_666 fs11 pt10">找他人预约单位体检</div>
            </div>
            <div class="left flex_c f_ai_c" @click="toPath(2)">
                <img src="@/assets/img/team/team1.png" alt="" />
                <div class="bottom_name flex_c f_ai_c">
                    <div class="cr_333 fs16 fw600 t_ls1 name">单位团检</div>
                    <div class="two"></div>
                </div>
                <div class="cr_666 fs11 pt10">个性化团检预约</div>
            </div>
        </div>
        <van-tabbar>
            <van-tabbar-item>
                <router-link to="/">
                    <div><img :src="icon.home" style="height:0.5rem" /></div>
                    <div class="mt2 mb2 mauto fs12 fw600 cr_999 lh14">个检</div>
                </router-link>

            </van-tabbar-item>
            <van-tabbar-item>
                <router-link to="/ItemCheck">
                    <div><img :src="icon.ItemCheck" /></div>
                    <div class="mt2 mb2 mauto fs12 fw600 cr_f38 lh14">团检</div>
                </router-link>
            </van-tabbar-item>
            <van-tabbar-item>
                <router-link to="/user">
                    <div><img :src="icon.inactive" /></div>
                    <div class="mt2 mb2 mauto fs12 fw600 cr_999 lh14">我的</div>
                </router-link>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import {
    NavBar,
    Form,
    Field,
    CellGroup,
    Popup,
    Picker,
    RadioGroup,
    Radio,
    DatetimePicker,
    Button,
    Toast,
    ActionBar,
    ActionBarButton,
    Icon,
    DropdownMenu,
    DropdownItem,
    Overlay,
    Tabbar,
    TabbarItem,
} from "vant";
import api from "@/api/api/Index";
export default {
    methods: {
        btnColor(value) {
            if (this.type == "身份证") {
                if (this.reg.test(value)) {
                    this.orgBtn = true;
                } else {
                    this.orgBtn = false;
                }
            } else {
                if (this.iphone.test(value)) {
                    this.orgBtn = true;
                } else {
                    this.orgBtn = false;
                }
            }
        },
        itemCheck() {
            this.$router.push("/OtherCheck");
        },
        findCheck() {
            if (this.type == "身份证") {
                if (!this.reg.test(this.idCard)) {
                    Toast("请输入正确的身份证号码");
                    return false;
                }
            } else {
                if (!this.iphone.test(this.idCard)) {
                    Toast("请输入正确的手机号码");
                    return false;
                }
            }
            api.itemCheck
                .getUserGroupPackageInformation(this.idCard)
                .then((res) => {
                    if (res.status == 0 || res.status == 3) {
                        this.$router.push("/itemDetail?data=" + this.idCard);
                    } else {
                        this.$router.push("/itemStatus?data=" + this.idCard);
                    }
                });
            this.$store.state.team = "y";
        },
        createdItem() {
            this.$router.push("/CreatedCheck");
        },
        toTime(item) {
            this.type = item.text;
            this.ActiveTime = false;
            this.orgBtn = false;
            if (this.type == "身份证") {
                if (this.reg.test(this.idCard)) {
                    this.orgBtn = true;
                }
            } else {
                if (this.iphone.test(this.idCard)) {
                    this.orgBtn = true;
                }
            }
        },
        toPath(value) {
            if (value == 1) {
                this.$router.push("/othercheck");
            } else if (value == 2) {
                this.$router.push("/CreatedCheck");
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        document.title = "衡阳市中心医院";
        next();
    },
    data() {
        return {
            orgBtn: false,
            show: false,
            ActiveTime: false,
            type: "身份证",
            idCard: "",
            option1: [
                { text: "身份证", value: 0 },
                { text: "手机号码", value: 1 },
                // { text: "护照", value: 2 },
            ],
            reg: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            iphone: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            icon: {
                home: require("@/assets/img/home/nohome.png"),
                ItemCheck: require("@/assets/img/home/Item.png"),
                inactive: require("@/assets/img/home/user.png"),
            },
        };
    },
    setup() {
        const onClickLeft = () => history.back();
        return {
            onClickLeft,
        };
    },
    components: {
        vanNavBar: NavBar,
        vanForm: Form,
        vanField: Field,
        vanCellGroup: CellGroup,
        vanPopup: Popup,
        vanPicker: Picker,
        vanRadioGroup: RadioGroup,
        vanRadio: Radio,
        vanDatetimePicker: DatetimePicker,
        vanButton: Button,
        vanActionBar: ActionBar,
        vanActionBarButton: ActionBarButton,
        vanDropdownMenu: DropdownMenu,
        vanDropdownItem: DropdownItem,
        vanOverlay: Overlay,
        vanTabbar: Tabbar,
        vanTabbarItem: TabbarItem,
    },
};
</script>

<style scoped>
.van-tabbar-item {
    width: 1em;
    display: flex;
    flex-direction: column;
}
.van-tabbar-item img {
    width: 0.4rem;
    height: 0.4rem;
    margin: 0 auto;
}
.all {
    height: 100vh;
}
.downBtn {
    width: 6.86rem;
    bottom: 0;
    height: 1.1rem;
}
.downBtn .van-button {
    width: 6.86rem;
    height: 0.8rem;
    border-radius: 0.4rem;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.block {
    width: 6.22rem;
    height: 5rem;
    background: #ffffff;
    border-radius: 0.2rem;
}
.van-overlay {
    background: rgba(0, 0, 0, 0.2);
}
.block .close {
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
}
.selectIn {
    width: 6.88rem;
    height: 1rem;
    border-radius: 0.5rem;
    background: #f4f4f4;
    margin: 0 auto;
    font-size: 0.28rem;
}
.selectIn .left {
    width: 2.3rem;
    height: 0.6rem;
    border-right: 1px solid #dddddd;
}
.right input {
    height: 1rem;
    background: #f4f4f4;
}
.selectOn ul {
    width: 2rem;
    margin-left: 0.32rem;
    top: 1rem;
    left: 0;
    position: absolute;
}
ul li:not(:last-child) {
    border-bottom: 0.01rem solid #dddddd;
    width: 6.89rem;
    margin: 0 auto;
}
.selectOn ul li:hover {
    background: #777;
}
.searchBtn .van-button {
    width: 6.88rem;
    height: 1rem;
    border-radius: 0.5rem;
    border: 0.01rem solid #e9e9e9;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.45rem;
    letter-spacing: 1px;
}
.Btn {
    background: #f8f8f8;
    color: #666666;
}
.orgBtn {
    background: #ff8230;
    color: #ffffff;
}
.bottom {
    margin: 0.8rem 0.32rem;
}
.bottom > div {
    width: 3.34rem;
    height: 3.28rem;
    border-radius: 0.2rem;
}
.bottom > div:nth-child(1) {
    background: #d6fffd;
    box-shadow: 0rem 0.12rem 0.2rem 0rem rgba(65, 173, 171, 0.14);
}
.bottom > div:nth-child(2) {
    background: #f5eaff;
    box-shadow: 0rem 0.12rem 0.2rem 0rem rgba(171, 124, 214, 0.2);
}
.bottom div:nth-child(1) img {
    width: 1.17rem;
    height: 0.8rem;
    margin-top: 0.7rem;
    margin-bottom: 0.26rem;
}
.bottom div:nth-child(2) img {
    width: 1rem;
    height: 0.8rem;
    margin-top: 0.7rem;
    margin-bottom: 0.26rem;
}
ul li {
    height: 1rem;
    line-height: 1rem;
    font-weight: 600;
    color: #333333;
    font-size: 0.28rem;
}

.two {
    width: 1rem;
    height: 0.1rem;
    background: #9f64ff;
    box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(190, 143, 234, 0.6);
    border-radius: 0.06rem;
    position: absolute;
    top: 0.38rem;
    z-index: 2;
}
.one {
    width: 1rem;
    height: 0.1rem;
    background: #2ddabc;
    box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(45, 219, 188, 0.5);
    border-radius: 0.06rem;
    position: absolute;
    top: 0.38rem;
    z-index: 2;
}
.bottom_name {
    width: 1.88rem;
    height: 0.5rem;
    position: relative;
}
.bottom_name .name {
    position: absolute;
    top: 0rem;
}
</style>