import { createStore } from 'vuex'

export default createStore({
  state: {
    token: localStorage.getItem("token"),
    Examinator: null,
    path: '',
    team: '',
    interval: ''
  },
  mutations: {
    setToken(state, str) {
      state.token = str;
    },
    setPath(state, path) {
      state.path = path;
    },
    setTeam(state, team) {
      state.team = team;
    },
    setInterval(state, interval) {
      state.team = interval;
    }
  },
  actions: {

  },
  modules: {
  }
})
