<template>
  <div class="bg_white">
    <div id="form1" style="height: 100%">
      <van-nav-bar title="登录" left-arrow @click-left="onClickLeft" fixed>
        <template #left>
          <i
            class="van-badge__wrapper van-icon van-icon-cross"
            style="font-size: 0.4583rem; color: #2e2e2e"
          ></i>
        </template>
        <template #right>
          <router-link to="/">
            <img style="width: 0.4rem" src="@/assets/img/user11.png" alt="" />
          </router-link>
        </template>
      </van-nav-bar>
      <div id="login_page" style="height: 100%">
        <div class="Title fs14 tc cr_ff8 lh20 fw600 f_ls_1 pt100">
          衡阳市中心医院健康管理（体检）中心
        </div>
        <div class="login_top fs12 tc lh20 cr_ff8 f_ls_1 flex_r f_ai_c f_jc_c">
          <div class="left"></div>
          <div>您身边的体检专家</div>
          <div class="right"></div>
          <!-- <div class="logo">
                        <router-link to="/">
                            <img src="https://img.kktijian.com:8443/gr/img/OldImages/JiGou/20210301/YY201509141935330001/bab7b698553f49fc9baf4e8cf2b8982a.jpg">
                        </router-link>    
                    </div> -->
        </div>
        <div class="login_form">
          <div class="item">
            <div class="left">
              <img src="@/assets/img/icon/phone.png" />
              <input
                type="tel"
                id="phone"
                name="phone"
                v-model="phone"
                placeholder="请输入手机号码"
              />
            </div>
          </div>
          <van-popup
            v-model:show="verifyShow"
            class="pt10 brs_10"
            style="width: 6.22rem; height: 4.18rem;padding-top:0.5rem "
          >
            <div class="tc fs15 fw600 cr_333 t_ls1 pb20">填写验证信息</div>
            <div class="flex_r pt10 ml20">
              <div class="brs_20" style="width: 2.8rem; height: 0.88rem">
                <canvas
                  class="brs_8"
                  width="160"
                  height="42"
                  id="matchesCanvas"
                  @click="refersh()"
                ></canvas>
              </div>

              <input
                class="checkInput ml28"
                type="tel"
                id="verifyCode"
                name="verifyCode"
                placeholder="请输入计算结果"
                @update:model-value="btnColor"
                v-model="verifyCode"
              />
            </div>
            <van-button
              type="success"
              class="btnSure"
              @click="verifyCodeCheck()"
              >确定</van-button
            >
          </van-popup>

          <div class="item">
            <div class="left">
              <img src="@/assets/img/icon/psd.png" />
              <input
                type="text"
                id="code"
                name="code"
                placeholder="请输入验证码"
                v-model="code"
              />
            </div>
            <div class="right">
              <div class="get_yzm_box">
                <span class="can_click" @click="getLoginCaptcha()">{{
                  Captcha
                }}</span>
              </div>
            </div>
          </div>
          <div class="login_sub_box">
            <button
              class="loginBtn bg_org brs_20 fs16 fw600 cr_fff lh22 f_ls_1"
              @click="loginByCaptcha()"
            >
              登录
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";
import api from "@/api/api/Index";
import { Toast, Popup, Tabbar, TabbarItem, NavBar, Button } from "vant";
import { useStore } from "vuex";
import "font-awesome/css/font-awesome.min.css";
import dragVerify from "vue-drag-verify/src";
export default {
  components: {
    vanPopup: Popup,
    vanTabbar: Tabbar,
    vanTabbarItem: TabbarItem,
    vanNavBar: NavBar,
    vanButton: Button,
    dragVerify,
  },
  setup() {
    const active = ref(1);
    const store = new useStore();
    return {
      active,
      store,
    };
  },
  created() {},
  mounted() {},
  methods: {
    btnColor(value) {
      if (value != "") {
        document.querySelector(".btnSure").style.backgroundColor = "#FF8230";
      } else {
        document.querySelector(".btnSure").style.backgroundColor = "#d8d8d8";
      }
    },
    onClickLeft() {
      this.$router.push({ path: "/" });
    },
    verifyCodeCheck() {
      var _this = this;
      if (this.verifyCode == this.mathesResult) {
        _this.verify = true;
        _this.verifyShow = false;
        api.Login.getLoginCaptcha({ telephone: _this.phone }).then((res) => {
          _this.timer = setInterval(function () {
            _this.Captcha = _this.count + "s";
            _this.count--;
            if (_this.count <= 0) {
              clearInterval(_this.timer);
              _this.Captcha = "重新获取验证码";
              _this.count = 60;
            }
          }, 1000);
        });
      } else {
        Toast("验证失败");
      }
    },
    refersh() {
      this.getCodeImg();
    },
    getCodeImg() {
      var _this = this;
      var matchesCanvas = document.getElementById("matchesCanvas");
      var cvas = matchesCanvas.getContext("2d");
      //获取计算题
      var mathList = [_this.mulMath(), _this.additionMath(), _this.subMath()];
      var i = _this.randNumber(0, mathList.length);
      var maths = mathList[i];

      var width = matchesCanvas.width;
      var height = matchesCanvas.height;
      //先绘制背景色
      cvas.fillStyle = "#CDC8B1";
      cvas.fillRect(0, 0, width, height);

      //再绘制计算题
      cvas.fillStyle = "#7F7F7F";
      var fontSize = 26;
      cvas.font = fontSize + "px Arial";
      cvas.textBaseline = "middle";
      cvas.fillText(maths[0], 10, 20);
      cvas.fillText(maths[2], 50, 20);
      cvas.fillText(maths[1], 80, 20);
      cvas.fillText("=?", 120, 20);
      if (i == 0) {
        var result = parseInt(maths[0]) * parseInt(maths[1]);
      } else if (i == 1) {
        var result = parseInt(maths[0]) + parseInt(maths[1]);
      } else {
        var result = parseInt(maths[0]) - parseInt(maths[1]);
      }
      //给出计算结果
      this.mathesResult = result;
      _this.cvasInterfere(cvas, width, height);
    },
    passcallback() {},
    handleFail() {
      if (this.value) {
        setTimeout(() => {
          this.$refs.sss.reset();
        }, 500);
      }
    },
    getLoginCaptcha() {
      var phone = this.phone;
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(phone)) {
        Toast("请填写正确的手机号码");
      } else {
        if (this.count == 60) {
          if (this.verify) {
            var _this = this;
            api.Login.getLoginCaptcha({ telephone: phone }).then((res) => {
              _this.timer = setInterval(function () {
                _this.Captcha = _this.count + "s";
                _this.count--;
                if (_this.count <= 0) {
                  clearInterval(_this.timer);
                  _this.Captcha = "重新获取验证码";
                  _this.count = 60;
                }
              }, 1000);
            });
          } else {
            var _this = this;
            this.verifyShow = true;
            setTimeout(function () {
              _this.getCodeImg();
            }, 100);
          }
        }
      }
    },
    loginByCaptcha() {
      var phone = this.phone;
      var _this = this;
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(phone)) {
        Toast("请填写正确的手机号码");
      } else if (!this.verify) {
        Toast("请先获取验证码");
      } else if (!this.code) {
        Toast("请输入验证码");
      } else {
        api.Login.loginByCaptcha({
          captcha: this.code,
          telephone: this.phone,
        }).then((res) => {
          _this.store.state.token = res.token;
          localStorage.setItem("token", res.token);
          var url = _this.$route.query.redirect;
          var theRequest = new Object();
          if (url.indexOf("?") != -1) {
            var str = url.substr(url.indexOf("?") + 1, url.length);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
              theRequest[strs[i].split("=")[0]] = unescape(
                strs[i].split("=")[1]
              );
            }
          }

          _this.$router.replace({
            path: _this.$route.query.redirect,
            query: theRequest,
          });
        });
      }
    },
    randNumber(min, max) {
      //随机数生成方法
      var res = parseInt(Math.random() * (max - min) + min);
      return res;
    },
    randColor(min, max) {
      var r = this.randNumber(min, max);
      var g = this.randNumber(min, max);
      var b = this.randNumber(min, max);
      var colorRes = "rgb(" + r + "," + g + "," + b + ")";
      return colorRes;
    },
    mulMath() {
      //乘法
      var first = this.randNumber(1, 9);
      var second = this.randNumber(1, 9);
      return [first, second, "*"];
    },
    additionMath() {
      //加法
      var first = this.randNumber(1, 9);
      var second = this.randNumber(1, 9);
      return [first, second, "+"];
    },
    subMath() {
      //减法
      var first = this.randNumber(5, 9);
      var second = this.randNumber(1, 5);
      return [first, second, "-"];
    },
    cvasInterfere(cvas, width, height) {
      //干扰线
      for (var i = 0; i < 8; i++) {
        cvas.beginPath();
        cvas.moveTo(this.randNumber(0, width), this.randNumber(0, height));
        cvas.lineTo(this.randNumber(0, width), this.randNumber(0, height));
        cvas.strokeStyle = this.randColor(180, 220);
        cvas.closePath();
        cvas.stroke();
      }
      //干扰噪点
      for (var i = 0; i < 80; i++) {
        cvas.beginPath();
        cvas.arc(
          this.randNumber(0, width),
          this.randNumber(0, height),
          1,
          0,
          2 * Math.PI
        );
        cvas.closePath();
        cvas.fillStyle = this.randColor(150, 250);
        cvas.fill();
      }
    },
  },
  data() {
    return {
      phone: "",
      Captcha: "获取验证码",
      code: "",
      timer: null,
      count: 60,
      verifyShow: false,
      verify: false,
      verifyCode: null,
      mathesResult: null,
      verifyAttr: {
        handlerIcon: "fa fa-angle-double-right",
        successIcon: "fa fa-check",
        background: "rgb(238, 238, 238)",
        progressBarBg: "#ff8230",
        completedBg: "#66cc66",
        handlerBg: "#fff",
        textSize: "0.375rem",
      },
    };
  },
};
</script>

<style scoped>
#login_page {
  background-image: url("../../assets/img/login.png");
  background-size: 100% 4.62rem;
  background-repeat: no-repeat;
  width: 100%;
}

.login_top .left,
.login_top .right {
  width: 1rem;
  height: 0.04rem;
  background: linear-gradient(to right, rgba(240, 202, 145, 0.5), #ff8230);
}
.van-nav-bar {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
}
.item {
  width: 6.22rem;
}
#login_page .login_form {
  width: 100%;
  padding-right: 0;
  padding-left: 0.64rem;
  padding-top: 1rem;
}
.loginBtn {
  width: 6.22rem;
  height: 0.9rem;
  border: none;
  box-shadow: 0rem 0.06rem 0.24rem 0rem #ffc5a2;
}
.btnSure {
  width: 5.42rem;
  height: 0.9rem;
  background: #d8d8d8;
  border-radius: 0.14rem;
  border: none;
  margin-left: 0.4rem;
  margin-top: 0.3rem;
  font-size: 0.32rem;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 0.39rem;
}
.checkInput {
  width: 2rem !important;
  height: 0.88rem;
  border: 0.01rem solid #eeeeee;
  border-radius: 0.14rem;
  margin-right: 0.2rem;
}
#login_page .login_form .checkInput{
  font-size: 0.24rem;
  padding-left: 0.2rem;
}
</style>
