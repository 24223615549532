import request from '@/api/axios'; // 导入http中创建的axios实例


const OrderList = {
    //体检预约下单
    OrderList(data){
        return request({
            url: '/api/h5/exam/order/list',
            method: 'get',
            params:data
        })
    },
    // 修改代付款到待预约或待体检标识
    updateIdentification(data){
        return request({
            url: '/api/h5/exam/order/updateIdentification',
            method: 'post',
            data
        })
    },
     //删除体检订单
     deleteOrder(id){
        return request({
            url: '/api/h5/exam/order/'+id,
            method: 'DELETE'
        })
    },
     //取消体检订单
     cancelOrder(data){
        return request({
            url: '/api/h5/exam/order/cancelOrder',
            method: 'post',
            data
        })
    },
    //订单退款
    refund(data){
       return request({
           url: '/Nativepay/refund.php',
           requestBase:'baseUrl2',
           method: 'get',
           params:data
       })
   },
   //查询退款状态
  getRefundStatus(orderSn){
    return request({
        url: '/api/h5/exam/order/getRefundStatus/'+orderSn,
        method: 'get'
    })
  }
}


export default OrderList;