<template>
    <div class="all pt24 pb48 over_h">
        <!-- 头部 -->
        <!-- <van-nav-bar title="衡阳市中心医院健康管理（体检）" left-arrow fixed>
      <template #left> </template>
      <template #right>
        <router-link to="/User">
          <i
            class="van-badge__wrapper van-icon van-icon-ellipsis fs25"
            style="color: #2e2e2e"
          ></i>
        </router-link>
      </template>
    </van-nav-bar> -->
        <!-- 体检报告我的报告 -->
        <div class="banner">
            <img src="@/assets/img/banner.png" alt="" />
        </div>
        <!-- <div class="title_box mr15 ml15 flex_r f_jc_b">
      <div
        class="little_box brs_10 flex_r f_ai_c f_jc_b pr13 bl13"
        :class="{ active: item.status }"
        v-for="(item, index) in titleBox"
        :key="index"
      >
        <div class="mauto">
          <div class="fs17 t_ls1 cr_fff">{{ item.name }}</div>
          <div class="fs12 cr_fff t_ls1 fw400">{{ item.label }}</div>
        </div>
        <div class="left">
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div> -->
        <!-- 本周热销 -->
        <div class="hot_box mt24 mauto pt15 pr12 pb12 pl12 brs_10">
            <div class="up_bx flex_r f_jc_b mb8 f_ai_b">
                <div class="flex_r">
                    <div class="fs18 fw600 cr_000 t_ls1">热销套餐</div>
                    <div class="two pd5 brs_30 tc fs12 cr_fff lh7 ml8 mt5 fw400">
                        医院自营
                    </div>
                </div>
                <div class="cr_f38 fs12">{{ hotList.orderCount }}人已下单</div>
            </div>
            <router-link :to="{ path: 'projectDetail', query: { id: hotList.mealId } }">
                <div class="hot_content bg_white flex_r f_jc_b pd5 brs_10">
                    <div class="ml10">
                        <div class="fs18 fw600 cr_000 t_ls1 pt5">{{ hotList.mealName }}</div>
                        <div class="flex_r f_ai_s mt5">
                            <div class="intro_desc cr_666 fw600 t_ls1 van-multi-ellipsis--l2">
                                <span class="
                    type
                  
                    font_type
                  ">
                                    {{ hotList.examType }} </span>{{ hotList.introduction }}
                            </div>
                        </div>
                        <div class="cr_f86 fw600 fs16 pt10">￥{{ hotList.price }}</div>
                    </div>
                    <div>
                        <img class="right mt1 brs_5" :src="hotList.imgUrl" alt="" />
                    </div>
                </div>
            </router-link>
        </div>
        <div class="mt24">
            <div class="flex_r f_ai_c f_jc_b ml15 mr15">
                <div class="right">
                    <div class="fs17 fw600 cr_000 t_ls1">体检套餐</div>
                </div>
                <div class="left flex_r f_ai_c">
                    <router-link :to="{ name: 'ProjectList', query: { type: type } }">
                        <span class="cr_999 f_ls_1 fs14 tr">更多</span>
                    </router-link>
                    <img class="ml4" src="@/assets/img/home/more.png" alt="" />
                </div>
            </div>
            <!-- 体检套餐类型 -->
            <TcType :typeList="typeList" @typeSelect="typeSelect"></TcType>
        </div>

        <!-- 体检套餐列表 -->
        <div style="margin-top: 0.36rem">
            <TcList :isHot="false" :packageList="packageList[type]" :finished="true" :isShow="true" />
            <router-link :to="{ name: 'ProjectList', query: { type: type } }">
                <div class="seeMore tc fs15 cr_999 lh20 f_ls_1 mt17 mb28">
                    查看更多
                    <van-icon name="arrow" />
                </div>
            </router-link>
        </div>
        <div class="flex_r mr15 ml15 f_jc_b">
            <div class="intro_item bg_f8 brs_10 tc" v-for="(item, index) in introList" :key="index">
                <router-link :to="item.page">
                    <img class="w30 mt14 mauto" :src="item.img" alt="" />
                    <div class="fs18 cr_444 f_ls_1 mt12 fw600">{{ item.name }}</div>
                    <div class="mt4 mb4 cr_888 f_ls_1">{{ item.desc }}</div>
                    <div>
                        <div class="cr_fda fw600 fs16 mb14 f_ls_1">
                            去看看<span>
                                <van-icon name="arrow" />
                            </span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <!-- 保障详情 -->
        <div class="mg15 bg_5ef brs_10 pd11 last">
            <div class="flex_r f_jc_b mb10 f_ai_c" @click="show = true">
                <span class="fs17 fw600 cr_000">保障</span>
                <div>
                    <span class="cr_999 fs14 f_ls_1">详情<span>
                            <van-icon name="arrow" />
                        </span></span>
                </div>
            </div>
            <div class="last_bottom">
                <ul class="flex_r f_jc_b">
                    <li class="flex_r bg_fef pd10 f_ai_c brs_5" v-for="(item, index) in lastList" :key="index">
                        <img class="mr5" :src="item.img" alt="" />
                        <span>{{ item.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <van-tabbar>
            <van-tabbar-item>
                <router-link to="/">
                    <div><img :src="icon.home" style="height:0.5rem"/></div>
                    <div class="mt2 mb2 mauto fs12 fw600 cr_f38 lh14">个检</div>
                </router-link>

            </van-tabbar-item>
            <van-tabbar-item>
                <router-link to="/ItemCheck">
                    <div><img :src="icon.ItemCheck" /></div>
                    <div class="mt2 mb2 mauto fs12 fw600 cr_999 lh14">团检</div>
                </router-link>
            </van-tabbar-item>
            <van-tabbar-item>
                <router-link to="/user">
                    <div><img :src="icon.inactive" /></div>
                    <div class="mt2 mb2 mauto fs12 fw600 cr_999 lh14">我的</div>
                </router-link>
            </van-tabbar-item>
        </van-tabbar>
        <!-- 保障详情弹框 -->
        <van-popup v-model:show="show" teleport="body" position="bottom">
            <div class="popupUp flex_r f_ai_c f_jc_b pt14 pb14 mr15 ml15 br_b_1_grey">
                <div class="popupTitle fs18 cr_000 fw600 f_ls_1">保障详情</div>
                <div @click="show = false">
                    <img src="@/assets/img/close.png" alt="" />
                </div>
            </div>
            <div class="buju flex_r mg16 f_jc_b">
                <div class="left bg_7f3 brs_10 pt11 pb11 pr14 pl14">
                    <div class="flex_r f_ai_c">
                        <img src="@/assets/img/home/l2.png" alt="" />
                        <div class="fs14 cr_000 fw600 mf8 f_ls_1 ml10">免费改期</div>
                    </div>
                    <div class="fs12 cr_444 mt5">
                        每位客户拥有2次通过网站直接改期的机会。如因特殊情况需再次改期，只需联系康康体检网客服审核，确认原因后即可改期。改期不收任何服务费。
                    </div>
                </div>
                <div class="right mt3">
                    <div class="list brs_10 pt11 pb11 mb8 pl11 pr11" v-for="(item, index) in popupList" :key="index">
                        <div class="up_bbox flex_r f_ai_c">
                            <img :src="item.img" alt="" />
                            <div class="name fs14 cr_000 fw600 ml8 f_ls_1">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="content fs12 cr_444 mt5">{{ item.content }}</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { ref } from "vue";
import api from "@/api/api/Index";
import {
    Tabbar,
    TabbarItem,
    Swipe,
    SwipeItem,
    Popup,
    Tab,
    Tabs,
    NavBar,
} from "vant";
import TcList from "@/components/content/MealList.vue";
import TcType from "@/components/content/MealType.vue";
export default {
    components: {
        vanTabbar: Tabbar,
        vanTabbarItem: TabbarItem,
        vanSwipe: Swipe,
        vanSwipeItem: SwipeItem,
        vanPopup: Popup,
        vanTab: Tab,
        vanTabs: Tabs,
        TcType,
        TcList,
        vanNavBar: NavBar,
    },
    setup() {
        const active = ref(0);
        return { active };
    },
    created() {
        var _this = this;
        //拿到体检类型
        api.Home.meal_type().then((res) => {
            _this.typeList = res;
            _this.typeList.forEach((item, index) => {
                item.checked = false;
            });
            _this.typeList.unshift({
                dictLabel: "全部",
                dictValue: 0,
                checked: true,
            });
        });
        //拿到热门套餐
        api.Home.hotDate().then((res) => {
            if (res[0]) {
                this.hotList = res[0];
            }
        }),
            //得到examtype类型
            api.Examinator.dictType("exam_type")
                .then((res) => {
                    _this.examType = res;
                })
                .then(() => {
                    //拿到套餐列表
                    api.Home.listbytype({
                        mealtype: "",
                        pageNum: 1,
                        pageSize: 2,
                    }).then((res) => {
                        this.examType.forEach((element) => {
                            res.rows.forEach((element1) => {
                                if (element.dictValue == element1.examType) {
                                    element1.examType = element.dictLabel;
                                }
                            });
                        });
                        _this.packageList[0] = res.rows;
                    });
                });
    },
    methods: {
        //获取地址信息
        getAddress(point) {
            var gc = new BMap.Geocoder();
            var map = new BMap.Map("allmap");
            var _this = this;
            gc.getLocation(point, function (rs) {
                var pointA = new BMap.Point(rs.point.lng, rs.point.lat); // 当前位置坐标
                var pointB = new BMap.Point(112.5695747646, 26.9043737742); // 目标地点坐标
                map.getDistance(pointA, pointB); //两点间的距离
                _this.distance = Number(
                    map.getDistance(pointA, pointB) / 1000
                ).toFixed(2);
            });
        },
        toPage(page, val) {
            this.$router.push({
                name: page,
                query: {
                    id: val.mealId,
                },
            });
        },
        // 点击分类
        typeSelect(dictValue) {
            var _this = this;
            if (!this.packageList[dictValue]) {
                api.Home.listbytype({
                    mealtype: dictValue,
                    pageNum: 1,
                    pageSize: 2,
                }).then((res) => {
                    this.examType.forEach((element) => {
                        res.rows.forEach((element1) => {
                            if (element.dictValue == element1.examType) {
                                element1.examType = element.dictLabel;
                            }
                        });
                    });
                    _this.packageList[dictValue] = res.rows;
                    _this.type = dictValue;
                });
            } else {
                _this.type = dictValue;
            }
        },
    },
    data() {
        return {
            // examType
            examType: [],
            icon: {
                home: require("@/assets/img/home/home.png"),
                ItemCheck:require("@/assets/img/home/ItemCheck.png"),
                inactive: require("@/assets/img/home/user.png"),
            },
            typeList: [], //类型
            type: 0,
            packageList: [], //套餐列表
            show: false,
            //热门套餐信息
            hotList: {
                orderCount: "",
                mealId: "",
                mealName: "",
                examType: "",
                introduction: "",
                price: "",
                imgUrl: "",
            },
            titleBox: [
                {
                    name: "体检报告",
                    label: "线上预约",
                    img: require("@/assets/img/home/tijian.png"),
                    status: false,
                },
                {
                    name: "我的报告",
                    label: "快速查看",
                    img: require("@/assets/img/home/baogao.png"),
                    status: true,
                },
            ],
            introList: [
                {
                    img: require("@/assets/img/home/jigou.png"),
                    name: "机构介绍",
                    desc: "三甲甲等综合性医院",
                    page: "/introduction",
                },
                {
                    img: require("@/assets/img/home/shu.png"),
                    name: "预定须知",
                    desc: "体检常见问题解答",
                    page: "/inform",
                },
            ],
            lastList: [
                { img: require("@/assets/img/home/l1.png"), name: "官方渠道" },
                { img: require("@/assets/img/home/l2.png"), name: "免费改期" },
                { img: require("@/assets/img/home/l3.png"), name: "未约可退" },
            ],
            popupList: [
                {
                    img: require("@/assets/img/home/l1.png"),
                    name: "官方渠道",
                    content: "医院自有平台，官方线上渠道，可享受极速预约通道。",
                },
                {
                    img: require("@/assets/img/home/l3.png"),
                    name: "未约可退",
                    content: "用户支付成功但未预约体检时间，可以全额退款。",
                },
            ],
        };
    },
};
</script>

<style scoped>
.intro_desc {
    width: 4rem;
}
.type {
    height: 0.4rem;
    width: 0.7rem;
    color: #0cb7b2;
    border: 0.01rem solid #0cb7b2;
    padding: 0rem 0.1rem 0 0.1rem;
    border-radius: 0.1rem;
    margin-right: 0.16rem;
    font-size: 0.2rem;
}
.all {
    background: var(--themeColor);
}
.title_box .active {
    background: var(--littleBoxTwo);
}
.little_box {
    width: 3.34rem;
    height: 1.62rem;
    background: var(--littleBoxOne);
}
.little_box .left img {
    width: 1rem;
    height: 1rem;
}
.hot_box {
    background-color: var(--hotBox);
    width: 6.86rem;
    height: 3.22rem;
}
.hot_box .up_bx {
    height: 0.5rem;
}
.hot_box .up_bx .left {
    display: flex;
    flex-wrap: nowrap;
}

.hot_box .two {
    background: var(--hotBoxType);
    height: 0.34rem;
}

.hot_content {
    width: 6.38rem;
    height: 2rem;
}
.hot_content .right {
    width: 1.78rem;
    height: 1.78rem;
}

.intro_item {
    width: 3.34rem;
    height: 3.28rem;
}
.intro_item img {
    width: 0.8rem;
    height: 0.8rem;
}
.last {
    height: 2.12rem;
}

.last .last_bottom ul li {
    width: 2rem;
    height: 1rem;
}
.last .last_bottom ul li img {
    width: 0.34rem;
    height: 0.36rem;
}
.left img {
    width: 0.12rem;
}
.popupUp {
    /* margin: auto 0.32rem; */
    border-bottom: 0.01rem solid #ecebeb;
}
.popupUp img {
    width: 0.27rem;
}
.van-popup .left img,
.van-popup .right img {
    width: 0.36rem;
    height: 0.36rem;
}
.van-popup .left {
    width: 3.34rem;
    background-color: #fdf7f3;
}
.van-popup .right {
    margin-top: 0.05rem;
}
.van-popup .buju .list {
    width: 3.34rem;
    background-color: #fdf7f3;
    min-height: 1.78rem;
    min-width: 3.34rem;
}

.all .van-nav-bar__title {
    font-size: 0.3rem;
    font-weight: 600;
    color: #000000;
    line-height: 0.42rem;
    letter-spacing: 0.01rem;
}
.all .van-nav-bar {
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
}
.van-tabbar-item {
    width: 1em;
    display: flex;
    flex-direction: column;
}
.van-tabbar-item img {
    width: 0.4rem;
    height: 0.4rem;
    margin: 0 auto;
}
.buju .left {
    width: 6rem;
    margin-right: 0.2rem;
}
.banner,
.banner img {
    width: 6.86rem;
    height: 2.4rem;
    margin: 0rem auto 0.4rem auto;
}
</style>
