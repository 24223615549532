<template>
  <div>
    <div class="title flex_r f_ai_c">
      <div class="icon bg_org"></div>
      <div class="name fs17 fw600 cr_000 ml10"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.icon {
  width: 0.1rem;
  height: 0.42rem;
  border-radius: 0rem 0.08rem 0.08rem 0rem;
}
</style>
