<template>
  <van-nav-bar
    title="确认订单"
    left-text="返回"
    left-arrow
    @click-left="toPage('ProjectDetail')"
    fixed
  >
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
          class="van-badge__wrapper van-icon van-icon-ellipsis"
          style="font-size: 0.5208rem; color: #2e2e2e"
        ></i> -->
        <img style="width:0.4rem" src="@/assets/img/user11.png" alt="">
      </router-link>
    </template>
  </van-nav-bar>
  <div class="bg_f8 pt46 pb100">
    <div class="content">
      <div class="bg_white pt15 all">
        <div class="mr16 ml16">
          <!-- 头部信息 -->
          <div class="info">
            <DetailTitle :ProjectInfo="packageList"></DetailTitle>
          </div>

          <div
            class="br_t_1_grey flex_r f_jc_b mt16 mr5 mb0 ml5 pt12 pb12 pr4 pl4"
            @click="toPage('Detail')"
          >
            <div class="projectCell">共{{ packageList.sum }}项可检查的项目</div>
            <div>
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
      </div>
      <!-- form表单 -->
      <div class="form bg_white brs_10 mt10 mr15 mb100 ml15">
        <div class="flex_r f_jc_b f_ai_c up">
          <Title><span>现在预约</span></Title>
          <van-field name="switch" label="" label-width="1.12rem">
            <template #input>
              <van-switch v-model="checked" size="20" />
            </template>
          </van-field>
        </div>
        <div>
          <van-form>
            <van-cell-group inset class="mb19">
              <div
                class="prompt fs12 fw600 cr_b99 lh16 f_ls_1 mr15 ml15"
                v-show="checked"
              >
                可购买后再填写，在体检前需完成预约
              </div>
              <OrderForm
                :submitInfo="submitInfo"
                :checked="checked"
                @MinBookDay="MinBookDay"
              ></OrderForm>
              <div class="callPhone mb24">
                <Title><span>联系人信息</span></Title>
                <div class="phone ml12">
                  <van-field
                    v-model="submitInfo.phoneNum"
                    name="手机号码"
                    label="手机号码"
                    placeholder="请输入您的手机号码"
                    label-width="1.5rem"
                    colon="true"
                    readonly
                    required
                  />
                </div>
              </div>
              <div class="pay">
                <div class="pay_top flex_r f_ai_c cr_000 f_jc_b">
                  <div class="left">
                    <Title><span>支付方式</span></Title>
                  </div>
                  <div class="right flex_r f_ai_c cr_000">
                    <div
                      class="right_box mr15 flex_r f_ai_c cr_000"
                      v-for="item in list"
                      :key="item"
                    >
                      <img :src="item.img" alt="" />
                      <div>{{ item.name }}</div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    pay_bottom
                    flex_r
                    f_ai_c f_jc_b
                    mt19
                    mb19
                    mr15
                    ml15
                    br_b_1_grey
                  "
                >
                  <div class="left flex_r f_ai_c">
                    <img
                      class="mr14"
                      src="@/assets/img/order/zhifu.png"
                      alt=""
                    />
                    <div>微信支付</div>
                  </div>
                  <div class="right">
                    <van-field name="checkbox" label="">
                      <template #input>
                        <van-checkbox checked />
                      </template>
                    </van-field>
                  </div>
                </div>
              </div>
              <div class="total mt48 mb10">
                <Title><span>订单合计</span></Title>
                <div
                  class="
                    mr15
                    ml15
                    flex_r
                    f_jc_b
                    fs14
                    fw600
                    cr_999
                    lh19
                    f_ls_1
                    mt26
                    mb15
                  "
                >
                  <div>在线支付</div>
                  <div class="price fs16 fw600 cr_b7b lh16 f_ls_1">
                    ￥{{ packageList.price }}
                  </div>
                </div>
                <div
                  class="
                    total_bottom
                    mr15
                    ml15
                    tr
                    fs14
                    fw600
                    cr_000
                    lh20
                    f_ls_1
                  "
                >
                  <div>
                    订单总额<span class="fs17 fw600 cr_f86 ml7"
                      >￥{{ packageList.price }}</span
                    >
                  </div>
                </div>
              </div>
            </van-cell-group>
            <van-action-bar class="pr15 pl15 flex_r f_jc_b">
              <div class="service flex_r f_ai_c" @click="service()">
                <img src="@/assets/img/order/kefu.png" alt="" />
                <div class="fs14 cr_999 lh20 f_ls_1 ml5">联系客服</div>
              </div>
              <van-button
                class="toBtn brs_20"
                round
                block
                type="primary"
                native-type="submit"
                @click="submit()"
              >
                去支付 ¥{{ packageList.price }}
              </van-button>
            </van-action-bar>
          </van-form>
        </div>
      </div>
      <div class="warning bg_1df fs14 fw600 cr_b99 lh38 f_ls_1 brs_3 tc">
        线上购买不支持医保支付及后续医保报销，请知悉！
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import api from "@/api/api/Index";
import "vant/lib/index.css";
import {
  NavBar,
  Form,
  Field,
  CellGroup,
  Switch,
  RadioGroup,
  Radio,
  Popup,
  Picker,
  Checkbox,
  ActionBar,
  ActionBarButton,
  Button,
  Toast,
  Dialog,
} from "vant";
import DetailTitle from "@/components/content/DetailTitle.vue";
import Title from "@/components/content/Title.vue";
import OrderForm from "@/components/content/OrderForm.vue";
export default {
  components: {
    vanNavBar: NavBar,
    DetailTitle,
    Title,
    vanForm: Form,
    vanField: Field,
    vanCellGroup: CellGroup,
    vanSwitch: Switch,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanPopup: Popup,
    vanPicker: Picker,
    vanCheckbox: Checkbox,
    vanActionBar: ActionBar,
    vanActionBarButton: ActionBarButton,
    vanButton: Button,
    OrderForm,
  },
  setup() {
    const checked = ref(true);
    const result = ref("");
    return { checked, result };
  },
  created() {
    if (this.$route.params.telephone) {
      var data = this.$route.params;
      data.cardType = 0
      this.checked = true;
      this.submitInfo.phoneNum = data.telephone;
      this.submitInfo.gender = data.gender;
      this.submitInfo.idCardType = data.cardType;
      this.submitInfo.isMarriage = data.isMarriage;
      this.submitInfo.idCard = data.cardNumber;
      this.submitInfo.medicalName = data.medicalName;
      this.submitInfo.birthday = data.birthday;
      this.submitInfo.age = data.age;
    }
    var _this = this;
    //用户信息
    api.Login.getCurrentUser().then((res) => {
      if (!this.$route.params.telephone)
        _this.submitInfo.phoneNum = res.telephone;
    });
    //套餐信息
    api.ProjectDetail.mealDetail(this.$route.query.id).then((res) => {
      var packageList = res[0];
      var sum = 0;
      packageList.resultvo.forEach((item, index) => {
        sum += item.itemVoList.length;
      });
      packageList.sum = sum;
      _this.packageList = packageList;
      _this.submitInfo.orderAmount = packageList.price;
      _this.submitInfo.mealId = packageList.mealId;
      _this.submitInfo.medicalHospital = packageList.hospitalName;
    });
  },
  methods: {
    MinBookDay(value) {
      this.submitInfo.medicalTime = value;
    },
    toPage(page) {
      this.$router.push({
        name: page,
        query: {
          id: this.$route.query.id,
        },
      });
    },
    service() {
      Dialog.confirm({
        title: "在线客服",
        message: "400-1000-676（08:00-22:00）",
        confirmButtonText: "拨打",
      }).then(() => {
        // 拨打电话
        window.location.href = "tel://400-1000-676";
      });
    },
    submit() {
      if (!this.checked) {
        var submitData = {
          orderAmount: this.submitInfo.orderAmount,
          mealId: this.submitInfo.mealId,
          payType: this.submitInfo.payType,
          identifying: 1,
        };
      } else {
        var submitData = this.submitInfo;
        if (!submitData.medicalName) {
          Toast("请输入真实姓名");
          return false;
        }
        if (!submitData.phoneNum) {
          Toast("请输入联系电话");
          return false;
        }
        if (!/^1[3456789]\d{9}$/.test(submitData.phoneNum)) {
          Toast("请输入正确的联系电话");
          return false;
        }
        if (!submitData.idCard) {
          Toast("请输入身份证号码");
          return false;
        }
        if (submitData.idCardType == 0) {
          var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if (!reg.test(submitData.idCard)) {
            Toast("请输入正确的身份证号码");
            return false;
          }
        } else {
          var reg1 = /^([a-zA-z]|[0-9]){5,17}$/;
          var reg2 =/^([A-Z]\d{6,10}(\(\w{1}\))?)$/;
          if (
            !reg1.test(submitData.idCard) ||
            !reg2.test(submitData.idCard)
          ) {
            Toast("请输入正确的护照/港澳通行证号码");
            return false;
          }
        }
        if (!submitData.birthday) {
          Toast("请选择出生日期");
          return false;
        }
        if (!submitData.age) {
          Toast("请输入体检人年龄");
          return false;
        }
        submitData.identifying = 2;
      }
      if (this.submitFlag) {
        this.submitFlag = false;
        var _this = this;
        //防止多次重复下单
        setTimeout(function () {
          _this.submitFlag = true;
        }, 2000);
        api.Order.orderAdd(submitData).then((res) => {
          window.location.href =
            "http://ylpay.goliebao.com/Nativepay/weixinapi.php?out_trade_no=" +
            res.orderSn +
            "&total_fee=" +
            res.orderAmount +
            "&body=" +
            res.orderDesc +
            "&orderId=" +
            res.orderId;
        });
      }
    },
  },
  data() {
    return {
      list: [
        { img: require("@/assets/img/home/l2.png"), name: "免费改期" },
        { img: require("@/assets/img/home/l3.png"), name: "未约可退" },
      ],
      //套餐信息
      packageList: {},
      //form表单
      submitFlag: true,
      submitInfo: {
        phoneNum: null,
        isMarriage: 1,
        orderAmount: null,
        mealId: null,
        idCardType: 0,
        gender: 1,
        idCard: null,
        medicalName: null,
        medicalTime: null,
        medicalHospital: null,
        age: null,
        birthday: null,
        payType: "wxpay_mweb",
      },
    };
  },
};
</script>

<style scoped>
.all {
  width: 100%;
}

.form {
  width: 6.86rem;
}
.up .van-cell {
  width: 1.25rem;
  margin-right: 0.2rem;
}
.form .van-cell-group--inset {
  margin: 0 0 0.4rem 0;
}

.pay_top .right_box img {
  width: 0.25rem;
  height: 0.25rem;
}
.pay_bottom img {
  width: 0.48rem;
  height: 0.42rem;
}
.service img {
  width: 0.36rem;
  height: 0.36rem;
}
.toBtn {
  width: 4.54rem;
  height: 0.8rem;
  background: linear-gradient(270deg, #ff7463 0%, #ff8d5f 100%);
  box-shadow: 0rem 0.03rem 0.1rem 0rem #ffc5a2;
  border: none;
}
.van-action-bar {
  box-shadow: 0rem -0.06rem 0.2rem 0rem rgba(244, 244, 244, 0.5);
}

.warning {
  height: 0.8rem;
  position: fixed;
  bottom: 0.9rem;
  width: 100%;
}
.van-switch--on {
  background-color: #ff8230;
}
.van-checkbox__icon--checked .van-icon {
  background-color: #03c7c2;
}
.callPhone .phone {
  width: 100%;
}
</style>
