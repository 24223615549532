<template>
  <div class="tx_content">
    <div class="tx_inside" v-for="(item, index) in list" :key="index">
      <img
        v-if="item == '女性' || item == '男女不限'"
        class="no"
        src="@/assets/img/home/2.png"
        alt=""
      />
      <img
        v-if="item == '男性'"
        class="no"
        src="@/assets/img/home/1.png"
        alt=""
      />
      <img
        v-if="item == '已婚'"
        class="no"
        src="@/assets/img/home/3.png"
        alt=""
      />
      <img
        v-if="item == '未婚'"
        class="no"
        src="@/assets/img/home/1.png"
        alt=""
      />
      <img
        v-if="item == '中年'"
        class="no"
        src="@/assets/img/home/4.png"
        alt=""
      />
      <img
        v-if="item == '老年'"
        class="no"
        src="@/assets/img/home/5.png"
        alt=""
      />
      <div class="text">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api/Index";
export default {
  props: ["typeList"],
  mounted() {
  },
  created() {},
  watch: {
    typeList(newVal, oldVal) {
      api.Examinator.dictType("applicable_people").then((res) => {
        for (let index = 0; index < newVal.length; index++) {
          res.forEach((element) => {
            if (newVal[index] == element.dictValue) {
              this.list[index] = element.dictLabel;
            }
          });
        }
      });
    },
  },
  methods: {},
  data() {
    return {
      list: [],
    };
  },
};
</script>

<style scoped>
.tx_content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin: 0;
  text-align: center;
}
.tx_content .tx_inside {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.3rem;
  align-items: center;
  flex-direction: column;
}
.tx_content .tx_inside .text {
  width: 1.2rem;
  color: #444444;
  font-weight: 600;
  margin-top: 0.1rem;
  margin-right: 0rem;
}
.tx_inside .no {
  width: 1rem;
  height: 1rem;
  /* border-radius: 50%; */
}
.go {
  color: #ff8230;
  width: 0.98rem;
  height: 0.98rem;
  border-radius: 50%;
  margin-top: 0.02rem;
  border: 0.01rem solid #ff8230;
}
</style>
