<template>
  <div class="tx_content">
    <div class="multi_span" style="overflow-x: scroll; z-index: 9999">
      <span
        v-for="(item, index) in typeList"
        :key="index"
        :class="item.checked ? 'actives' : ''"
        @click="typeSelect(item.dictValue)"
        >{{ item.dictLabel }}</span
      >
    </div>
  </div>
</template>

<script>
import api from "@/api/api/Index";
export default {
  props: ["typeList", "typeSelect"],
  created() {
  },
  methods: {
    typeSelect(id) {
      this.typeList.forEach((item, index) => {
        if (item.dictValue == id) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      this.$emit("typeSelect", id);
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
body {
  position: relative;
}
.tx_content {
  /* margin-right: 0.32rem; */
  background-color: #fff;
}
.tx_content ul {
  display: flex;
  flex-direction: row;
}
.multi_span span:not(first) {
  height: 0.64rem;
  line-height: 0.64rem;
  font-size: 0.28rem;
}
.actives {
  background: var(--mealType);
  box-shadow: 0rem 0.03rem 0.1rem 0rem #ffc5a2;
  border-radius: 0.32rem;
  font-size: 0.28rem;
  color: #ffffff;
  letter-spacing: 1px;
}
</style>
