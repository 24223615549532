<template>
  <div class="container">
    <div class="map_return">
      <a href="javascript:history.go(-1)">&nbsp;</a>
    </div>
    <div id="map" style="width: 100%; height: 90vh"></div>
    <div class="map_tjjg bg_white br_t_1_e8e8">
      <div class="tjjg lh20">
        <b class="fs14 cr_4b4">衡阳市中心医院健康管理（体检）中心</b>
        <em class="fs12 cr_999"></em>
        <span class="fs12 cr_4b4 mr10">公立三甲</span>
      </div>
      <div class="map_link mr5">
        <a
          href="https://map.baidu.com/mobile/webapp/place/linesearch/foo=bar/end=word="
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";

export default {
  components: {},
  setup() {
    const active = ref(1);
    return {
      active,
    };
  },
  mounted() {
    this.initMap();
  },
  data() {
    return {};
  },
  methods: {
    initMap() {
      // 百度地图API功能

      var lng = "112.5695747646";
      var lat = "26.9043737742";
      var map = new BMap.Map("map");
      map.centerAndZoom(new BMap.Point(lng, lat), 18); //创建地图给一个默认坐标
      map.enableScrollWheelZoom(true);
      var mk1 = new BMap.Marker(new BMap.Point(lng, lat), 18);
      map.addOverlay(mk1);
      map.centerAndZoom(new BMap.Point(lng, lat), 18);
    },
  },
};
</script>

<style scoped>
/*地图导航*/
.map_tjjg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1.5625rem;
  z-index: 2;
}
.map_tjjg .tjjg {
  position: absolute;
  top: 0.1042rem;
  left: 0.2083rem;
  right: 1.5625rem;
  bottom: 0;
}
.map_tjjg .tjjg b {
  float: left;
  width: 100%;
  height: 0.4375rem;
  overflow: hidden;
}
.map_tjjg .tjjg em {
  float: left;
  width: 100%;
  height: 0.4375rem;
  font-style: normal;
  overflow: hidden;
}
.map_tjjg .tjjg span {
  float: left;
  width: auto;
  height: 0.4375rem;
  overflow: hidden;
}
.map_return {
  position: absolute;
  top: 0.2708rem;
  left: 0.2708rem;
  width: 0.7083rem;
  height: 0.5833rem;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr=#4c000000, endColorstr=#4c000000);
}
.map_return a {
  float: left;
  width: 100%;
  height: 100%;
  background: url(../../assets/img/map_return.png) center no-repeat;
  background-size: 0.2083rem;
}
.map_tjjg .map_link {
  float: right;
  width: 1.4583rem;
  height: 1.5625rem;
}
.map_tjjg .map_link a {
  float: left;
  width: 100%;
  height: 100%;
  background: url(../../assets/img/map_icon.png) center no-repeat;
  background-size: 1.0417rem;
}
</style>
