import request from '@/api/axios'; // 导入http中创建的axios实例


const ProjectDetail = {
    //根据套餐类型查询套餐
    mealDetail(id) {
        return request({
            url: '/api/open/h5/exam/inspection/listByMealId/' + id,
            method: 'get'
        })
    },
    //查询预约日期列表
    appointmentDate() {
        return request({
            url: '/api/open/h5/exam/appointmentDate/appointmentTimeList',
            method: 'get'
        })
    },
}


export default ProjectDetail;