<template>
  <van-nav-bar
    :title="packageList.mealName"
    left-text="返回"
    left-arrow
    fixed="true"
    @click-left="onClickLeft"
    style="z-index: 999999"
  >
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <img style="width: 0.4rem" src="@/assets/img/user11.png" alt="" />
      </router-link>
    </template>
  </van-nav-bar>
  <div class="bg_white pt45 pb45">
    <!-- 套餐信息 -->
    <div class="all bg_white pb18">
      <img
        style="width: 7.5rem; height: 5.6rem"
        :src="packageList.imgUrl"
        alt=""
      />
      <div class="info mt10 mb10 mr15 ml15">
        <div class="one_box flex_r f_jc_b f_ai_c">
          <div class="left flex_r f_ai_e">
            <div class="price fs20 fw600 cr_f86 lh20 f_ls_1">
              ￥{{ packageList.price }}
            </div>
            <div class="num fs10 cr_888 lh14 ml14">
              {{ packageList.orderCount }}人已预约
            </div>
          </div>
          <!-- <div class="right">
            <img src="@/assets/img/点中的爱心@2x.png" alt="" />
            已收藏
          </div> -->
        </div>
        <div class="name mt14 fs17 cr_000 lh24 fw600">
          {{ packageList.mealName }}
        </div>
        <div class="desc mt12 fs14 fw600 cr_888 lh20 f_ls_1">
          {{ packageList.introduction }}
        </div>
        <div class="detail flex_r f_jc_b mt24" @click="show = true">
          <div class="left flex_r f_ai_c">
            <div
              class="left_box flex_r f_ai_c mr14"
              v-for="item in lastList"
              :key="item"
            >
              <img class="mr2" :src="item.img" alt="" />
              <div class="fs12 cr_000 lh16 f_ls_1">{{ item.name }}</div>
            </div>
          </div>
          <div class="right">
            <span
              >详情
              <van-icon name="arrow" />
            </span>
          </div>
        </div>
        <van-popup v-model:show="show" teleport="body" position="bottom">
          <div
            class="popupUp flex_r f_ai_c f_jc_b pt14 pb14 mr15 ml15 br_b_1_grey"
          >
            <div class="popupTitle fs18 cr_000 fw600 f_ls_1">保障详情</div>
            <div @click="show = false">
              <img src="@/assets/img/close.png" alt="" />
            </div>
          </div>
          <div class="buju flex_r mg16 f_jc_b">
            <div class="left bg_7f3 brs_10 pt11 pb11 pr14 pl14">
              <div class="flex_r f_ai_c">
                <img src="@/assets/img/home/l2.png" alt="" />
                <div class="fs14 cr_000 fw600 mf8 f_ls_1 ml10">免费改期</div>
              </div>
              <div class="fs12 cr_444 mt5">
                每位客户拥有2次通过网站直接改期的机会。如因特殊情况需再次改期，只需联系康康体检网客服审核，确认原因后即可改期。改期不收任何服务费。
              </div>
            </div>
            <div class="right mt3">
              <div
                class="list brs_10 pt11 pb11 mb8 pl11 pr11"
                v-for="(item, index) in popupList"
                :key="index"
              >
                <div class="up_bbox flex_r f_ai_c">
                  <img :src="item.img" alt="" />
                  <div class="name fs14 cr_000 fw600 ml8 f_ls_1">
                    {{ item.name }}
                  </div>
                </div>
                <div class="content fs12 cr_444 mt5">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </van-popup>
      </div>
    </div>
    <!-- 医院信息 -->
    <div class="all" style="border-top: 0.2rem solid #f8f8f8">
      <router-link to="introduction">
        <div class="flex_r f_ai_c pt20">
          <div class="left ml16">
            <img
              style="width: 0.8rem"
              src="@/assets/img/intro/map.png"
              alt=""
            />
          </div>
          <div class="right ml12">
            <div class="title fs14 fw600 cr_000 lh20 flex_r f_ai_c f_jc_b">
              {{ packageList.hospitalName }}
              <van-icon class="icon" name="arrow" />
            </div>
            <div class="one-txt-cut address fs12 fw600 cr_999 lh16 mt4 f_ls_1">
              {{ packageList.address }}
            </div>
          </div>
        </div>
      </router-link>
      <!-- 日历 -->
      <div class="RiLi mt19 mb19">
        <RiLi
          :selectOne="isSelect"
          @time="updateTime"
          @MinBookDate="MinBookDate"
        ></RiLi>
      </div>
    </div>
    <div class="all" style="border-top: 0.2rem solid #f8f8f8">
      <div class="bottom">
        <van-tabs
          color="#FF8230"
          v-model:active="active"
          @click-tab="onClickTab"
        >
          <!-- 项目详情 -->
          <van-tab title="项目详情">
            <div class="content mt29">
              <ul>
                <li>
                  <Title><span>套餐特点</span></Title>
                  <div class="spec fs14 ml14 cr_444 lh22">
                    {{ packageList.mealTrait }}
                  </div>
                </li>
                <li>
                  <Title><span>适用人群</span></Title>
                  <Crowd :typeList="packageList.scope"></Crowd>
                </li>
                <li>
                  <Title><span>疾病筛查</span></Title>
                  <div class="disease mr16 mt16 ml16">
                    <div
                      class="desc tc mb24"
                      v-for="(item, index) in packageList.diseaseScreening"
                      :key="index"
                    >
                      <div class="img" v-show="item == '肠胃疾病'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d1.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '甲状腺检查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d2.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '糖尿病'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d3.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '胸肺部检查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d4.png"
                          alt=""
                        />
                      </div>

                      <div class="img" v-show="item == '肝胆检查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d5.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '前列腺检查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d6.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '肿瘤筛查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d7.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '妇科检查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d8.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '心脑血管检查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d9.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '宫颈癌'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d10.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '乳腺检查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d11.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '关节炎'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d12.png"
                          alt=""
                        />
                      </div>
                      <div class="img" v-show="item == '乙肝病毒检查'">
                        <img
                          class="mauto"
                          src="@/assets/img/detail/d13.svg"
                          alt=""
                        />
                      </div>
                      {{ item }}
                    </div>
                  </div>
                </li>
                <li>
                  <Title
                    ><span>套餐项目({{ packageList.sum }}项)</span></Title
                  >
                  <CellList :CellList="packageList.resultvo"></CellList>
                </li>
              </ul>
            </div>
          </van-tab>
          <!-- 预约流程 -->
          <van-tab title="预约流程">
            <div class="content mt29 mr15 mb38 ml15">
              <div
                class="up flex_r f_ai_s"
                v-for="(item, index) in stepList"
                :key="index"
              >
                <div class="left flex_c f_ai_c f_jc_b">
                  <div class="left_box1 flex_c f_ai_c bg_5ef brs_50">
                    <img class="mt10" :src="item.img" alt="" />
                  </div>
                  <div
                    v-show="index != stepList.length - 1"
                    class="bottom mt7 mb7 mauto pt10 pb10"
                  ></div>
                </div>
                <div class="right flex_c ml10">
                  <span class="title cr_ff8 fs14 fw600 f_ls_1">{{
                    item.title
                  }}</span
                  ><span class="desc cr_000 fs12 f_ls_1 fw400">{{
                    item.desc
                  }}</span>
                </div>
              </div>
            </div>
          </van-tab>
          <!-- 体检须知 -->
          <van-tab title="体检须知">
            <div
              class="inform pt18 mr20 pb33 ml16"
              v-for="(item, index) in informList"
              :key="index"
            >
              <div
                class="title fs12 cr_ff8 fw600 bg_ffe brs_5"
                style="text-align: center"
              >
                {{ item.title }}
              </div>
              <ul class="pd10 brs_10">
                <li
                  class="tl fs12 cr_000 lh18 flex_r mb8"
                  v-for="i in item.content"
                  :key="i"
                >
                  <div
                    style="
                      width: 0.12rem;
                      height: 0.12rem;
                      margin-right: 0.11rem;
                      line-height: 0.3rem;
                    "
                  >
                    <van-icon
                      style="
                        width: 0.12rem;
                        height: 0.12rem;
                        background-color: #ffc8a4;
                        border-radius: 50%;
                      "
                    />
                  </div>
                  {{ i }}
                </li>
              </ul>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
  <div class="actionBar">
    <van-action-bar class="br_t_1_e1e1 pr10 pl10 flex_r f_ai_c">
      <div class="price fs20 fw600 f_ls_1 mr32 cr_f86">
        ￥{{ packageList.price }}
      </div>
      <van-action-bar-button
        class="brs_20"
        type="danger"
        text="立即购买"
        @click="toOrder"
      />
    </van-action-bar>
  </div>
</template>

<script>
import "vant/lib/index.css";
import api from "@/api/api/Index";
import { ref } from "vue";
import {
  Tab,
  Tabs,
  NavBar,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Popup,
  Calendar,
  Tabbar,
  TabbarItem,
} from "vant";
import Title from "@/components/content/Title.vue";
import Crowd from "@/components/content/Crowd.vue";
import RiLi from "@/components/content/Calendar.vue";
import CellList from "@/components/content/CellList.vue";
export default {
  components: {
    vanTab: Tab,
    vanTabs: Tabs,
    vanNavBar: NavBar,
    vanActionBar: ActionBar,
    vanPopup: Popup,
    vanActionBarIcon: ActionBarIcon,
    vanActionBarButton: ActionBarButton,
    vanCalendar: Calendar,
    vanTabbar: Tabbar,
    vanTabbarItem: TabbarItem,
    Title,
    Crowd,
    RiLi,
    CellList,
  },
  setup() {
    const active = ref(0);
    const date = ref("");
    return {
      active,
      date,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 验证是否获取到了上页的url
      /* eslint-disable no-console */
    });
  },
  created() {
    var _this = this;
    api.ProjectDetail.mealDetail(this.$route.query.id)
      .then((res) => {
        var packageList = res[0];
        packageList.diseaseScreening = res[0].diseaseScreening.split(",");
        packageList.scope = res[0].scope.split(",");
        var sum = 0;
        packageList.resultvo.forEach((item, index) => {
          sum += item.itemVoList.length;
        });
        packageList.sum = sum;
        _this.packageList = packageList;
      })
      .then(() => {
        api.Examinator.dictType("disease_screening").then((res1) => {
          for (
            let index = 0;
            index < this.packageList.diseaseScreening.length;
            index++
          ) {
            res1.forEach((element) => {
              if (
                element.dictValue === this.packageList.diseaseScreening[index]
              ) {
                this.packageList.diseaseScreening[index] = element.dictLabel;
              }
            });
          }
        });
        api.Examinator.dictType("inspection_item_type").then((res2) => {
          for (
            let index = 0;
            index < this.packageList.resultvo.length;
            index++
          ) {
            res2.forEach((element) => {
              if (
                this.packageList.resultvo[index].inspectionItemType ==
                element.dictValue
              ) {
                this.packageList.resultvo[index].inspectionItemType =
                  element.dictLabel;
              }
            });
          }
        });
      });
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: this.$store.state.path });
    },
    updateTime(value) {
      this.isSelect = true;
      this.selectDate = value;
    },
    MinBookDate(value) {
      this.MinBookDay = value;
    },
    toOrder() {
      var date;
      if (this.isSelect) {
        var year = this.selectDate.day.getFullYear();
        var month =
          this.selectDate.day.getMonth() + 1 >= 10
            ? this.selectDate.day.getMonth() + 1
            : "0" + (this.selectDate.day.getMonth() + 1);
        var day =
          this.selectDate.day.getDate() >= 10
            ? this.selectDate.day.getDate()
            : "0" + this.selectDate.day.getDate();
        date = year + "-" + month + "-" + day;
      } else {
        date = "";
      }
      this.$router.push({
        name: "Order",
        query: { id: this.$route.query.id, day: date },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    document.title = "衡阳市中心医院";
    next();
  },
  data() {
    return {
      oldUrl: "",
      isSelect: false,
      selectDate: {},
      MinBookDay: "",
      disable: false,
      show: false, //保障详情
      calendarShow: false,
      service: false,
      //套餐信息
      packageList: {},
      lastList: [
        { img: require("@/assets/img/home/l1.png"), name: "官方渠道" },
        { img: require("@/assets/img/home/l2.png"), name: "免费改期" },
        { img: require("@/assets/img/home/l3.png"), name: "未约可退" },
      ],
      popupList: [
        {
          img: require("@/assets/img/home/l1.png"),
          name: "官方渠道",
          content: "医院自有平台，官方线上渠道，可享受极速预约通道。",
        },
        {
          img: require("@/assets/img/home/l3.png"),
          name: "未约可退",
          content: "用户支付成功但未预约体检时间，可以全额退款。",
        },
      ],
      //流程数据
      stepList: [
        {
          img: require("@/assets/img/content/x1.png"),
          title: "选择套餐",
          desc: "选择需要的体检套餐，完成下单。",
        },
        {
          img: require("@/assets/img/content/x2.png"),
          title: "预约体检",
          desc: "付款完成后，点击“体检预约”或到“我的订单”中预约体检。",
        },
        {
          img: require("@/assets/img/content/x3.png"),
          title: "到院服务",
          desc: "体检当日现场出示身份证即可体检,注:到医院无需另行支付其他费用 (现场加项、申请快递报告除外) 。",
        },
        {
          img: require("@/assets/img/content/x4.png"),
          title: "领取报告",
          desc: "体检报告一般在3 - 15个工作日出结果，部分医院支持在线查询报告、报告邮寄，各医院领取时间、方式不同， 具体的报告领取方式和时间可现场咨询医院工作人员。",
        },
      ],
      //提醒体检前中后数据
      informList: [
        {
          title: "体检前",
          content: [
            "体检前一天请您清淡饮食，勿饮酒、勿劳累。体检当天请空腹禁食。",
            "体检前一天要注意休息，晚上8点后不再进食。避免剧烈运动和情绪激动，保证充足睡眠，以免影响体检结果。",
            "例假期间不宜做妇科、尿液检查。",
          ],
        },
        {
          title: "体检中",
          content: [
            "需空腹检查的项目为抽血、腹部B超、数字胃肠，胃镜及其它标注的体检项目。",
            "做膀胱、子宫、附件B超时请勿排尿，如无尿需饮水至膀胱充盈。做妇科检查前应排空尿。",
            "未婚女性不做妇科检查；怀孕的女性请预先告知医护人员,不安排做放射及其他有影响的检查。",
            "做放射线检查前,请您除去身上佩戴的金银、玉器等饰物。",
            "核磁共振检查，应禁止佩带首饰、手表、传呼、手机等金属物品，磁卡也不应带入检查室，以防消磁。",
          ],
        },
        {
          title: "体检后",
          content: [
            "全部项目完毕后请您务必将体检单交到前台。",
            "请您认真听取医生的建议,及时复查、随诊或进一步检查治疗。",
            "请您保存好体检结果，以便和下次体检结果作对照也可作为您就医时的资料。",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.container {
  min-height: 80vh;
}
.all {
  background-color: white;
  width: 100%;
}
.bottom {
  margin-top: 0.2rem;
}
.one_box .right img {
  width: 0.34rem;
  height: 0.3rem;
}

.detail .left img {
  width: 0.24rem;
  height: 0.24rem;
}
.top .left img {
  width: 0.8rem;
  height: 0.8rem;
}

.top .right .title {
  width: 5.82rem;
  position: relative;
}
.address {
  width: 5.82rem;
  height: 0.33rem;
}
.top .right .icon {
  width: 0.06rem;
  height: 0.06rem;
  color: #979797;
  position: absolute;
  right: 0.1rem;
  top: 0.05rem;
}
.active {
  height: 7rem;
}

.content .disease {
  display: flex;
  flex-wrap: wrap;
}
.content .disease img {
  width: 0.64rem;
  /* height: 0.64rem; */
  margin: 0 auto;
}
.disease .desc .img {
  height: 0.8rem;
  /* width: 0.8rem; */
}
.content .disease .desc {
  text-align: center;
  width: 25%;
  margin-bottom: 0.5rem;
}
.spec {
  height: 0.92rem;
}
.content ul li:nth-child(1) {
  margin-bottom: 0.7rem;
}
.content ul li:nth-child(2) {
  margin-bottom: 0.7rem;
}
.van-action-bar {
  border-top: solid 0.01rem #e1e1e1;
  padding: 0 0.2rem;
  display: flex;
  align-items: center;
}
.van-action-bar .price {
  font-size: 0.4rem;
  font-weight: 600;
  color: #f86565;
  /* line-height: 0.4rem; */
  letter-spacing: 0.0208rem;
  margin-right: 0.65rem;
}
.van-action-bar-button {
  width: 4.54rem;
  height: 0.8rem;
  background: var(--btnColor);
  box-shadow: 0rem 0.03rem 0.1rem 0rem #ffc5a2;
  border-radius: 0.4rem;
}

.book {
  margin: 0.36rem 0.32rem 0.8rem 0.32rem;
}
.up {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.content .left {
  display: flex;
  flex-direction: column;
}
.left_box1 {
  width: 0.88rem;
  height: 0.88rem;
}
.content .left img {
  width: 0.48rem;
  height: 0.48rem;
  /* padding: 0.2rem 0.24rem; */
}
.content .right .desc {
  width: 5.78rem;
}
.content .bottom {
  min-height: 0.3rem;
  border-left: 0.01rem dashed #cecece;
}
.inform {
  width: 6.86rem;
  /* position: relative; */
}
.inform .title {
  width: 1.01rem;
  height: 0.55rem;
  background: #ffebe0;
  position: absolute;
  /* bottom: 0.1rem; */
}
.inform ul {
  background: #f8f8f8;
  width: 6.86rem;
  /* position: absolute; */
  top: 0.34rem;
  min-height: 100%;
  position: relative;
}
.inform ul li {
  list-style: none;
}
.popupUp {
  border-bottom: 0.01rem solid #ecebeb;
}
.popupUp img {
  width: 0.27rem;
}
.van-popup .left img,
.van-popup .right img {
  width: 0.36rem;
  height: 0.36rem;
}
.van-popup .buju .list {
  background-color: #fdf7f3;
  min-height: 1.78rem;
  min-width: 3.2rem;
}
.buju .left {
  width: 6rem;
  margin-right: 0.2rem;
}
</style>
