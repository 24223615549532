<template>
  <van-cell-group inset>
    <div class="getInfo mt19 mb19" v-show="checked">
      <van-field
        v-model="submitInfo.medicalHospital"
        name="体检医院"
        label="体检医院"
        label-width="1.5rem"
        colon="true"
        readonly
      />
      <div class="time">
        <van-field
          readonly
          label-width="1.5rem"
          colon="true"
          name="datetimePicker"
          label="时间选择"
          v-model="submitInfo.medicalTime"
          required
          placeholder="请选择体检日期和时间"
        />
        <img
          class="rili"
          @click="showTime = true"
          src="@/assets/img/order/rili.png"
          alt=""
        />
      </div>
      <div class="people flex_r f_jc_b">
        <van-field
          v-model="submitInfo.medicalName"
          name="体检人"
          label="体检人"
          required
          placeholder="请输入体检人姓名"
          label-width="1.5rem"
          colon="true"
        />
        <div
          class="select bg_5ef brs_3 br_1_c0 tc lh43 ml5"
          @click="toExaminator()"
        >
          选择
        </div>
      </div>
      <div class="wedding">
        <van-field
          name="radio"
          label="性别"
          required
          label-width="1.5rem"
          colon="true"
        >
          <template #input>
            <div
              :class="
                item.dictValue == submitInfo.gender ? 'wed_box' : 'wed_box2'
              "
              v-for="(item, index) in gender"
              :key="index"
              @click="weddingSelect(item, 1)"
            >
              {{ item.dictLabel }}
            </div>
          </template>
        </van-field>
      </div>
      <div class="wedding">
        <van-field
          name="radio"
          label="婚姻状态"
          required
          label-width="1.5rem"
          colon="true"
        >
          <template #input>
            <div
              :class="
                item.dictValue == submitInfo.isMarriage ? 'wed_box' : 'wed_box2'
              "
              v-for="(item, index) in wedding"
              :key="index"
              @click="weddingSelect(item, 2)"
            >
              {{ item.dictLabel }}
            </div>
          </template>
        </van-field>
      </div>
      <van-field
        v-model="IdType[submitInfo.idCardType].text"
        required
        label-width="1.5rem"
        colon="true"
        name="picker"
        label="证件类型"
        placeholder="请输入您的证件号码"
        readonly
        @click="showPicker = true"
      />
      <!-- <van-popup v-model:show="showPicker" position="bottom">
        <van-picker
          :columns="IdType"
          @confirm="onConfirmType"
          @cancel="showPicker = false"
        />
      </van-popup> -->
      <van-field
        v-model="submitInfo.idCard"
        name="证件号码"
        label="证件号码"
        placeholder="请输入您的证件号码"
        label-width="1.5rem"
        colon="true"
        required
        @blur="blurIdNum"
      />
      <!-- 选择出生日期 -->
      <div class="Date flex_r">
        <van-field
          v-model="submitInfo.birthday"
          readonly
          required
          name="date"
          colon="true"
          label="出生日期"
          label-width="1.5rem"
          placeholder="出生日期"
        />
        <img src="@/assets/img/riqi.png" @click="showDate = true" alt="" />
      </div>
      <van-popup v-model:show="showDate" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirmDate"
          @cancel="showDate = false"
        />
      </van-popup>
      <van-field
        required
        v-model="submitInfo.age"
        type="tel"
        name="age"
        label="年龄"
        placeholder="请输入体检人年龄"
        label-width="1.5rem"
        colon="true"
      />
      <div class="phone">
        <van-field
          v-model="submitInfo.phoneNum"
          type="tel"
          name="手机号码"
          label="手机号码"
          placeholder="请输入您的手机号码"
          label-width="1.5rem"
          colon="true"
          required
        />
      </div>
    </div>
  </van-cell-group>
  <div
    class="box"
    :class="showTime == true ? 'showBox' : 'block'"
    v-if="showTime"
    @touchmove.prevent
    @mousewheel.prevent
  ></div>
  <div
    class="riliPopup bg_white"
    :class="showTime == true ? 'showBox' : 'block'"
    @touchmove.prevent
    @mousewheel.prevent
  >
    <div class="popupUp flex_r f_ai_c f_jc_b mr15 ml15 pt13 pb13 br_b_1_grey">
      <div class="popupTitle fs17 cr_000 fw600 f_ls_1">体检时间</div>
      <div @click="showTime = false">
        <img src="@/assets/img/close.png" alt="" />
      </div>
    </div>
    <div style="margin: 0.31rem 0">
      <Calendar
        :day="day"
        @selectDate="selectDay"
        @time="updateTime"
        @MinBookDate="MinBookDate"
        :isShow="true"
      ></Calendar>
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";
import Calendar from "@/components/content/Calendar.vue";
import api from "@/api/api/Index";
import {
  Form,
  Field,
  CellGroup,
  Switch,
  RadioGroup,
  Radio,
  Popup,
  Picker,
  Checkbox,
  Button,
  DatetimePicker,
} from "vant";
export default {
  props: ["submitInfo", "checked"],
  components: {
    canForm: Form,
    vanField: Field,
    vanCellGroup: CellGroup,
    vanSwitch: Switch,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanPopup: Popup,
    vanPicker: Picker,
    vanCheckbox: Checkbox,
    vanButton: Button,
    vanDatetimePicker: DatetimePicker,
    Calendar,
  },
  created() {
    this.day = this.$route.query.day;
    // this.MinBookDate();
    // 婚姻
    api.Examinator.dictType("is_marriage").then((res) => {
      this.wedding = res;
    });
    // 性别
    api.Examinator.dictType("sys_user_sex").then((res) => {
      this.gender = res;
    });
    // 证件
    api.Examinator.dictType("card_type").then((res) => {
      this.IdType = res;
      this.IdType.forEach((item) => {
        item.text = item.dictLabel;
      });
    });
  },
  data() {
    return {
      once: true,
      day: "",
      showTime: false,
      showPicker: false,
      showDate: false,
      wedding: [],
      weddingValue: 0,
      gender: [],
      genderValue: 0,
      IdType: [
        {
          text: "身份证",
          type: 1,
        },
        {
          text: "港澳通行证/护照",
          type: 2,
        },
      ],
    };
  },
  methods: {
    onConfirmDate(val) {
      var year = val.getFullYear();
      var month = val.getMonth() + 1;
      var day = val.getDate();
      this.submitInfo.birthday =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);
      this.showDate = false;
    },
    // 输入身份证后自动计算出生日
    blurIdNum(event) {
      // 130503670401001
      if (this.submitInfo.idCardType == 0) {
        if (event.target) {
          var value = event.target._value;
        } else {
          var value = event;
        }
        const year = value.substr(6, 4);
        const mon = value.substr(10, 2);
        const day = value.substr(12, 2);
        this.submitInfo.birthday = `${year}-${mon}-${day}`;
      }
    },
    MinBookDate(value) {
      if (this.once) {
        if (this.day != null || this.day != "") {
          this.submitInfo.medicalTime = this.$route.query.day;
          this.$emit("MinBookDay", this.$route.query.day);
          this.once = false;
        }
        if (this.day == null || this.day == "") {
          this.submitInfo.medicalTime = value;
          this.$emit("MinBookDay", value);
          this.once = false;
        }
      }

      // this.submitInfo.medicalTime = value;
      // this.$emit("MinBookDay", value);
    },
    updateTime(value, status) {
      if (value.spec == 2) {
        this.submitInfo.medicalTime = this.formatDate(
          value.day.getFullYear(),
          value.day.getMonth() + 1,
          value.day.getDate()
        );
        this.showTime = status;
      }
    },
    formatDate(year, month, day) {
      let y = year;
      let m = month;
      if (m < 10) m = "0" + m;
      let d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
    toExaminator() {
      this.$router.push({
        name: "Examinator",
        query: {
          redirect: this.$router.currentRoute.value.fullPath,
        },
      });
    },
    toggle() {
      this.personInfo = !personInfo;
    },
    onDateConfirm(value) {
      var year = value.getFullYear();
      var month = value.getMonth() + 1;
      var day = value.getDate();
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      this.submitInfo.date = year + "-" + month + "-" + day;
      this.calendarShow = false;
    },
    onConfirmType(value, index) {
      this.submitInfo.idCardType = value.dictValue;
      this.showPicker = false;
    },
    weddingSelect(item, type) {
      if (type == 2) {
        this.submitInfo.isMarriage = item.dictValue;
      } else if (type == 1) {
        this.submitInfo.gender = item.dictValue;
      }
    },
  },
  setup() {
    const onClickLeft = () => history.back();
    const currentDate = ref(new Date(1990, 0, 17));
    return {
      minDate: new Date(1922, 0, 1),
      maxDate: new Date(),
      currentDate,
      onClickLeft,
    };
  },
};
</script>

<style scoped>
html {
  position: relative;
}
.time img,
.Date img {
  width: 0.36rem;
  right: 0.2rem;
}
.Date img {
  position: absolute;
  top: 20%;
}
.getInfo .van-field {
  margin: 0 auto 0.4rem auto;
  width: 6.22rem;
  height: 0.9rem;
  background: #fff8f4;
  border-radius: 0.06rem;
}
.getInfo .van-field .van-field__label {
  font-size: 0.28rem;
  font-weight: bolder;
  color: #000000;
  line-height: 0.4rem;
  letter-spacing: 0.01rem;
}
.getInfo .van-cell {
  padding: 0.22rem 0.25rem;
}
.rili {
  width: 0.36rem;
  position: absolute;
  top: 25%;
  right: 0.64rem;
}
.time,
.Date {
  position: relative;
}

.people .van-field {
  width: 4.5rem;
}
.people .select {
  width: 1.6rem;
  height: 0.9rem;
}
.wedding .wed_box {
  background: #ff8230;

  color: #ffffff;
}
.wedding .wed_box,
.wedding .wed_box2 {
  width: 1.2rem;
  height: 0.56rem;
  border-radius: 0.28rem;
  font-size: 0.28rem;
  line-height: 0.56rem;
  letter-spacing: 0.01rem;
  text-align: center;
  margin-right: 0.28rem;
}
.wedding .wed_box2 {
  background: #f2f2f2;
  color: #444444;
}
.getInfo .phone .van-field {
  background: none;
}
.van-checkbox__icon--checked .van-icon {
  background-color: #03c7c2;
}
.popupUp img {
  width: 0.27rem;
}
.block {
  display: none;
}
.showBox {
  display: block;
}
.riliPopup {
  width: 7.5rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  z-index: 3300;
  left: 0;
}
.box {
  height: 100%;
  width: 7.5rem;
  opacity: 0.3;
  position: fixed;
  top: 0;
  z-index: 3000;
  left: 0;
}
</style>
