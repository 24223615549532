import request from '@/api/axios'; // 导入http中创建的axios实例


const OrderDetail = {
    //订单详情
    OrderDetail(id){
        return request({
            url: '/api/h5/exam/order/detail/'+id,
            method: 'get'
        })
    },
    //添加订单体检人信息
    OrderUpdate(data){
        return request({
            url: '/api/h5/exam/order/updateState',
            method: 'post',
            data:data
        })
    }
}


export default OrderDetail;