<template>
  <van-nav-bar
    v-show="this.team == ''"
    title="机构介绍"
    left-arrow
    @click-left="onClickLeft"
    fixed
  >
    <template #left>
      <i
        class="van-badge__wrapper van-icon van-icon-cross"
        style="font-size: 0.4583rem; color: #2e2e2e"
      ></i>
    </template>
    <template #right>
      <router-link to="/User">
        <!-- <i
          class="van-badge__wrapper van-icon van-icon-ellipsis"
          style="font-size: 0.5208rem; color: #2e2e2e"
        ></i> -->
        <img style="width: 0.4rem" src="@/assets/img/user11.png" alt="" />
      </router-link>
    </template>
  </van-nav-bar>
  <div class="all bg_white pt69 pb48">
    <!-- 头部 -->
    <div class="top mb29 mauto bg_white">
      <div class="big">
        <img src="@/assets/img/intro/icon2.png" alt="" />
        <div class="up_box flex_r f_ai_c">
          <div class="left">
            <img :src="hospitalInfo.imgUrl" alt="" />
          </div>
          <div class="right ml10">
            <div class="name fs14 cr_fff f_ls_1">
              {{ hospitalInfo.hospitalName }}
            </div>
            <ul class="flex_r f_ai_c mt6">
              <li>性仁</li>
              <li>品卓</li>
              <li>智博</li>
              <li>术精</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bottom flex_r f_ai_c f_jc_b mt20">
        <div class="left mt7 ml13">
          <div class="left_box flex_r mt5 f_ai_c">
            <img class="mt4 mr8" src="@/assets/img/intro/time.png" alt="" />
            <div class="text cr_999 fw600 fs12">
              营业时间：{{ hospitalInfo.workTime }}
            </div>
          </div>
          <div class="left_box flex_r mt5">
            <img class="mt4 mr8" src="@/assets/img/intro/icon1.png" alt="" />
            <div class="text cr_999 fw600 fs12 van-multi-ellipsis--l2">
              {{ hospitalInfo.address }}
            </div>
          </div>
        </div>
        <router-link to="map">
          <div class="right_box pl5 flex_c f_ai_c" v-if="distance">
            <div><img src="@/assets/img/intro/map1.png" alt="" /></div>
            <div class="instance fs10 cr_00c mt5 van-ellipsis">
              {{ distance }}km<span class="mauto"
                ><van-icon name="arrow"
              /></span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="intro">
      <Title>
        <span>医院简介</span>
      </Title>
      <div class="content mt12 mb12 fs12 lh20 cr_000 f_ls_1 mr15 ml15">
        {{ hospitalInfo.orgInfo }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api/Index";
import { NavBar } from "vant";
import Title from "@/components/content/Title.vue";

export default {
  components: {
    vanNavBar: NavBar,
    Title,
  },
  data() {
    return {
      team: this.$store.state.team,
      //距离
      distance: "",
      //医院信息
      hospitalInfo: {
        address: " ",
        hospitalId: 0,
        hospitalName: "",
        imgUrl: require("@/assets/img/intro/map.png"),
        onlineCustomerService: "",
        orgInfo: "",
        workTime: "",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    // if (this.$store.state.team!= "") {
    //   document.title = "医院信息";
    // }
    next(vm=>{
      if (vm.$store.state.team!= "") {
      document.title = "医院信息";
    }
    });
  },
  created() {
    var _this = this;
    api.Introduction.hospitalDetail().then((res) => {
      _this.hospitalInfo = res;
    });
    var geolocation = new BMap.Geolocation();
    geolocation.getCurrentPosition(function (r) {
      if (this.getStatus() == BMAP_STATUS_SUCCESS) {
        var mk = new BMap.Marker(r.point);
        _this.getAddress(r.point);
      } else {
      }
    });
  },
  methods: {
    getAddress(point) {
      var gc = new BMap.Geocoder();
      var map = new BMap.Map("allmap");
      var _this = this;
      gc.getLocation(point, function (rs) {
        var pointA = new BMap.Point(rs.point.lng, rs.point.lat); // 当前位置坐标
        var pointB = new BMap.Point(112.5695747646, 26.9043737742); // 目标地点坐标
        map.getDistance(pointA, pointB); //两点间的距离
        _this.distance = Number(map.getDistance(pointA, pointB) / 1000).toFixed(
          2
        );
      });
    },
  },
  setup() {
    const onClickLeft = () => history.back();
    return { onClickLeft };
  },
};
</script>

<style scoped>
.all {
  width: 100%;
  min-height: 90vh;
}
.top {
  /* margin: 0 0.32rem 0.6rem 0.32rem; */
  position: relative;
  width: 6.86rem;
  height: 3rem;
  box-shadow: 0 0.06rem 0.2rem 0 #dadada;
}
.top .up_box {
  position: absolute;
  top: 0.5rem;
  left: 0.28rem;
}
.top .up_box img {
  width: 1rem;
  height: 1rem;
  border: 0.05rem solid white;
}

.bottom .left .left_box img {
  width: 0.22rem;
  height: 0.24rem;
}
.bottom .left .left_box .text {
  width: 4.67rem;
}
.bottom .right_box img {
  width: 0.47rem;
  height: 0.5rem;
}
.bottom .right_box {
  border-left: 0.01rem solid #e7e7e7;
  width: 1.5rem;
}
.bottom .right_box .instance {
  width: 100%;
}
.top .up_box .right ul li {
  color: #444444;
  font-size: 0.2rem;
  margin-right: 0.1rem;
}
</style>
