<template>
    <van-nav-bar title="我的订单" left-arrow @click-left="back()" class="nav-bar">
        <template #title>
            <span style="font-size: 0.3125rem; color: #fff">{{
        OrderDetail.title
      }}</span>
        </template>
        <template #left>
            <i class="van-badge__wrapper van-icon van-icon-cross" style="font-size: 0.4583rem; color: #fff"></i>
        </template>
        <template #right>
            <router-link to="/User">
                <!-- <i
          class="van-badge__wrapper van-icon van-icon-ellipsis"
          style="font-size: 0.5208rem; color: #fff"
        ></i> -->
                <img style="width: 0.4rem" src="@/assets/img/user11.png" alt="" />
            </router-link>
        </template>
    </van-nav-bar>
    <van-overlay :show="show" z-index="99999">
        <div class="wrapper">
            <van-loading type="spinner" />
        </div>
    </van-overlay>
    <div class="container pb96 bg_f8">
        <!-- 顶部位置 -->
        <div class="order-detail-top">
            <img src="@/assets/img/img/qxbj.png" class="top-bg-img" v-if="OrderDetail.orderState == 7" />
            <img src="@/assets/img/img/zfbj.png" class="top-bg-img" v-else />
            <!-- 待支付 -->
            <div class="top-main flex_r f_jc_b f_ai_s" v-if="OrderDetail.orderState == 0">
                <div class="left flex_r f_ai_c">
                    <img src="@/assets/img/img/clock_1.png" alt="" />
                    <div>
                        <h3 class="fs23 fw600 cr_fff">待支付</h3>
                        <p class="fs14 cr_fff">剩余时间：{{ OrderDetail.remainTime }}</p>
                    </div>
                </div>
                <div class="right">
                    <span class="fs24 fw600 cr_fff">￥<b>{{ OrderDetail.orderAmount }}</b>
                    </span>
                </div>
            </div>
            <!-- 已取消 -->
            <div class="top-main yqx-title" v-if="OrderDetail.orderState == 7">
                <img src="@/assets/img/img/yqx.png" alt="" />
                <div>
                    <h3 class="fs23 fw600 cr_fff">已取消</h3>
                    <p class="fs12 cr_fff">
                        欢迎再次选择 {{ OrderDetail.medicalHospital }}
                    </p>
                </div>
            </div>
            <!-- 待预约 -->
            <div class="top-main yy-title" v-if="OrderDetail.orderState == 1">
                <img src="@/assets/img/img/yy.png" alt="" />
                <div>
                    <h3 class="fs23 fw600 cr_fff">待预约</h3>
                    <p class="fs12 cr_fff">{{ OrderDetail.medicalHospital }}</p>
                    <!-- <p>衡阳市中心医院健康管理（体检）中心</p> -->
                </div>
            </div>
            <!-- 待体检 -->
            <div class="top-main tj-title" v-if="OrderDetail.orderState == 2">
                <img src="@/assets/img/img/tj.png" alt="" />
                <div>
                    <h3 class="fs23 fw600 cr_fff">待体检</h3>
                    <p class="fs12 cr_fff">{{ OrderDetail.medicalHospital }}</p>
                </div>
            </div>
            <!-- 审核中 -->
            <div class="top-main shz-title" v-if="OrderDetail.orderState == 3">
                <img src="@/assets/img/img/shz.png" alt="" />
                <div>
                    <h3 class="fs23 fw600 cr_fff">审核中</h3>
                    <p class="fs12 cr_fff">
                        欢迎再次选择 {{ OrderDetail.medicalHospital }}
                    </p>
                </div>
            </div>
            <!-- 已退款 -->
            <div class="top-main shz-title" v-if="OrderDetail.orderState == 4">
                <img src="@/assets/img/img/shz.png" alt="" />
                <div>
                    <h3 class="fs23 fw600 cr_fff">已退款</h3>
                    <p class="fs12 cr_fff">
                        欢迎再次选择 {{ OrderDetail.medicalHospital }}
                    </p>
                </div>
            </div>
            <!-- 审核未通过 -->
            <div class="top-main shz-title" v-if="OrderDetail.orderState == 5">
                <img src="@/assets/img/img/shz.png" alt="" />
                <div>
                    <h3 class="fs23 fw600 cr_fff">审核未通过</h3>
                    <p class="fs12 cr_fff">
                        欢迎再次选择 {{ OrderDetail.medicalHospital }}
                    </p>
                </div>
            </div>
            <!-- 已体检 -->
            <div class="top-main shz-title" v-if="OrderDetail.orderState == 6">
                <img src="@/assets/img/img/shz.png" alt="" />
                <div>
                    <h3 class="fs23 fw600 cr_fff">已体检</h3>
                    <p class="fs12 cr_fff">
                        欢迎再次选择 {{ OrderDetail.medicalHospital }}
                    </p>
                </div>
            </div>
            <div class="top-main shz-title" v-if="OrderDetail.orderState == 8">
                <img src="@/assets/img/img/shz.png" alt="" />
                <div>
                    <h3 class="fs23 fw600 cr_fff">体检中</h3>
                    <p class="fs12 cr_fff">
                        欢迎再次选择 {{ OrderDetail.medicalHospital }}
                    </p>
                </div>
            </div>
            <!-- 订单信息 -->
            <div class="order-info mr15 ml15 pd15 brs_10 bg_white">
                <div class="text-top flex_r f_jc_b pb15 br_b_1_grey">
                    <div class="text-left flex_r f_ai_c">
                        <img class="mr15 brs_5" :src="OrderDetail.imgUrl" />
                        <div>
                            <h3 class="fs17 mb5 cr_000">{{ OrderDetail.mealName }}</h3>
                            <p class="fs12 cr_888">{{ OrderDetail.medicalHospital }}</p>
                        </div>
                    </div>
                    <div class="text-right fs10 cr_888">x<span class="fs13">1</span></div>
                </div>
                <div class="text-bottom flex_r f_jc_b mt12 fs14 cr_999" @click="toDetail()">
                    <p>共{{ OrderDetail.sum }}项可检查项目</p>
                    <div>
                        <i class="van-badge__wrapper van-icon van-icon-arrow"></i>
                    </div>
                </div>
            </div>
        </div>

        <!-- 内容部分 -->
        <div class="order-detail-main bg_f8 pt14 pb14 mr15 ml15">
            <!-- 待支付模块，包含：现在预约、联系人信息、支付方式、订单合计 -->
            <div class="box mb10 brs_10 pt19 pb19 bg_white unpaid-main" v-if="OrderDetail.orderState == 0">
                <!-- 待支付的 现在预约 -->
                <div class="xzyy mb19" v-if="OrderDetail.idCard == null">
                    <div class="box-top flex_r f_jc_b f_ai_c">
                        <div class="title flex_r f_ai_c">
                            <i></i>
                            <h3>现在预约</h3>
                        </div>
                        <van-switch active-color="#FF8230" v-model="checked" class="switch mr15" size="0.5rem" />
                    </div>
                    <div class="xzyy-text mt8 pl15 fs12 fw600 cr_b99">
                        可向右滑动按钮，现在填写信息预约体检
                    </div>
                    <OrderForm :submitInfo="submitInfo" :checked="checked"></OrderForm>
                </div>
                <div class="box appointment-details" v-if="OrderDetail.idCard">
                    <div class="title">
                        <i></i>
                        <h3>预约详情</h3>
                    </div>
                    <ul class="appointment-details-text">
                        <li>
                            <h5>体检人：</h5>
                            <p>{{ OrderDetail.medicalName }}</p>
                        </li>
                        <li>
                            <h5>手机号码：</h5>
                            <p>{{ OrderDetail.phoneNum }}</p>
                        </li>
                        <li>
                            <h5>证件号码：</h5>
                            <p>{{ OrderDetail.idCard }}</p>
                        </li>
                        <li>
                            <h5>预约时间：</h5>
                            <p>{{ OrderDetail.medicalTime }}</p>
                        </li>
                    </ul>
                </div>
                <!-- 待支付的 联系人信息 -->
                <div class="lxrxx">
                    <div class="title">
                        <i></i>
                        <h3>联系人信息</h3>
                    </div>
                    <div class="lxrxx-text">
                        <i>*</i>
                        手机号码：
                        <span v-if="OrderDetail.phoneNum">{{ OrderDetail.phoneNum }}</span>
                        <span v-else>{{ submitInfo.phoneNum }}</span>
                    </div>
                </div>
                <!-- 待支付的 支付方式 -->
                <div class="zffs mt30 mb50 mr15">
                    <div class="box-top flex_r f_jc_b">
                        <div class="title">
                            <i></i>
                            <h3>支付方式</h3>
                        </div>
                        <div class="zffs-icon flex_r f_ai_c">
                            <div class="flex_r f_ai_c ml14">
                                <img class="mr4" src="@/assets/img/img/time.png" alt="" />
                                免费改期
                            </div>
                            <div class="flex_r f_ai_c ml14">
                                <img class="mr4" src="@/assets/img/img/refund.png" alt="" />
                                未约可退
                            </div>
                        </div>
                    </div>
                    <div class="
              zffs-text
              pb10
              flex_r
              f_jc_b f_ai_c
              mt19
              ml15
              mb15
              br_b_1_grey
            ">
                        <div class="flex_r f_ai_c fs15 fw600 cr_000">
                            <img class="mr10" src="@/assets/img/img/wxzf.png" alt="" />
                            微信支付
                        </div>
                        <van-radio name="1" checked></van-radio>
                    </div>
                </div>
                <!-- 待支付的 订单合计 -->
                <div class="ddhj">
                    <div class="title">
                        <i></i>
                        <h3>订单合计</h3>
                    </div>
                    <div class="ddhj-text mt24 pr15 pl15">
                        <div class="pay flex_r f_jc_b cr_999 fw600 fs13">
                            <div>在线支付</div>
                            <p class="fs13">
                                ￥<span class="fs17">{{ OrderDetail.orderAmount }}</span>
                            </p>
                        </div>
                        <div class="total tr mt12 fs13 cr_000 fw600">
                            订单总额
                            <span class="fs13 fw600 cr_f86">￥<b class="fs20">{{ OrderDetail.orderAmount }}</b></span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 现在预约：在 待预约页面  显示-->
            <div class="box appointment" v-if="OrderDetail.orderState == 1">
                <div>
                    <div class="box-top">
                        <div class="title">
                            <i></i>
                            <h3>现在预约</h3>
                        </div>
                    </div>
                    <OrderForm @MinBookDay="MinBookDay" :submitInfo="submitInfo" :checked="checked"></OrderForm>
                </div>
                <div class="lxrxx mb10">
                    <div class="title">
                        <i></i>
                        <h3>联系人信息</h3>
                    </div>
                    <div class="lxrxx-text mt8 pl29 fs13 fw600 cr_000">
                        <i class="cr_ff8">*</i>
                        手机号码：
                        <span v-if="OrderDetail.phoneNum">{{ OrderDetail.phoneNum }}</span>
                        <span v-else>{{ submitInfo.phoneNum }}</span>
                    </div>
                </div>
            </div>

            <!-- 预约详情：在 待体检 显示 -->
            <div class="box appointment-details" v-if="OrderDetail.orderState == 2">
                <div class="title">
                    <i></i>
                    <h3>预约详情</h3>
                </div>
                <ul class="appointment-details-text pl15 mt12">
                    <li>
                        <h5>体检人：</h5>
                        <p>{{ OrderDetail.medicalName }}</p>
                    </li>
                    <li>
                        <h5>手机号码：</h5>
                        <p>{{ OrderDetail.phoneNum }}</p>
                    </li>
                    <li>
                        <h5>证件号码：</h5>
                        <p>{{ OrderDetail.idCard }}</p>
                    </li>
                    <li>
                        <h5>预约时间：</h5>
                        <p>{{ OrderDetail.medicalTime }}</p>
                    </li>
                </ul>
            </div>
            <!-- 审核未通过理由 -->
            <div class="box ddhj" v-if="OrderDetail.orderState == 5">
                <div class="title">
                    <i></i>
                    <h3>理由：</h3>
                    <h3 class="fs13 cr_2e2">{{ OrderDetail.message }}</h3>
                </div>
            </div>
            <!-- 审核未通过理由 -->
            <!-- 订单合计，在 待体检、审核中 显示-->
            <div class="box ddhj" v-if="
          OrderDetail.orderState != 7 &&
          OrderDetail.orderState != 1 &&
          OrderDetail.orderState != 0
        ">
                <div class="title">
                    <i></i>
                    <h3>订单合计</h3>
                </div>
                <div class="ddhj-text">
                    <div class="pay">
                        <div>在线支付</div>
                        <p>
                            ￥<span>{{ OrderDetail.orderAmount }}</span>
                        </p>
                    </div>
                    <div class="total">
                        订单总额
                        <span>￥<b>{{ OrderDetail.orderAmount }}</b></span>
                    </div>
                </div>
            </div>

            <!-- 订单信息：在 已取消、待支付、待预约、待体检 显示 -->
            <div class="box order-msg mb29">
                <div class="title">
                    <i></i>
                    <h3>订单信息</h3>
                </div>
                <div class="order-msg-text pd15">
                    <div>
                        <p>创建时间：</p>
                        <p>{{ OrderDetail.createTime }}</p>
                    </div>
                    <div v-if="OrderDetail.payTime">
                        <p>支付时间：</p>
                        <p>{{ OrderDetail.payTime }}</p>
                    </div>
                    <div v-if="OrderDetail.refundTime">
                        <p>退款时间：</p>
                        <p>{{ OrderDetail.refundTime }}</p>
                    </div>
                    <div>
                        <p>订单编号：</p>
                        <p>{{ OrderDetail.orderId }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="order-detail-bottom">
        <div class="notify pt10 pb10 pr15 pl15 fs13 cr_b99 bg_1df" v-if="OrderDetail.orderState == 0">
            线上购买不支持医保支付及后续医保报销，请知悉！
        </div>
        <div class="payment flex_r f_jc_b f_ai_c pt9 pr16 pb9 pl16 bg_white">
            <div class="left flex_r fs13 cr_999" @click="service()">
                <img class="mr7" src="@/assets/img/img/kefu.png" />
                <span>联系客服</span>
            </div>
            <div class="right">
                <button class="btn qxdd mr12 br_1_d7 cr_999 bg_white fw600 fs15 brs_20" v-if="OrderDetail.orderState == 7 || OrderDetail.orderState == 8" @click="deleteOrder()">
                    删除订单
                </button>
                <button class="btn qxdd mr12 br_1_d7 cr_999 bg_white" v-if="
            OrderDetail.orderState == 0 ||
            OrderDetail.orderState == 1 ||
            OrderDetail.orderState == 2
          " @click="cancelOrder(OrderDetail)">
                    取消订单
                </button>
                <button class="btn ljzf br_1_d7 cr_fff" v-if="OrderDetail.orderState == 0" @click="toPay(OrderDetail)">
                    立即支付
                </button>
                <button class="btn zcgm br_1_bf cr_444 bg_white" v-if="
            OrderDetail.orderState == 3 ||
            OrderDetail.orderState == 4 ||
            OrderDetail.orderState == 5 ||
            OrderDetail.orderState == 6 ||
            OrderDetail.orderState == 7 ||
            OrderDetail.orderState == 8
          " @click="toPage('ProjectDetail', OrderDetail.mealId)">
                    再次购买
                </button>
                <button class="btn qryy cr_fff bg_org" v-if="OrderDetail.orderState == 1" @click="confirmBook(1)">
                    确认预约
                </button>
                <button class="btn tjxz br_1_8c cr_ff8 bg_5ef" v-if="OrderDetail.orderState == 2" @click="toPage('Inform')">
                    体检须知
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import api from "@/api/api/Index";
import "vant/lib/index.css";
import { Loading, Overlay } from "vant";
import OrderForm from "@/components/content/OrderForm.vue";
import {
    NavBar,
    Switch,
    Radio,
    RadioGroup,
    Form,
    CellGroup,
    Field,
    Popup,
    Picker,
    Toast,
    Dialog,
} from "vant";

export default {
    components: {
        vanNavBar: NavBar,
        vanSwitch: Switch,
        vanRadio: Radio,
        vanRadioGroup: RadioGroup,
        vanForm: Form,
        vanCellGroup: CellGroup,
        vanField: Field,
        vanPopup: Popup,
        vanPicker: Picker,
        OrderForm,
        vanOverlay: Overlay,
        vanLoading: Loading,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        return { proxy };
    },
    created() {
        // this.MinBookDay();
        //获取当天日期
        // var today = new Date();
        // var year = today.getFullYear();
        // var month = today.getMonth() + 1;
        // var day = today.getDate();
        // if (month < 10) month = "0" + month;
        // if (day < 10) day = "0" + day;
        // this.submitInfo.medicalTime = year + "-" + month + "-" + day;

        if (this.$route.params.telephone) {
            var data = this.$route.params;
            this.checked = true;
            this.submitInfo.phoneNum = data.telephone;
            this.submitInfo.gender = data.gender;
            this.submitInfo.idCardType = data.cardType;
            this.submitInfo.isMarriage = data.isMarriage;
            this.submitInfo.idCard = data.cardNumber;
            this.submitInfo.medicalName = data.medicalName;
            this.submitInfo.birthday = data.birthday;
            this.submitInfo.age = data.age;
        }
        var _this = this;
        api.Login.getCurrentUser().then((res) => {
            if (!this.$route.params.telephone)
                _this.submitInfo.phoneNum = res.telephone;
        });
        _this.getDetail();
    },
    data() {
        return {
            show: false,
            OrderDetail: {},
            examinatorForm: {
                hospital: "衡阳市中心医院健康管理（体检）中心",
                time: "",
                name: "",
                marriage: "",
                cardType: "居民身份证",
                cardNumber: "",
                telephone: "18718113705",
            },
            wedding: [
                { name: "未婚", value: 1, isTrue: true },
                { name: "已婚", value: 0, isTrue: false },
            ],
            IdType: [
                {
                    text: "居民身份证",
                    type: 1,
                },
                {
                    text: "港澳通行证/护照",
                    type: 2,
                },
            ],
            IdIndex: 0,
            checked: true,
            showType: false,
            submitFlag: true,
            submitInfo: {
                phoneNum: null,
                isMarriage: 1,
                orderId: null,
                idCardType: 0,
                gender: 1,
                idCard: null,
                medicalName: null,
                medicalTime: null,
                medicalHospital: null,
                age: null,
                birthday: null,
            },
        };
    },
    methods: {
        getDetail() {
            var _this = this;
            api.OrderDetail.OrderDetail(this.$route.query.id).then((res) => {
                _this.OrderDetail = res;
                // _this.$set(_this.OrderDetail, 'orderState', 3)
                //订单状态  0:待付款 1:待预约 2:待体检 3:审核中 4:已退款 5:审核未通过 6:已体检 7:取消
                var title = [
                    "立即支付",
                    "立即预约",
                    "待体检订单详情",
                    "退款订单详情",
                    "退款订单详情",
                    "退款订单详情",
                    "已体检订单详情",
                    "订单详情",
                ];
                _this.OrderDetail.title = title[res.orderState];
                var sum = 0;
                res.inspectioItemVoList.forEach((item, index) => {
                    sum += item.itemVoList.length;
                });
                _this.OrderDetail.sum = sum;
                _this.submitInfo.orderId = res.orderId;
                _this.submitInfo.medicalHospital = res.medicalHospital;
                //设置订单失效时间
                var time = res.createTime * 1000 + 1000 * 60 * 60 * 2;
                var currentTime = new Date().getTime();
                var cm = time - currentTime;
                if (cm <= 1000 * 60 * 60 * 2 && cm > 0) {
                    _this.OrderDetail.remainTime =
                        _this.proxy.$common.toHHmmss(cm);
                    _this.proxy.$common.setTimer(cm, _this.OrderDetail);
                }
                _this.OrderDetail.createTime = _this.proxy.$common.format(
                    _this.OrderDetail.createTime * 1000
                );
            });
        },
        MinBookDay(value) {
            this.submitInfo.medicalTime = value;
        },
        deleteOrder() {
            var id = this.OrderDetail.orderId;
            var message = "是否删除该订单";
            Dialog.confirm({
                title: "",
                message: message,
            })
                .then(() => {
                    // on confirm
                    var i;
                    var _this = this;
                    api.OrderList.deleteOrder(id).then((res) => {
                        this.$router.push({
                            name: "OrderList",
                            query: {
                                type: this.$route.query.type,
                            },
                        });
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },
        cancelOrder(val) {
            var id = this.OrderDetail.orderId;
            var _this = this;
            var data = {};
            data.orderId = id;
            var message = "是否取消该订单";
            if (_this.OrderDetail.orderState != 0) {
                message = "是否取消该订单,并申请退款。";
            }
            Dialog.confirm({
                title: "",
                message: message,
            })
                .then(() => {
                    // on confirm
                    switch (val.orderState) {
                        case 0:
                            data.type = 0;
                            api.OrderList.cancelOrder(data).then((res) => {
                                this.getDetail();
                            });
                            break;
                        case 1:
                            data.type = 2;
                            api.OrderList.refund({
                                orderid: val.orderSn,
                                totalfee: val.orderAmount,
                                refundfee: val.orderAmount,
                            }).then((res) => {
                                if (res.msg) {
                                    this.$message({
                                        type: "warning",
                                        message: res.msg,
                                    });
                                } else {
                                    this.show = true;
                                    var num = 0;
                                    var interval = setInterval(() => {
                                        if (num < 10) {
                                            num++;
                                            api.OrderList.getRefundStatus(
                                                val.orderSn
                                            ).then((res) => {
                                                if (res == 4) {
                                                    this.show = false;
                                                    this.$message({
                                                        type: "success",
                                                        message: "退款成功!",
                                                    });
                                                    this.open = false;
                                                    clearInterval(interval);
                                                    this.getDetail();
                                                }
                                            });
                                        } else if (num == 10) {
                                            num++;
                                        } else {
                                            this.$message({
                                                type: "warning",
                                                message:
                                                    "网络请求超时，请手动刷新页面",
                                            });
                                            this.show = false;
                                            clearInterval(interval);
                                            this.getDetail();
                                        }
                                    }, 1000);
                                }
                            });
                            break;
                        case 2:
                            data.type = 1;
                            api.OrderList.cancelOrder(data).then((res) => {
                                this.getDetail();
                            });

                            break;
                        default:
                            break;
                    }
                })
                .catch(() => {
                    // on cancel
                });
        },
        dataSelect() {},
        service() {
            Dialog.confirm({
                title: "在线客服",
                message: "400-1000-676（08:00-22:00）",
                confirmButtonText: "拨打",
            }).then(() => {
                // 拨打电话
                window.location.href = "tel://400-1000-676";
            });
        },
        onConfirmType(value, index) {
            this.IdIndex = index;
            this.showType = false;
        },
        toExaminator() {
            this.$router.push({
                name: "Examinator",
                query: {
                    redirect: this.$router.currentRoute.value.fullPath,
                },
            });
        },
        weddingSelect(i) {
            this.wedding.forEach((item, index) => {
                if (index == i) {
                    item.isTrue = true;
                    this.submitInfo.isMarriage = item.value;
                } else {
                    item.isTrue = false;
                }
            });
        },
        confirmBook(type) {
            var submitData = this.submitInfo;
            if (!submitData.medicalName) {
                Toast("请输入真实姓名");
                return false;
            }
            if (!submitData.phoneNum) {
                Toast("请输入联系电话");
                return false;
            }
            if (!/^1[3456789]\d{9}$/.test(submitData.phoneNum)) {
                Toast("请输入正确的联系电话");
                return false;
            }
            if (!submitData.idCard) {
                Toast("请输入身份证号码");
                return false;
            }
            if (submitData.idCardType == 0) {
                var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!reg.test(submitData.idCard)) {
                    Toast("请输入正确的身份证号码");
                    return false;
                }
            } else {
                var reg1 = /^([a-zA-z]|[0-9]){5,17}$/;
                var reg2 = /^([A-Z]\d{6,10}(\(\w{1}\))?)$/;
                if (
                    !reg1.test(submitData.idCard) ||
                    !reg2.test(submitData.idCard)
                ) {
                    Toast("请输入正确的护照/港澳通行证号码");
                    return false;
                }
            }
            if (!submitData.birthday) {
                Toast("请选择出生日期");
                return false;
            }
            if (!submitData.age) {
                Toast("请输入体检人年龄");
                return false;
            }
            var _this = this;
            if (this.submitFlag) {
                this.submitFlag = false;
                setTimeout(function () {
                    _this.submitFlag = true;
                }, 1000);
                api.OrderDetail.OrderUpdate(submitData).then((res) => {
                    if (type == 1) {
                        Toast("预约成功");
                        _this.OrderDetail.orderState = 2;
                        _this.OrderDetail.medicalName = submitData.medicalName;
                        _this.OrderDetail.phoneNum = submitData.phoneNum;
                        _this.OrderDetail.idCard = submitData.idCard;
                        _this.OrderDetail.medicalTime = submitData.medicalTime;
                    }
                });
            }
            return true;
        },
        toPay(val) {
            //判断预约信息是否填写
            if (this.checked && !this.OrderDetail.idCard) {
                this.confirmBook();
                if (!this.confirmBook()) {
                    return false;
                }
            }
            setTimeout(() => {
                window.location.href =
                    "http://ylpay.goliebao.com/Nativepay/weixinapi.php?out_trade_no=" +
                    this.OrderDetail.orderSn +
                    "&total_fee=" +
                    this.OrderDetail.orderAmount +
                    "&body=" +
                    this.OrderDetail.orderDesc +
                    "&orderId=" +
                    this.OrderDetail.orderId;
            }, 100);
        },
        back() {
            this.$router.push({
                name: "OrderList",
                query: {
                    type: this.$route.query.type,
                },
            });
        },
        toDetail() {
            this.$router.push({
                name: "Detail",
                query: {
                    id: this.OrderDetail.mealId,
                },
            });
        },
        toPage(page, id) {
            this.$router.push({
                name: page,
                query: { id: id },
            });
        },
    },
};
</script>

<style scoped>
.nav-bar {
    background: none !important;
    border: none !important;
    color: #fff !important;
}
.order-detail-top {
    position: relative;
    z-index: 0;
}
.order-detail-top .top-bg-img {
    position: absolute;
    top: -0.9583rem;
    left: 0;
    /* bottom: 0.9583rem; */
    z-index: -1;
}
.order-detail-top .order-info {
    position: relative;
    bottom: 0.3rem;
}
.order-detail-top .order-info .text-left img {
    width: 1rem;
    height: 1rem;
}
.order-detail-main .zffs-icon img {
    width: 0.26rem;
    height: 0.24rem;
}
.order-detail-main .zffs-text img {
    width: 0.4rem;
}
.order-detail-main .zffs-text >>> .van-icon {
    border-color: #ff8230;
    background: #ff8230;
}
.order-detail-main .order-msg-text div:last-child {
    margin-top: 0.28rem;
}
.marriage-active {
    color: #fff !important;
    background: #ff8230 !important;
}
.order-detail-bottom {
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
}
.order-detail-bottom .payment .left img {
    width: 0.36rem;
    height: 0.36rem;
}
.order-detail-bottom .payment .btn {
    width: 2rem;
    height: 0.8rem;
    border-radius: 1rem;
}
.order-detail-bottom .ljzf {
    box-shadow: 0rem 0.0417rem 0.1042rem 0rem rgba(255, 197, 162, 1);
    background: linear-gradient(270deg, #ff7463 0%, #ff8d5f 100%);
}
.order-detail-bottom .qryy {
    border: none;
}

.van-cell-group--inset {
    margin: 0;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>
