import request from '@/api/axios'; // 导入http中创建的axios实例


const Home = {
    //获取套餐类型
    meal_type(){
        return request({
            url: '/api/open/h5/exam/dict/data/type/meal_type',
            method: 'get',
        })
    },
    //根据套餐类型查询套餐
    listbytype(data){
        return request({
            url: '/api/open/h5/exam/set/meal/listByType',
            method: 'get',
            params:data
        })
    },
    //医院信息详情
    hospitalDetail(){
        return request({
            url: '/api/open/h5/exam/hospital/detail/',
            method: 'get'
        })
    },
    //热门套餐
    hotDate(){
        return request({
            url:'/api/open/h5/exam/set/meal/popularDate',
            method:'get'
        })
    }
}


export default Home;